// External imports
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Input,
  InputLabel,
  FormControl,
  Button,
  NativeSelect,
  Typography,
} from '@material-ui/core';
// Internal imports
import { isValidCalcServerDate, getDate } from '~/app/webHelpers.js';
import NumberFormat from 'react-number-format';
import {
  formatDollarAmt,
  generatePrintManualPaymentForm,
} from '~/app/Pages/Payments/helpers/paymentHelpers.js';
// Styling imports
import { taxReturnPaymentStyles } from '~/app/Pages/Payments/PaymentModal/css/taxReturnPayment.js';
import '~/app/Pages/Payments/PaymentModal/css/taxReturnPayment.css';

const styles = theme => taxReturnPaymentStyles(theme);

const ManualPayment = props => {
  const { classes } = props;
  const [paymentMethod, setPaymentMethod] = React.useState('CHECK');
  const [dateReceived, setDateReveived] = React.useState(getDate());
  const [receivedFrom, setReceivedFrom] = React.useState('');
  const [amount, setAmount] = React.useState('0.00');
  const [referenceNum, setReferenceNum] = React.useState('');
  const [memo, setMemo] = React.useState('');
  const [errorMsg, setErrorMsg] = React.useState('');

  const processPaymentDetails = () => {
    if (amount === '') {
      // errorHandler is only used within formviewer, outside the returns should use setErrorMsg
      if (props.errorHandler) {
        props.errorHandler('Amount is required');
      } else {
        setErrorMsg('Amount is required');
      }
      return;
    }

    // $15,600.55 -> 1560055
    const amt = parseInt(amount.replace(/[$,.]/g, ''));

    if (!isValidCalcServerDate(dateReceived)) {
      if (props.errorHandler) {
        props.errorHandler('Date Received Field is not valid. Must be in the format MM/DD/YYYY');
      } else {
        setErrorMsg('Date Received Field is not valid. Must be in the format MM/DD/YYYY');
      }
      return;
    }
    if (amt < -999999 || amt > 999999) {
      if (props.errorHandler) {
        props.errorHandler('Amount must be between -$9,999.99 and $9,999.99');
      } else {
        setErrorMsg('Amount must be between -$9,999.99 and $9,999.99');
      }
      return;
    }

    props.processPaymentDetails(
      dateReceived,
      receivedFrom,
      paymentMethod,
      amt.toString(),
      referenceNum,
      memo,
      props.returnPaymentInfo.paymentGUID,
    );

    props.onClose();
  };

  const formatMaksedSSN = ssn => {
    if (ssn.length === 9) {
      return 'XXXX-XX-' + ssn.substring(5, 9);
    }
    return '';
  };

  const handlePrintPayments = () => {
    let copyOfPayInfo = {};
    copyOfPayInfo = Object.assign(copyOfPayInfo, props.returnPaymentInfo);

    copyOfPayInfo.pssn = copyOfPayInfo.pssn ? formatMaksedSSN(copyOfPayInfo.pssn) : '';
    copyOfPayInfo.sssn = copyOfPayInfo.sssn ? formatMaksedSSN(copyOfPayInfo.sssn) : '';

    copyOfPayInfo.netInvoiceAmount = props.returnPaymentInfo.netInvoiceAmount
      ? formatDollarAmt(props.returnPaymentInfo.netInvoiceAmount, true)
      : '';
    copyOfPayInfo.due = props.returnPaymentInfo.due
      ? formatDollarAmt(props.returnPaymentInfo.due, true)
      : '';
    copyOfPayInfo.dateReceived = dateReceived;
    copyOfPayInfo.receivedFrom = receivedFrom;
    copyOfPayInfo.paymentMethod = paymentMethod;
    copyOfPayInfo.amount = amount;
    copyOfPayInfo.referenceNum = referenceNum;
    copyOfPayInfo.memo = memo;
    generatePrintManualPaymentForm(copyOfPayInfo, props.isBusiness);
  };

  return (
    <>
      <Grid
        container
        spacing={8}
        alignContent="flex-start"
        justify="flex-start"
        alignItems="baseline"
      >
        <Grid item xs={6}>
          <FormControl fullWidth required>
            <InputLabel htmlFor="txttaxdatereceived" shrink>
              Date Received(MM/DD/YYYY)
            </InputLabel>
            <NumberFormat
              name="taxdatereceived"
              id="txttaxdatereceived"
              value={dateReceived}
              disableUnderline
              customInput={Input}
              onChange={e => setDateReveived(e.target.value)}
              format="##/##/####"
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel shrink>Received From</InputLabel>
            <Input
              id="txtrcvdFrom"
              disableUnderline
              onChange={e => setReceivedFrom(e.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth required>
            <InputLabel shrink>Payment Method</InputLabel>
            <NativeSelect
              disableUnderline
              id="selectpaymentmethod"
              onChange={e => setPaymentMethod(e.target.value)}
              value={paymentMethod}
            >
              <option key="paytype1" value={'CHECK'}>
                CHECK
              </option>
              <option key="paytype2" value={'CASH'}>
                CASH
              </option>
              <option key="paytype3" value={'CREDIT CARD'}>
                CREDIT CARD
              </option>
              <option key="paytype4" value={'DEBIT CARD'}>
                DEBIT CARD
              </option>
              <option key="paytype5" value={'CASH APP'}>
                CASH APP
              </option>
            </NativeSelect>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth required>
            <InputLabel htmlFor="txttaxamount" shrink>
              Amount
            </InputLabel>
            <NumberFormat
              name="taxamount"
              id="txttaxamount"
              value={amount}
              disableUnderline
              prefix="$"
              fixedDecimalScale={true}
              decimalScale={2}
              customInput={Input}
              onChange={e => setAmount(e.target.value)}
              thousandSeparator={true}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel shrink>Reference Number</InputLabel>
            <Input
              id="txtrefNum"
              disableUnderline
              onChange={e => setReferenceNum(e.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth required>
            <InputLabel shrink>Received By</InputLabel>
            <Input
              id="txtRcvdBy"
              disableUnderline
              disabled
              classes={{ root: classes.disabledInp }}
              value={props.returnPaymentInfo.receivedBy || ''}
            />
          </FormControl>
        </Grid>
        <Grid item xs={8}>
          <FormControl fullWidth required>
            <InputLabel shrink>Serial Number</InputLabel>
            <Input
              id="txtSerialNum"
              disableUnderline
              disabled
              classes={{ root: classes.disabledInp }}
              value={props.returnPaymentInfo.paymentGUID || ''}
            />
          </FormControl>
        </Grid>
        {/* Placeholder */}
        <Grid item xs={4} />
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel shrink>Memo</InputLabel>
            <Input
              id="txtMemo"
              disableUnderline
              multiline={true}
              style={
                props.shortScreenRez ? { height: '6vh', paddingTop: '2rem' } : { height: '8vh' }
              }
              rows={3}
              onChange={e => setMemo(e.target.value)}
            />
          </FormControl>
        </Grid>
        {/* error message */}
        {errorMsg && (
          <Grid item xs={12}>
            <Typography variant="body1" color="error" gutterBottom>
              {errorMsg}
            </Typography>
          </Grid>
        )}
        <Grid classes={{ item: classes.manualPaymentButtonGrid }} item xs={12}>
          <Button
            id="btnManualPaymentCancel"
            classes={{ root: classes.payBtn }}
            onClick={() => props.onClose()}
          >
            Close
          </Button>
          <Button
            id="btnManualPaymentPrint"
            classes={{ root: classes.payBtn }}
            onClick={() => handlePrintPayments()}
          >
            Print
          </Button>
          <Button id="btnManualPaymentSave" onClick={() => processPaymentDetails()}>
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(styles)(ManualPayment);
