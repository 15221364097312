// External imports
import React, { Component } from 'react';
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  Input,
  Paper,
  MenuItem,
  Select,
  Divider,
  Checkbox,
  Button,
  Snackbar,
  NativeSelect,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
// Internal imports
import {
  BUSINESS_SETUP_PAGES,
  EXTENDED_SETUP_PAGES,
  REQUIRED_SETUP_PAGES,
} from '~/app/constants.js';
import { canAccessCorp } from '~/app/Utility/general';
import SimpleDialog from '#/Common/SimpleDialog.jsx';
import XlinkAPI from '~/app/api/xlinkAPI';
import ErrorHelpers from '~/app/errorHelpers.js';
import infoIcon from '~/images/icons/icons8-info.svg';
import WebHelpers from '~/app/webHelpers.js';
// Styling imports
import '~/app/Pages/Setup/css/setup.css';
import '~/app/Components/NewAccount/SingleOffice/css/efilingSetup.css';

// TODO: Pull Config Fields from DB and render them dynamically
class EFilingSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      check: '',
      defaultEFIN: '3254978', // Get this value from db
      defaultSBIN: '34598GDJS23', // Get the value from db
      noCrossCollectionNYME: false,
      discardACKLetters: false,
      requireThirdPartyDesignee: false,
      autoGeneratePIN: false,
      requireUserStatus: false,
      requireSignatureDate: false,
      requireCellPhoneCarrierInfo: false,
      leaveReturnStatusAckBank: false,
      leaveReturnStatusAckNonBank: false,
      requireReferral: false,
      autoAddFilingOptions: false,
      // Year to year
      excludeW2: false,
      excludeDocs: false,
      includePreparerInfo: false,
      includeBankInfo: false,
      autoAddStateReturn1: '',
      autoAddStateReturn2: '',
      defaultPhoneNum: '',
      defaultEFST: '',
      PreparerTb: {},
      confirmCancel: false,
      gotoPrev: false,
      snackbarOpen: false,
      // Modals
      isInfoAutoGeneratePINOpen: false,
    };
  }

  componentDidMount() {
    this.getInitialConfigState();
  }

  getInitialConfigState = () => {
    window.scrollTo(0, 0);
    XlinkAPI.getEFileSetup(this.props.loginID)
      .then(res => {
        const data = res.data;
        // TODO: Modify when converting to dynamic rendering
        this.setState({
          eFileConfig: data.config,
          noCrossCollectionNYME: data.config.DEBT.configValue === '1',
          discardACKLetters: data.config.ACKL.configValue === '1',
          requireThirdPartyDesignee: data.config.RTPD.configValue === '1',
          autoGeneratePIN: data.config.APIN.configValue === '1',
          requireUserStatus: data.config.USST.configValue === '1',
          requireSignatureDate: data.config.FPDT.configValue === '1',
          requireCellPhoneCarrierInfo: data.config.CPCI.configValue === '1',
          leaveReturnStatusAckBank: data.config.BKST.configValue === '1',
          autoAddFilingOptions: data.config.AAFO.configValue === '1',
          excludeW2: data.config.YTYF.configValue === '1',
          excludeDocs: data.config.YTYS.configValue === '1',
          includePreparerInfo: data.config.YTYP.configValue === '1',
          includeBankInfo: data.config.YTYB.configValue === '1',
          autoAddStateReturn1: data.config.ERCD.configValue,
          autoAddStateReturn2: data.config.EROS.configValue,
          defaultPhoneNum: data.config.NUMB.configValue,
          leaveReturnStatusAckNonBank: data.config.NBKS.configValue === '1',
          requireReferral: data.config.REFV.configValue === '1',
          defaultEFST: data.config.EFST.configValue,
        });
      })
      .catch(err => {
        ErrorHelpers.handleError('Failed to get EFILE Setup', err);
      });
  };

  toggleConfirmCancel = toggle => {
    this.setState({
      confirmCancel: toggle,
    });
  };

  handleInfoModal = () => {
    this.setState({ isInfoAutoGeneratePINOpen: !this.state.isInfoAutoGeneratePINOpen });
  };

  // Handle onChange without validation for checkbox
  handleCheckbox = event => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  // Handle onChange without validation for selec/menuitem fields
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  // Handle onChange without validation for input fields
  handleInputChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  // render a dropdown where the value is an int- requires type for styling
  renderGenericDropdown = (type, contents) => {
    const defaultPhoneStyle = {
      width: '12vw',
      height: '3.5vh',
      marginRight: '3vw',
      fontColor: '#ffffff',
      paddingTop: '0px',
      paddingBottom: '0px',
      paddingLeft: '5px',
    };
    const menuItemStyle = {
      paddingTop: '0px',
      paddingBottom: '0px',
      color: '#0077FF',
      height: '1.5em',
    };

    const defaultChoice = (
      <MenuItem value="" style={menuItemStyle}>
        <em>None</em>
      </MenuItem>
    );

    const menuItems = contents.map((entry, index) => {
      return (
        <MenuItem key={entry + index} value={index + 1} style={menuItemStyle}>
          {entry}
        </MenuItem>
      );
    });

    switch (type) {
      case 'autoAdd1':
        return (
          <Select
            value={this.state.autoAddStateReturn1}
            onChange={this.handleChange}
            input={
              <Input
                name="autoAddStateReturn1"
                id="autoadd-stateReturn1"
                style={defaultPhoneStyle}
                disableUnderline
              />
            }
          >
            {defaultChoice}
            {menuItems}
          </Select>
        );
      case 'autoAdd2':
        return (
          <Select
            value={this.state.autoAddStateReturn2}
            onChange={this.handleChange.bind(this)}
            input={
              <Input
                name="autoAddStateReturn2"
                id="autoadd-stateReturn2"
                style={defaultPhoneStyle}
                disableUnderline
              />
            }
          >
            {defaultChoice}
            {menuItems}
          </Select>
        );
      case 'phoneFrom':
        return (
          <NativeSelect
            id="ddlDefaultPhoneNum"
            onChange={this.handleChange}
            name="defaultPhoneNum"
            value={this.state.defaultPhoneNum}
            className="efiling-setup-dropdown"
            disableUnderline
          >
            {contents}
          </NativeSelect>
        );
      case 'EFST':
        return (
          <NativeSelect
            id="ddlEFST"
            onChange={this.handleChange}
            name="defaultEFST"
            value={this.state.defaultEFST}
            className="efiling-setup-dropdown"
            disableUnderline
          >
            {contents}
          </NativeSelect>
        );
    }
  };

  submitEfileData = gotoNext => {
    const submitNoCrossCollectionNYME = {
      configID: this.state.eFileConfig.DEBT.configID,
      configValue: this.state.noCrossCollectionNYME ? '1' : '0',
    };
    const submitDiscardACKLetters = {
      configID: this.state.eFileConfig.ACKL.configID,
      configValue: this.state.discardACKLetters ? '1' : '0',
    };
    const submitRequireThirdPartyDesignee = {
      configID: this.state.eFileConfig.RTPD.configID,
      configValue: this.state.requireThirdPartyDesignee ? '1' : '0',
    };
    const submitAutoGeneratePIN = {
      configID: this.state.eFileConfig.APIN.configID,
      configValue: this.state.autoGeneratePIN ? '1' : '0',
    };
    const submitRequireUserStatus = {
      configID: this.state.eFileConfig.USST.configID,
      configValue: this.state.requireUserStatus ? '1' : '0',
    };
    const submitRequireSignatureDate = {
      configID: this.state.eFileConfig.FPDT.configID,
      configValue: this.state.requireSignatureDate ? '1' : '0',
    };
    const submitRequireCellPhoneCarrierInfo = {
      configID: this.state.eFileConfig.CPCI.configID,
      configValue: this.state.requireCellPhoneCarrierInfo ? '1' : '0',
    };
    const submitLeaveReturnStatusAckBank = {
      configID: this.state.eFileConfig.BKST.configID,
      configValue: this.state.leaveReturnStatusAckBank ? '1' : '0',
    };
    const submitLeaveReturnStatusAckNonBank = {
      configID: this.state.eFileConfig.NBKS.configID,
      configValue: this.state.leaveReturnStatusAckNonBank ? '1' : '0',
    };
    const submitRequireReferral = {
      configID: this.state.eFileConfig.REFV.configID,
      configValue: this.state.requireReferral ? '1' : '0',
    };
    const submitAutoAddFilingOptions = {
      configID: this.state.eFileConfig.AAFO.configID,
      configValue: this.state.autoAddFilingOptions ? '1' : '0',
    };
    const submitExcludeW2 = {
      configID: this.state.eFileConfig.YTYF.configID,
      configValue: this.state.excludeW2 ? '1' : '0',
    };
    const submitExcludeDocs = {
      configID: this.state.eFileConfig.YTYS.configID,
      configValue: this.state.excludeDocs ? '1' : '0',
    };
    const submitIncludePreparerInfo = {
      configID: this.state.eFileConfig.YTYP.configID,
      configValue: this.state.includePreparerInfo ? '1' : '0',
    };
    const submitIncludeBankInfo = {
      configID: this.state.eFileConfig.YTYB.configID,
      configValue: this.state.includeBankInfo ? '1' : '0',
    };
    const submitAutoAddStateReturn1 = {
      configID: this.state.eFileConfig.ERCD.configID,
      configValue: this.state.autoAddStateReturn1,
    };
    const submitAutoAddStateReturn2 = {
      configID: this.state.eFileConfig.EROS.configID,
      configValue: this.state.autoAddStateReturn2,
    };
    const submitDefaultPhoneNum = {
      configID: this.state.eFileConfig.NUMB.configID,
      configValue: this.state.defaultPhoneNum,
    };
    const submitDefaultEFST = {
      configID: this.state.eFileConfig.EFST.configID,
      configValue: this.state.defaultEFST,
    };
    const eFileConfig = [
      submitNoCrossCollectionNYME,
      submitDiscardACKLetters,
      submitRequireThirdPartyDesignee,
      submitAutoGeneratePIN,
      submitRequireUserStatus,
      submitRequireSignatureDate,
      submitRequireCellPhoneCarrierInfo,
      submitLeaveReturnStatusAckBank,
      submitLeaveReturnStatusAckNonBank,
      submitRequireReferral,
      submitAutoAddFilingOptions,
      submitExcludeW2,
      submitExcludeDocs,
      submitIncludePreparerInfo,
      submitIncludeBankInfo,
      submitAutoAddStateReturn1,
      submitAutoAddStateReturn2,
      submitDefaultPhoneNum,
      submitDefaultEFST,
    ];
    XlinkAPI.updateEFileSetup(eFileConfig, this.props.loginID)
      .then(() => {
        if (gotoNext) {
          this.props.loadSetupComponent(EXTENDED_SETUP_PAGES.PRINTING.NAME);
        } else {
          this.toggleSuccessSnackbar(true);
        }
      })
      .catch(err => {
        ErrorHelpers.handleError('EFile Setup Error', err);
      });
  };

  toggleSuccessSnackbar = toggle => {
    this.setState({
      snackbarOpen: toggle,
    });
  };

  handleConfirmedCancel = () => {
    const payload = WebHelpers.getJWTPayload();
    let page = REQUIRED_SETUP_PAGES.LICENCING.NAME;

    if (canAccessCorp(payload, this.props.officeProfile, this.props.currentView)) {
      page = BUSINESS_SETUP_PAGES.BILLING.NAME;
    }

    this.toggleConfirmCancel(false);
    if (this.state.gotoPrev) {
      this.props.loadSetupComponent(page);
    } else {
      this.getInitialConfigState();
    }
  };

  render() {
    // TODO: should we get these from db/server?
    const entries = ['ENTRY 1', 'ENTRY 2', 'ENTRY 3', 'ENTRY 4', 'ENTRY 5'];
    const options = ['OPTION 1', 'OPTION 2', 'OPTION 3', 'OPTION 4', 'OPTION 5'];
    const from = [
      <option key="wrkPhone" value="0">
        Work Phone Number
      </option>,
      <option key="homePhone" value="1">
        Home Phone Number
      </option>,
      <option key="cellPhone" value="2">
        Cell Phone Number
      </option>,
    ];
    const optionsEFST = [
      <option key="always" value="0">
        Always
      </option>,
      <option key="whenRequired" value="1">
        When Required
      </option>,
      <option key="never" value="2">
        Never
      </option>,
    ];

    const labelWidthStyle = {
      height: '1.6vh',
      color: '#354052',
      textAlign: 'left',
      fontFamily: 'Roboto',
      fontSize: '14px',
    };
    const defaultEFINStyle = {
      display: 'none',
      float: 'left',
      height: '1.6vh',
      color: '#354052',
      textAlign: 'left',
      fontFamily: 'Roboto',
      fontSize: '14px',
      marginBottom: '2vh',
      fontWeight: '500',
      marginRight: '0.5vw',
    };

    // TODO: Grab EFIN

    const defaultEFINStyleNum = {
      display: 'none',
      height: '1.6vh',
      color: '#354052',
      textAlign: 'left',
      fontFamily: 'Roboto',
      fontSize: '14px',
      marginBottom: '2vh',
    };
    const defaultSBINStyle = {
      display: 'none',
      float: 'left',
      height: '1.6vh',
      color: '#354052',
      textAlign: 'left',
      fontFamily: 'Roboto',
      fontSize: '14px',
      marginBottom: '4vh',
      fontWeight: '500',
      marginRight: '0.5vw',
    };

    // TODO: Grab SBIN

    const defaultSBINStyleNum = {
      display: 'none',
      height: '1.6vh',
      color: '#354052',
      textAlign: 'left',
      fontFamily: 'Roboto',
      fontSize: '14px',
      marginBottom: '4vh',
    };
    const labelWidthStylePhone = {
      height: '1.6vh',
      color: '#354052',
      fontFamily: 'Roboto',
      fontSize: '14px',
      width: '30vw',
    };
    const labelWidthStyleCheckbox = {
      height: '2vh',
      color: '#354052',
      textAlign: 'left',
      fontFamily: 'Roboto',
      fontSize: '14px',
      marginTop: '0.5vh',
      marginBottom: '0.5vh',
    };
    const labelWidthStyleDropdown = {
      marginLeft: '-10px',
    };

    return (
      <div className="container-fixed" style={{ marginTop: '2.5rem' }}>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.snackbarOpen}
          autoHideDuration={2500}
          onClose={() => this.toggleSuccessSnackbar(false)}
          id="snkbarconfsaved"
          message={<span id="success-message">Configuration Saved</span>}
        />
        <Paper>
          <form style={{ marginLeft: '3vw', marginRight: '3vw', marginTop: '1vh' }}>
            <div style={{ marginBottom: '45px' }}>
              <span>
                <div
                  style={{
                    textAlign: 'left',
                    paddingTop: '3vh',
                    paddingBottom: '0.5vh',
                  }}
                >
                  <span
                    style={{
                      fontFamily: 'Roboto',
                      fontSize: '21px',
                      letterSpacing: '0.14px',
                      color: '#1A173B',
                    }}
                  >
                    E-Filing
                  </span>
                </div>
                <div style={{ marginLeft: '2vw' }}>
                  <div style={defaultEFINStyle}>Default EFIN:</div>{' '}
                  <div style={defaultEFINStyleNum}>{this.state.defaultEFIN}</div>
                  <div style={defaultSBINStyle}>Default SBIN:</div>{' '}
                  <div style={defaultSBINStyleNum}>{this.state.defaultSBIN}</div>{' '}
                </div>

                <div className="efiling-setup-settings-container">
                  <div>
                    <FormControl>
                      <div className="efiling-setup-content">
                        <FormControlLabel
                          label="No Cross Collection in NY/ME"
                          style={labelWidthStyleCheckbox}
                          control={
                            <Checkbox
                              style={{
                                width: 17,
                                height: 16,
                                marginRight: '0.3vw',
                                backgroundColor: '#F3F9FF',
                              }}
                              icon={
                                <CheckBoxOutlineBlankIcon
                                  style={{ fontSize: 24, color: '#8FC3FF' }}
                                  color="secondary"
                                />
                              }
                              checkedIcon={
                                <CheckBoxIcon style={{ fontSize: 24 }} color="primary" />
                              }
                              id="chkNoCrossCollectionNYMEEfilingSetup"
                              name="noCrossCollectionNYME"
                              checked={this.state.noCrossCollectionNYME}
                              onChange={this.handleCheckbox}
                              color="primary"
                              inputProps={{ height: '20px', width: '20px' }}
                            />
                          }
                        />
                      </div>
                    </FormControl>
                  </div>
                  <div>
                    <FormControl>
                      <div className="efiling-setup-content">
                        <FormControlLabel
                          label="Auto Generate Pin for Taxpayer/Spouse/Tax Preparer"
                          style={labelWidthStyleCheckbox}
                          control={
                            <Checkbox
                              style={{
                                width: 17,
                                height: 16,
                                marginRight: '0.3vw',
                                backgroundColor: '#F3F9FF',
                              }}
                              icon={
                                <CheckBoxOutlineBlankIcon
                                  style={{ fontSize: 24, color: '#8FC3FF' }}
                                  color="secondary"
                                />
                              }
                              checkedIcon={
                                <CheckBoxIcon style={{ fontSize: 24 }} color="primary" />
                              }
                              id="chkAutoGeneratePINEfilingSetup"
                              name="autoGeneratePIN"
                              checked={this.state.autoGeneratePIN}
                              onChange={this.handleCheckbox}
                              color="primary"
                              inputProps={{ height: '20px', width: '20px' }}
                            />
                          }
                        />
                        <img
                          id="infoAutoGeneratePIN"
                          onClick={() => this.handleInfoModal()}
                          className="efiling-setup-content-info-btn"
                          src={infoIcon}
                        />
                      </div>
                    </FormControl>
                  </div>
                  <div>
                    <FormControl>
                      <div className="efiling-setup-content">
                        <FormControlLabel
                          label="Require Signature Print Date"
                          style={labelWidthStyleCheckbox}
                          control={
                            <Checkbox
                              style={{
                                width: 17,
                                height: 16,
                                marginRight: '0.3vw',
                                backgroundColor: '#F3F9FF',
                              }}
                              icon={
                                <CheckBoxOutlineBlankIcon
                                  style={{ fontSize: 24, color: '#8FC3FF' }}
                                  color="secondary"
                                />
                              }
                              checkedIcon={
                                <CheckBoxIcon style={{ fontSize: 24 }} color="primary" />
                              }
                              id="chkRequireSignatureDateEfilingSetup"
                              name="requireSignatureDate"
                              checked={this.state.requireSignatureDate}
                              onChange={this.handleCheckbox}
                              color="primary"
                              inputProps={{ height: '20px', width: '20px' }}
                            />
                          }
                        />
                      </div>
                    </FormControl>
                  </div>
                  <div>
                    <FormControl>
                      <div className="efiling-setup-content">
                        <FormControlLabel
                          label="Leave the Tax Return Status at Acknowledged for Bank Products"
                          style={labelWidthStyleCheckbox}
                          control={
                            <Checkbox
                              style={{
                                width: 17,
                                height: 16,
                                marginRight: '0.3vw',
                                backgroundColor: '#F3F9FF',
                              }}
                              icon={
                                <CheckBoxOutlineBlankIcon
                                  style={{ fontSize: 24, color: '#8FC3FF' }}
                                  color="secondary"
                                />
                              }
                              checkedIcon={
                                <CheckBoxIcon style={{ fontSize: 24 }} color="primary" />
                              }
                              id="chkLeaveReturnStatusAckBankEfilingSetup"
                              name="leaveReturnStatusAckBank"
                              checked={this.state.leaveReturnStatusAckBank}
                              onChange={this.handleCheckbox}
                              color="primary"
                              inputProps={{ height: '20px', width: '20px' }}
                            />
                          }
                        />
                      </div>
                    </FormControl>
                  </div>
                  <div>
                    <FormControl>
                      <div className="efiling-setup-content">
                        <FormControlLabel
                          label="Require User Status"
                          style={labelWidthStyleCheckbox}
                          control={
                            <Checkbox
                              style={{
                                width: 17,
                                height: 16,
                                marginRight: '0.3vw',
                                backgroundColor: '#F3F9FF',
                              }}
                              icon={
                                <CheckBoxOutlineBlankIcon
                                  style={{ fontSize: 24, color: '#8FC3FF' }}
                                  color="secondary"
                                />
                              }
                              checkedIcon={
                                <CheckBoxIcon style={{ fontSize: 24 }} color="primary" />
                              }
                              id="chkRequireUserStatusEfilingSetup"
                              name="requireUserStatus"
                              checked={this.state.requireUserStatus}
                              onChange={this.handleCheckbox}
                              color="primary"
                              inputProps={{ height: '20px', width: '20px' }}
                            />
                          }
                        />
                      </div>
                    </FormControl>
                  </div>
                  <div>
                    <FormControl>
                      <div className="efiling-setup-content">
                        <FormControlLabel
                          label="Leave the Tax Return Status at Acknowledged for Non Bank Products"
                          style={labelWidthStyleCheckbox}
                          control={
                            <Checkbox
                              style={{
                                width: 17,
                                height: 16,
                                marginRight: '0.3vw',
                                backgroundColor: '#F3F9FF',
                              }}
                              icon={
                                <CheckBoxOutlineBlankIcon
                                  style={{ fontSize: 24, color: '#8FC3FF' }}
                                  color="secondary"
                                />
                              }
                              checkedIcon={
                                <CheckBoxIcon style={{ fontSize: 24 }} color="primary" />
                              }
                              id="chkLeaveReturnStatusAckNonBankEfilingSetup"
                              name="leaveReturnStatusAckNonBank"
                              checked={this.state.leaveReturnStatusAckNonBank}
                              onChange={this.handleCheckbox}
                              color="primary"
                              inputProps={{ height: '20px', width: '20px' }}
                            />
                          }
                        />
                      </div>
                    </FormControl>
                  </div>
                  <div className="efiling-setup-content">
                    <FormControlLabel
                      label="Default Taxpayer's phone number from "
                      labelPlacement="start"
                      style={labelWidthStyleDropdown}
                      control={this.renderGenericDropdown('phoneFrom', from)}
                    />
                  </div>
                  <div className="efiling-setup-content">
                    <FormControlLabel
                      label="Prompt to File State Electronically"
                      labelPlacement="start"
                      style={labelWidthStyleDropdown}
                      control={this.renderGenericDropdown('EFST', optionsEFST)}
                    />
                  </div>
                </div>

                <div style={{ float: 'none', display: 'none' }}>
                  <div
                    style={{
                      color: '#354052',
                      fontFamily: 'Roboto',
                      fontSize: '14px',
                      fontWeight: '500',
                      marginBottom: '0.4vh',
                      marginLeft: '2vw',
                    }}
                  >
                    Auto Add State Return(1040 ONLY)
                  </div>
                  <div style={{ float: 'left', marginLeft: '2vw' }}>
                    <FormControl>
                      <InputLabel style={labelWidthStyle} htmlFor="autoadd-stateReturn1" shrink>
                        Auto Add State Return From
                      </InputLabel>
                      {this.renderGenericDropdown('autoAdd1', entries)}
                    </FormControl>
                  </div>
                  <div style={{ marginLeft: '2vw' }}>
                    <FormControl>
                      <InputLabel style={labelWidthStyle} htmlFor="autoadd-stateReturn2" shrink />
                      {this.renderGenericDropdown('autoAdd2', options)}
                    </FormControl>
                  </div>
                  <div style={{ marginLeft: '2vw' }}>
                    <FormControl>
                      <InputLabel style={labelWidthStylePhone} htmlFor="default-PhoneNum" shrink>
                        Default Taxpayer&quot;s Phone Number from
                      </InputLabel>
                      {this.renderGenericDropdown('phoneFrom', from)}
                    </FormControl>
                  </div>
                </div>
                <br />
              </span>
            </div>
            <Divider />
            <div className="setup-nav-btns">
              <Button
                id="btnPreviousEFileSetup"
                color="primary"
                onClick={() => {
                  this.setState({ gotoPrev: true });
                  this.toggleConfirmCancel(true);
                }}
              >
                Previous (cancel)
              </Button>
              <span>
                <Button
                  id="btnCancelEFileSetup"
                  color="primary"
                  onClick={() => {
                    this.setState({ gotoPrev: false });
                    this.toggleConfirmCancel(true);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  id="btnSaveEFileSetup"
                  color="primary"
                  onClick={() => this.submitEfileData(false)}
                >
                  Save
                </Button>
              </span>
              <Button
                id="btnNextEFileSetup"
                color="primary"
                onClick={() => this.submitEfileData(true)}
              >
                Save &amp; Next
              </Button>
            </div>
            <br />
          </form>
        </Paper>
        <SimpleDialog
          open={this.state.confirmCancel}
          onClose={() => this.toggleConfirmCancel(false)}
          onConfirm={this.handleConfirmedCancel}
          dialogTitle={'Lose Unsaved Changes?'}
          contentText={'Are you sure you want to undo any pending changes?'}
        />
        <SimpleDialog
          open={this.state.isInfoAutoGeneratePINOpen}
          onConfirm={() => this.handleInfoModal()}
          dialogTitle="Info"
          contentText="Auto Generate Pin for Taxpayer/Spouse/Tax Preparer:"
          contentTextTwo="The Pin for Tax Preparer comes from the ERO section in Settings. Please fill in the ERO Pin field in the ERO section to have it included in the return."
          confirmText="OK"
          styled={true}
        />
      </div>
    );
  }
}

export default EFilingSetup;
