// Internal imports
import XlinkAPI from '~/app/api/xlinkAPI';
import ErrorHelpers from '~/app/errorHelpers.js';
import { USERDB } from '~/app/constants.js';
// Redux imports
import { takeLatest, call, put } from 'redux-saga/effects';
import {
  actions,
  FETCH_USER_DB_LIST,
  REQUEST_DELETE_USER_DB_RECORD,
  REQUEST_UPSERT_USER_DB_RECORD,
  FETCH_OFFICE_SETUP_PROGESSS_FLAGS,
} from './duck';

export function* watchSetupPages() {
  yield takeLatest(FETCH_USER_DB_LIST, fetchUserDBList);
  yield takeLatest(REQUEST_UPSERT_USER_DB_RECORD, upsertUserDBRecord);
  yield takeLatest(REQUEST_DELETE_USER_DB_RECORD, deleteUserDBRecord);
  yield takeLatest(FETCH_OFFICE_SETUP_PROGESSS_FLAGS, fetchOfficeSetupProgressFlags);
}

export function* fetchOfficeSetupProgressFlags(payload) {
  try {
    if (payload.endpoint === undefined) {
      payload.endpoint = `${XLINK_API_URL}/account/office/setup/progress/${payload.loginID}`;
    }

    const statusFlags = yield call(XlinkAPI.simpleGetRequest, payload.endpoint);
    yield put(actions.setOfficeSetupFlags(statusFlags.data));
  } catch (error) {
    ErrorHelpers.handleError('Error fetching office setup status', error);
    yield put(actions.setOfficeSetupFlagsFailure());
  }
}

export function* fetchUserDBList(payload) {
  try {
    if (payload.endpoint === undefined) {
      payload.endpoint = `${XLINK_API_URL}/userdb/gettable`;
    }
    yield put(
      actions.updateDBType(
        payload.dbType,
        payload.idColHeader,
        payload.dataColHeader,
        payload.dbListTitle,
      ),
    );

    const dbEntries = yield call(XlinkAPI.post, payload.endpoint, {
      db_type: payload.dbType,
      loginID: payload.loginID,
    });
    if (dbEntries.data == null) {
      // remove after all endpoints return data
      dbEntries.data = [];
    }

    for (let i = 0; i < dbEntries.data.length; i++) {
      const fields = getKeyAndDescFields(payload.dbType, dbEntries.data[i]);
      dbEntries.data[i].key = fields.key;
      dbEntries.data[i].desc = fields.desc;
    }

    yield put(actions.requestDBListSuccess(dbEntries.data));
  } catch (error) {
    ErrorHelpers.handleError('Error fetching user database records', error);
    yield put(actions.requestDBListError());
  }
}

export function* upsertUserDBRecord(payload) {
  try {
    yield call(XlinkAPI.upsertUserDB, payload.dbType, payload.loginID, payload.dbData);

    yield put(actions.upsertUserDBRecordSuccess());
  } catch (error) {
    ErrorHelpers.handleError('Unable to add to user database', error);
    yield put(actions.upsertUserDBRecordError());
  }
}

export function* deleteUserDBRecord(payload) {
  try {
    yield call(XlinkAPI.deleteUserDBRecord, payload.dbType, payload.loginID, payload.key);

    yield put(actions.deleteUserDBRecordSuccess(payload.key));
  } catch (error) {
    ErrorHelpers.handleError('Error deleting user database record', error);
    yield put(actions.deleteUserDBRecordError());
  }
}

const getKeyAndDescFields = (dbType, dbRec) => {
  const data = {};
  switch (dbType) {
    case USERDB.ZIPCODE:
      data.key = dbRec.zip_code.trim();
      data.desc = dbRec.city + ', ' + dbRec.state_code;
      break;
    case USERDB.EFIN:
      data.key = dbRec.efin;
      data.desc = dbRec.firm_name;
      break;
    case USERDB.EMPLOYER:
      data.key = dbRec.employer_ein;
      data.desc = dbRec.firmname;
      break;
    case USERDB.CAREPROVIDER:
      data.key = dbRec.provider_code;
      data.desc = dbRec.provider_name;
      break;
    case USERDB.REFERRALS:
      data.key = dbRec.referral;
      data.desc = '';
      break;
    case USERDB.DONEE:
      data.key = dbRec.ein;
      data.desc = `${dbRec.donee_name} - ${dbRec.city}, ${dbRec.state_code} ${dbRec.postal_code}`;
      break;
    case USERDB.BANKRTN:
      data.key = dbRec.bank_rtn;
      data.desc = dbRec.bank_name;
      break;
    case USERDB.OCCUPATION:
      data.key = dbRec.occupation;
      data.desc = '';
      break;
    case USERDB.SITEIDENTIFIERS:
      data.key = dbRec.site_code;
      data.desc = dbRec.site_description;
      break;
    case USERDB.USERSTATUSCODES:
      data.key = dbRec.status_code;
      data.desc = dbRec.status_description;
      break;
    case USERDB.EDUINST:
      data.key = dbRec.institution_ein;
      data.desc = dbRec.institution_name;
      break;
  }
  return data;
};
