import { SIDE_TABS, TITLE_TAB_NAMES } from '~/app/constants.js';
export const selectors = {
  getMobileReturnState: state => state.returnList.mobileReturns,
  getAvailableMobileReturns: state => {
    return state.returnList.mobileReturns.results.filter(ret => {
      return ret.newFlag !== 0;
    });
  },
  getAvailableMobileReturnCount: state => {
    let availReturns = state.returnList.mobileReturns.results.filter(ret => {
      return ret.newFlag !== 0;
    });
    return availReturns.length;
  },
  getClickThroughReturnFilter: state => {
    let tabIDFound = state.app.tabState.tabMap[SIDE_TABS.DASHBOARD_MAIN].findIndex(tab => {
      return tab.name == TITLE_TAB_NAMES.TAX_RETURNS;
    });
    return state.app.tabState.tabMap[SIDE_TABS.DASHBOARD_MAIN][tabIDFound].filter == undefined
      ? []
      : state.app.tabState.tabMap[SIDE_TABS.DASHBOARD_MAIN][tabIDFound].filter;
  },
  getTaxReturnsListTitleTabID: state => {
    let tabIDFound = state.app.tabState.tabMap[SIDE_TABS.DASHBOARD_MAIN].findIndex(tab => {
      return tab.name == TITLE_TAB_NAMES.TAX_RETURNS;
    });
    return tabIDFound;
  },
};
