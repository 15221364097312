// External imports
import React, { Fragment, useEffect } from 'react';
import {
  Button,
  CircularProgress,
  Grid,
  Divider,
  Modal,
  Paper,
  withStyles,
} from '@material-ui/core';
// Internal imports
import MultiOfficeManagement from '~/app/Pages/Setup/ManageLicencing/components/MultiOfficeManagement.jsx';
import PagedTable from '#/Common/PagedTable.jsx';
import {
  setupLicenseDataForPagedTable,
  getRemoveText,
  INDIVIDUAL,
  BUSINESS,
} from './manageLicenseHelper.js';
import { useSetState } from '~/app/Utility/customHooks';
import AddLicenseModal from './components/AddLicenseModal.jsx';
import AssignLicenseModal from './components/AssignLicenseModal.jsx';
import LicenseTable from './components/LicenseTable.jsx';
import { HIERARCHY_TYPE } from '~/app/constants.js';
import XlinkAPI from '~/app/api/xlinkAPI';
import ErrorHelpers from '~/app/errorHelpers.js';
import WebHelpers, { uuid4, statusOK } from '~/app/webHelpers.js';
import SimpleDialog from '#/Common/SimpleDialog.jsx';
// Styling imports
import { styles } from '~/app/Pages/Setup/ManageLicencing/css/manageLicencing.js';
import { styles as pagedTableStyles } from '~/app/Components/Common/css/pagedTableStyles.jsx';

const ManageLicencing = props => {
  const { classes } = props;
  const payload = WebHelpers.getJWTPayload();
  const [state, setState] = useSetState({
    isManageMultiOfficeLicenseModalOpen: false,
    isRemoveModalOpen: false,
    selectedKey: 0,
    editingLicenseFlag: false,
    addProductLicenseFlag: false,
    showMultiOfficeManagement: false,
    showBusinessLicenseManagement: false,
    isInitialLoad: true,
    licencingSetup1040: props.licencingSetup1040 || {},
    licencingSetupBusiness: props.licencingSetupBusiness || {},
    offices: [],
    moLicensingSetup1040: null,
    selectedMultiOffice: null,
    topMostParentRole: props.topMostParentRole,
    isAddLicenseModalOpen: false,
    isAssignLicenseModalOpen: false,
    selectedLicenseType: INDIVIDUAL,
  });

  useEffect(() => {
    getAccountLicenses();
    getAccountOffices();
    getMOManagedLicenses();
  }, []);

  const getAccountLicenses = async (addLicenseFlag = false) => {
    if (!state.isInitialLoad) {
      try {
        const licenseData = await XlinkAPI.getAccountLicenseInfoByLoginID();
        if (statusOK(licenseData)) {
          setState({
            licencingSetup1040: licenseData.data.licenseInfo1040,
            licencingSetupBusiness: licenseData.data.licenseInfoBusiness,
          });
        }

        if (addLicenseFlag) {
          setState({ addProductLicenseFlag: false });
        }
      } catch (error) {
        ErrorHelpers.handleError('Unable to call get account license info: ', error);
      }
    } else {
      setState({
        licencingSetup1040: props.licencingSetup1040,
        licencingSetupBusiness: props.licencingSetupBusiness,
        isInitialLoad: false,
      });
    }
    props.isLoading(false);
  };

  const getAccountOffices = async () => {
    try {
      const officesData = await XlinkAPI.getAccountOfficesByLoginID();

      if (statusOK(officesData)) {
        await setState({
          offices: officesData?.data?.offices,
        });
      }
    } catch (error) {
      ErrorHelpers.handleError('Unable to call get MO accounts license info: ', error);
    }
  };

  const getMOManagedLicenses = async () => {
    try {
      const moLicenseData = await XlinkAPI.getMOManagedLicenseInfoByLoginID();

      if (statusOK(moLicenseData)) {
        await setState({
          moLicensingSetup1040: moLicenseData?.data?.multiOffices1040,
          moLicensingSetupBusiness: moLicenseData?.data?.multiOfficesBusiness,
        });
      }
    } catch (error) {
      ErrorHelpers.handleError('Unable to call get MO accounts license info: ', error);
    }
  };

  // getLicenseStatusText
  const getLicenseStatusText = officeID => {
    if (officeID) return <span style={styles.activeBubbleText}>Active</span>;

    return <span style={styles.unUsedBubbleText}>Unused</span>;
  };

  const openLicenseModal = (licenseInfo, selectedIndex, licenseType) => {
    const modalFlag = licenseInfo[selectedIndex].officeID === 0;
    // Depending on whether they clicked a row with an applied license or not open the correct modal
    setState({
      isAssignLicenseModalOpen: modalFlag,
      isRemoveModalOpen: !modalFlag,
      selectedKey: selectedIndex,
      selectedLicenseType: licenseType,
    });
  };

  // The remove license modal is just a SimpleDialog Component. While a license removal is being processed,
  // we should lock actions and shouldn't allow the user to close the modal.
  const closeRemoveLicenseModal = () => {
    if (!state.editingLicenseFlag) {
      setState({
        isRemoveModalOpen: false,
      });
    }
  };

  const closeAssignOfficeModal = () => {
    setState({
      editingLicenseFlag: false,
      isAssignLicenseModalOpen: false,
    });
  };

  const assignLicenseToOffice = async officeID => {
    setState({
      editingLicenseFlag: true,
    });

    const licensingSetup =
      state.selectedLicenseType === INDIVIDUAL
        ? state.licencingSetup1040
        : state.licencingSetupBusiness;

    try {
      await XlinkAPI.applyOfficeLicense(
        officeID,
        licensingSetup.licenseInfo[state.selectedKey].licenseID,
      );

      closeAssignOfficeModal();
      getAccountLicenses();
      getAccountOffices();
    } catch (error) {
      setState({
        editingLicenseFlag: false,
      });
      ErrorHelpers.handleError('Failed to assign license', error);
    }
  };

  const removeLicense = async () => {
    setState({
      editingLicenseFlag: true,
    });

    const licensingSetup =
      state.selectedLicenseType === INDIVIDUAL
        ? state.licencingSetup1040
        : state.licencingSetupBusiness;

    const appliedLicense = licensingSetup.licenseInfo[state.selectedKey];

    try {
      const res = await XlinkAPI.unapplyOfficeLicense(
        appliedLicense.officeID,
        appliedLicense.licenseID,
      );

      if (statusOK(res) && res.data.result) {
        // License removed
        setState({
          editingLicenseFlag: false,
          isRemoveModalOpen: false,
        });
        getAccountLicenses();
        getAccountOffices();
      } else {
        // License not removed
        setState({
          editingLicenseFlag: false,
        });
      }
    } catch (error) {
      setState({
        editingLicenseFlag: false,
      });
      ErrorHelpers.handleError('Failed to assign license', error);
    }
  };

  /**
   * Add a product license and if successful then we need to fetch
   * the product license again to get the latest for the login.
   */
  const addProductLicense = async (licenseProductCode = 'XLCO') => {
    if (!state.addProductLicenseFlag) {
      setState({ addProductLicenseFlag: true });

      try {
        const newLicense = await XlinkAPI.addProductLicense(uuid4(), licenseProductCode);
        if (newLicense) {
          // Fetch the list of licenses again to get latest updates after
          // adding a product license.
          getAccountLicenses(true);
        }
      } catch (error) {
        ErrorHelpers.handleError('Unable to perform action', error);
      }
    }
    setState({ addProductLicenseFlag: false });
  };

  const setMORows = () => {
    let moSetup = state.moLicensingSetup1040;
    if (state.showBusinessLicenseManagement) {
      moSetup = state.moLicensingSetupBusiness;
    }
    return setupLicenseDataForPagedTable(moSetup);
  };

  const MO_LICENSE_COLUMNS = [
    'Company Name',
    'Licenses Able To Generate',
    'Licenses Available to Generate',
    'Licenses Generated',
    'Licenses Applied',
    ' ',
  ];

  return (
    <div className="container-fixed" style={{ marginTop: '2.5rem' }}>
      <Paper>
        <div style={{ marginLeft: '3vw', marginRight: '3vw', marginTop: '1vh' }}>
          <div
            style={{
              textAlign: 'left',
              paddingTop: '3vh',
              paddingBottom: '1vh',
            }}
          />

          <div className="btn-license-header">
            <Button
              disabled={!state.moLicensingSetup1040}
              onClick={() => {
                setState({
                  showMultiOfficeManagement: !state.showMultiOfficeManagement,
                });
              }}
            >
              {!state.showMultiOfficeManagement
                ? 'Toggle Multi Office License Management'
                : 'Toggle Office License Management'}
            </Button>
            {state.topMostParentRole !== HIERARCHY_TYPE.FRANCHISE &&
            !state.showMultiOfficeManagement ? (
              <div className="manage-licence-create-license-btn-wrapper">
                <Button onClick={() => addProductLicense()} disabled={state.addProductLicenseFlag}>
                  Add a License
                </Button>
                {state.addProductLicenseFlag && (
                  <CircularProgress size={24} className={classes.buttonProgress} />
                )}
              </div>
            ) : payload.season > 2023 ? (
              <div className="manage-licence-create-license-btn-wrapper">
                <Button
                  onClick={() =>
                    setState({
                      showBusinessLicenseManagement: !state.showBusinessLicenseManagement,
                    })
                  }
                  disabled={state.addProductLicenseFlag}
                >
                  {!state.showBusinessLicenseManagement
                    ? 'Manage Business Licenses'
                    : 'Manage 1040 Licenses'}
                </Button>
              </div>
            ) : null}
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Grid container style={styles.parentGridContainer}>
              {!state.showMultiOfficeManagement && (
                <Fragment>
                  <LicenseTable
                    tableName="1040 Office Licenses"
                    licencingSetup={state.licencingSetup1040}
                    getLicenseStatusText={getLicenseStatusText}
                    openLicenseModal={openLicenseModal}
                    licenseType={INDIVIDUAL}
                  />
                  {payload.season > 2023 && (
                    <LicenseTable
                      tableName="Business Office Licenses"
                      licencingSetup={state.licencingSetupBusiness}
                      getLicenseStatusText={getLicenseStatusText}
                      openLicenseModal={openLicenseModal}
                      licenseType={BUSINESS}
                    />
                  )}
                </Fragment>
              )}
              {state.moLicensingSetup1040 && state.showMultiOfficeManagement && (
                <Fragment>
                  <h2 className="setup-section-heading multi-office-manage-license-title">
                    Multi Office Managed Licenses
                    {state.showBusinessLicenseManagement ? ' Business' : ' 1040'}
                  </h2>
                  <PagedTable
                    columns={MO_LICENSE_COLUMNS}
                    rows={setMORows()}
                    actionText="Manage"
                    onActionClick={data => {
                      if (state.showBusinessLicenseManagement) {
                        setState({
                          isManageMultiOfficeLicenseModalOpen: true,
                          selectedMultiOffice: state.moLicensingSetupBusiness[data.id],
                        });
                      } else {
                        setState({
                          isManageMultiOfficeLicenseModalOpen: true,
                          selectedMultiOffice: state.moLicensingSetup1040[data.id],
                        });
                      }
                    }}
                  />
                </Fragment>
              )}
              {/* Bottom Previous and Next */}
              <Grid item xs={12}>
                <Grid container style={styles.footerGridContainer}>
                  <Grid item xs={12} style={styles.footerDivider}>
                    <Divider style={styles.divider} />
                  </Grid>
                  <Grid item xs={10}>
                    {/** Just a spacer to push the buttons right */}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
        <SimpleDialog
          open={state.isRemoveModalOpen}
          onConfirm={removeLicense}
          dialogTitle="Unassign License"
          contentText={getRemoveText(state.licencingSetup1040, state.selectedKey)}
          confirmText="Remove"
          onClose={closeRemoveLicenseModal}
          confirmLoader={state.editingLicenseFlag}
          styled={true}
        />
        <AssignLicenseModal
          isAssignLicenseModalOpen={state.isAssignLicenseModalOpen}
          closeModal={closeAssignOfficeModal}
          licenseType={state.selectedLicenseType}
          assignLicenseToOffice={assignLicenseToOffice}
          closeAssignOfficeModal={closeAssignOfficeModal}
          getLicenseStatusText={getLicenseStatusText}
          offices={state.offices}
          selectedKey={state.selectedKey}
          licencingSetup={{
            licencingSetup1040: state.licencingSetup1040,
            licencingSetupBusiness: state.licencingSetupBusiness,
          }}
        />

        <AddLicenseModal
          season={payload.season}
          isOpen={state.isAddLicenseModalOpen}
          onClose={() => setState({ isAddLicenseModalOpen: false })}
          addProductLicense={addProductLicense}
          drillDownLoginRoleID={props.drillDownHistory[props.drillDownHistory.length - 1].role}
        />
        <Modal
          open={state.isManageMultiOfficeLicenseModalOpen}
          onClose={() => {
            setState({ isManageMultiOfficeLicenseModalOpen: false });
          }}
          disableBackdropClick={true}
        >
          <MultiOfficeManagement
            multiOffice={state.selectedMultiOffice}
            closeModal={() => setState({ isManageMultiOfficeLicenseModalOpen: false })}
            reloadLicensing={getMOManagedLicenses}
            isBusinessManagement={state.showBusinessLicenseManagement}
          />
        </Modal>
      </Paper>
    </div>
  );
};

export default withStyles({ ...styles, ...pagedTableStyles })(ManageLicencing);
