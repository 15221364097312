// External imports
import React, { Fragment, useEffect } from 'react';
import {
  Typography,
  Button,
  withStyles,
  TableRow,
  Table,
  TableHead,
  TableBody,
  TableCell,
  Menu,
  MenuItem,
  Grid,
} from '@material-ui/core';
import ReactPaginate from 'react-paginate';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import WarningAmberIcon from '@material-ui/icons/ReportProblemTwoTone';
import CheckIcon from '@material-ui/icons/Check';
// Internal imports
import { statusOK } from '~/app/webHelpers.js';
import AddDocumentModal from './Modals/AddDocumentModal.jsx';
import PreviewDocumentModal from './Modals/PreviewDocumentModal.jsx';
import RequestPDFModal from './Modals/RequestPDFModal.jsx';
import XlinkAPI from '~/app/api/xlinkAPI';
import InvoiceAPI from '~/app/api/remoteInvoiceAPI.js';
import ErrorHelpers from '~/app/errorHelpers.js';
import { useSetState } from '~/app/Utility/customHooks';
import SimpleDialog from '#/Common/SimpleDialog.jsx';
import {
  COLUMN_HEADERS,
  REMOTE_COLUMN_HEADERS,
  REQUESTEE_TYPE,
  REMOTE_DOC_STATUS_LABELS,
  UNDELETABLE_DOC_TYPES,
} from '~/app/Pages/Returns/components/Document/ArchivedDocuments/archivedDocumentsConstants.js';
import {
  getRemoteDocumentList,
  buildPreviewString,
  convertTimestampToLocal,
} from '~/app/Pages/Returns/components/Document/ArchivedDocuments/archivedDocumentsHelpers';
import AccessControl from '~/app/Components/Auth/AccessControl.jsx';
// Redux imports
import { useSelector, useDispatch } from 'react-redux';
import { actions as appActions } from '~/app/redux/modules/app.js';
import { actions as remoteDocActions } from '~/app/redux/modules/remoteDocRequests.js';
import { actions as formViewerActions } from '~/app/redux/modules/formViewer';
// Styling imports
import { styles } from '~/app/Pages/Returns/components/Document/ArchivedDocuments/css/archivedDocuments.js';
import './css/archivedDocuments.css';
import RequestDocumentsModal from './Modals/RequestDocumentsModal.jsx';
// Image imports
import menuVertical from '~/images/icons/menu_vertical.png';

/**
 * component allows users to "Add New Documents" to be displayed in a table.
 * The table displays rows of documents and their information that the user added.
 * They then can be "Previewed" as a PDF then downloaded
 *
 * @component ArchivedDocuments
 * @category ArchivedDocuments
 **/
const ArchivedDocuments = props => {
  const { classes } = props;
  const dispatch = useDispatch();
  const {
    returnID,
    firmName,
    taxpayerEmailForPdf,
    spouseEmailForPdf,
    clientFilingStatus,
    remoteDocRequestsList,
    isEditingRMD,
    currentRequestStep,
    currentPage,
    totalRequestsCount,
    rmdFormFields,
    documentTypes,
  } = useSelector(state => ({
    returnID: state.returnProfile.returnID,
    firmName: state.officeProfile.firm_name,
    taxpayerEmailForPdf: state.formViewer.taxpayerEmailForPdf,
    spouseEmailForPdf: state.formViewer.spouseEmailForPdf,
    clientFilingStatus: state.returnProfile.clientFilingStatus,
    remoteDocRequestsList: state.remoteDocRequests.remoteDocRequestsList,
    isEditingRMD: state.remoteDocRequests.isEditingRMD,
    currentRequestStep: state.remoteDocRequests.currentRequestStep,
    currentPage: state.remoteDocRequests.currentPage,
    totalRequestsCount: state.remoteDocRequests.totalRequestsCount,
    rmdFormFields: state.remoteDocRequests.rmdFormFields,
    documentTypes: state.formViewer.documentTypes,
  }));
  const [state, setState] = useSetState({
    currentOpenAttachment: null,
    currentOpenAttachmentDesc: null,
    currentOpenAttachmentType: null,
    currentOpenAttachmentDateUp: null,
    hoveredRow: null,
    contextMenuAnchor: null,
    contextMenuSelected: null,
    docID: null,
    isAddDocumentModalOpen: false,
    isRequestPDFModalOpen: false,
    isPreviewModalOpen: false,
    isConfirmDeleteModalOpen: false,
    isDocArchiveConfirmDeleteModalOpen: false,
    selectedHeader: 0,
    getReturnGUID: '',
    walletToken: null,
    secToken: null,
    remoteInvoices: [],
  });

  useEffect(() => {
    props.includeCompletedRS();
    fetchRemoteRequestsCount(returnID);
    fetchRemoteDocuments(returnID, currentPage);
    getWalletToken();

    // populate the selectable document types once.
    if (documentTypes && !documentTypes.length) {
      fetchDocumentTypeList();
    }
  }, []);

  useEffect(() => {
    if (state.walletToken) {
      fetchRemoteInvoices();
    }
  }, [state.walletToken]);

  /** fetches the document types from the document type lookup table */
  // TODO: Update Add Documents modal to use the updated documentType logic instead of hardcoding the list
  const fetchDocumentTypeList = async () => {
    try {
      const res = await XlinkAPI.getDocumentTypeList();
      if (statusOK(res)) {
        dispatch(formViewerActions.setDocumentTypes(res.data));
      }
    } catch (error) {
      dispatch(
        appActions.showSnackbarMessage(error, 'error', 3500, {
          vertical: 'top',
          horizontal: 'center',
        }),
      );
      ErrorHelpers.handleError('Error opening attachment', error);
    }
  };

  const getWalletToken = async () => {
    try {
      const walletData = await props.getWalletToken();
      if (walletData) {
        setState({
          walletToken: walletData.walletToken,
          secToken: walletData.secToken,
        });
      }
    } catch (error) {
      dispatch(
        appActions.showSnackbarMessage(error, 'error', 3500, {
          vertical: 'top',
          horizontal: 'center',
        }),
      );
      ErrorHelpers.handleError('Error fetching Wallet Token', error);
    }
  };

  const fetchRemoteInvoices = async () => {
    try {
      const res = await XlinkAPI.getInvoicesByReturnID(returnID);
      if (statusOK(res, true)) {
        const remoteInvoices = res.data; // array of invoice objects
        if (remoteInvoices?.length > 0) {
          try {
            const fetchedDataList = await Promise.all(
              remoteInvoices.map(invoice => getRemoteInvoiceByInvoiceGuid(invoice?.invoiceGuid)),
            );

            remoteInvoices.forEach((_, index) => {
              remoteInvoices[index] = {
                ...remoteInvoices[index],
                invoiceData: fetchedDataList[index],
              };
            });

            setState({ remoteInvoices: remoteInvoices?.reverse() || [] });
          } catch (err) {
            ErrorHelpers.handleError('Failed to Retrieve Remote Invoice Data: ', err?.message);
          }
        }
      }
    } catch (error) {
      dispatch(
        appActions.showSnackbarMessage(error, 'error', 3500, {
          vertical: 'top',
          horizontal: 'center',
        }),
      );
      ErrorHelpers.handleError('Error fetching invoices', error);
    }
  };

  /**
   * Handles fetching all active remote document requests for a return
   *
   * @param {number} returnID used to get remote doc requests
   * @param {number} currentPagehelps determine the offset amount when querying for the next page
   */
  const fetchRemoteDocuments = async (returnID, currentPage) => {
    try {
      const res = await XlinkAPI.getRemoteDocumentRequestsList(returnID, currentPage);
      if (statusOK(res)) {
        dispatch(remoteDocActions.setRemoteDocumentRequests(res.data));
      }
    } catch (error) {
      dispatch(
        appActions.showSnackbarMessage(error, 'error', 3500, {
          vertical: 'top',
          horizontal: 'center',
        }),
      );
      ErrorHelpers.handleError('Error opening attachment', error);
    }
  };

  /**
   * Handles fetching the total count of active remote doc requests for this return
   * Since we only fetch 15 at a time, the length of `remoteDocRequestsList` is true total amount.
   *
   * @param {number} returnID used to get remote doc requests
   */
  const fetchRemoteRequestsCount = async returnID => {
    try {
      const res = await XlinkAPI.getRemoteRequestsCount(returnID);
      if (statusOK(res)) {
        dispatch(remoteDocActions.setRemoteRequestsCount(res.data));
      }
    } catch (error) {
      dispatch(
        appActions.showSnackbarMessage(error, 'error', 3500, {
          vertical: 'top',
          horizontal: 'center',
        }),
      );
      ErrorHelpers.handleError('Error getting remote requests count', error);
    }
  };

  /**
   * Uses the local state name of the modal to toggle it off and on
   *
   * @param {string} modalName name of the local state for the modal
   */
  const toggleModal = modalName => {
    setState({ [modalName]: !state[modalName] });
  };

  /** Handles opening/closing the add document modal */
  const toggleAddDocumentModal = () => {
    setState({ isAddDocumentModalOpen: !state.isAddDocumentModalOpen });
  };

  /**
   * used to fetch Attachment from api, and also opens Preview Document modal
   *
   * @param {string} docid used to fetch attachment from api
   * @param {string} name attachments name
   * @param {string} desc attachments description
   * @param {string} type attachments type
   * @param {string} dateCreate attachments created date
   */
  const openAttachment = async (docid, name, desc, type, dateCreat) => {
    try {
      const response = await XlinkAPI.getReturnAttachmentByRIDDocId(123, docid);
      if (response) {
        setState(
          {
            currentOpenAttachment: response.data,
            currentOpenAttachmentTitle: name,
            currentOpenAttachmentDesc: desc,
            currentOpenAttachmentType: type,
            currentOpenAttachmentDateUp: dateCreat,
          },
          toggleModal('isPreviewModalOpen'),
        );
      }
    } catch (error) {
      ErrorHelpers.handleError('Error opening attachment', error);
    }
  };

  /**
   * In table, if user hovers over a Row(document) ->
   * It will highlight row, and display a button on the right side to open up a menus
   *
   * @param {number} index The index for determining which Row in the table is being hovered
   */
  const updateRowHoverEnter = index => {
    setState({ hoveredRow: index });
  };

  /**
   * In table, if user is hovering over a Row(document) and LEAVES ->
   * It will make the current rows menu button to diappear and change background color
   */
  const updateRowHoverLeave = () => {
    setState({ hoveredRow: null });
  };

  /**
   * When the document row is being hovered, the menu button is clickable and a Context Menu drops down.
   * The params help determing which row was clicked aswell as which button.
   *
   * @param {Object} event The event object is used to determine where the user clicked
   * @param {string} selected The selected for which row and btn was selected
   */
  const handleContextMenuToggle = (event, selected) => {
    setState({
      contextMenuAnchor: event.currentTarget,
      contextMenuSelected: selected,
    });
  };

  /**
   * When the user does not select from the dropdown menu, set state back to null and close menu
   */
  const handleContextMenuClose = () => {
    setState({
      contextMenuAnchor: null,
      contextMenuSelected: null,
    });
  };

  /**
   * Retrieves documents from parent comp, and parses through it to create a Sorted array from the documents.
   * This is then used in getArchiveTable that renders the Table jsx to generate the rows
   *
   * @returns {array} Array of sorted documents
   */
  const getDocumentList = () => {
    const { attachments, newestAttachments } = { ...props };
    let docs = [];

    if (
      attachments &&
      newestAttachments &&
      props.newestAttachments.length < props.attachments.length
    ) {
      docs = attachments.sort((a, b) => parseFloat(b.document_id) - parseFloat(a.document_id));
    } else {
      docs = newestAttachments.sort(
        (a, b) => parseFloat(b.document_id) - parseFloat(a.document_id),
      );
    }

    // Binary attachments have an attachment ID,filter out these copies
    // Documents are seen as seperate entities for each form, do not delete these or attachments won't function
    docs = docs.filter(attachment => {
      return !attachment?.document_attachment_id;
    });
    return docs;
  };

  const getRemoteInvoiceByInvoiceGuid = async invoiceGUID => {
    try {
      const invoiceData = await InvoiceAPI.getRemoteInvoice(
        invoiceGUID,
        state.walletToken,
        state.secToken,
      );
      return invoiceData?.data;
    } catch (err) {
      ErrorHelpers.handleError('Failed to Retrieve Remote Invoice Data: ', err);
    }
  };

  const openRemoteInvoiceModal = invoiceData => {
    dispatch(formViewerActions.toggleRemoteInvoiceModal(true));
    dispatch(
      formViewerActions.setRemoteInvoice({
        ...invoiceData,
      }),
    );
  };

  /**
   * Sends a remote signature request via email or text message to the preparer.
   *
   * @param {object} requestFormData form data from the Request Documents modal
   */
  const sendDocumentRequest = async requestFormData => {
    // USE isEditingRMD for logic if the properties change during eidting
    const preppedData = {
      returnID: parseInt(returnID),
      requesteeType: parseInt(requestFormData.requesteeType), // this needs to come from state to determine if its spouse or taxpayer
      email: requestFormData.email,
      subject: requestFormData.subject,
      description: requestFormData.description,
      documentTypeID: requestFormData.documentTypeID,
      remoteDocumentID: requestFormData.remoteDocumentID || 0, // 0 means that it is a new remote document request
      firmName: firmName,
    };

    try {
      // Save the authentication data to the remote docs table for this return
      const res = await XlinkAPI.postRemoteDocumentAuthenticationData(
        preppedData.returnID,
        preppedData.requesteeType,
        preppedData.email,
        preppedData.subject,
        preppedData.description,
        preppedData.documentTypeID,
        preppedData.remoteDocumentID,
        preppedData.firmName,
      );
      if (statusOK(res, false)) {
        fetchRemoteDocuments(returnID, currentPage); // fetch updated documents
        dispatch(remoteDocActions.setCurrentRequestStep(1)); // proceed to next step
        fetchRemoteRequestsCount(returnID);
      }
    } catch (err) {
      dispatch(
        appActions.showSnackbarMessage(err, 'error', 3500, {
          vertical: 'top',
          horizontal: 'center',
        }),
      );
      ErrorHelpers.handleError('Remote Documents Request Error: ', err);
    }
  };

  /**
   * Displays table for archived documents
   *
   * @returns {jsx} JSX to render a table
   */
  const getArchiveTable = () => {
    return (
      <Table onMouseLeave={updateRowHoverLeave}>
        <TableHead>
          <TableRow>
            {COLUMN_HEADERS.map(column => {
              return (
                <TableCell
                  key={`tableCell${column}`}
                  onMouseEnter={updateRowHoverLeave}
                  classes={{ root: classes.cellHeader }}
                >
                  {column}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {getDocumentList().map((row, i) => {
            return (
              <Fragment key={'archiveFrag-' + i}>
                <TableRow
                  key={'archiveRow-' + row.document_id.toString()}
                  hover
                  onMouseEnter={() => updateRowHoverEnter(row.document_id)}
                >
                  <TableCell
                    key={'archiveName-' + row.document_id.toString()}
                    variant="body"
                    classes={{ root: classes.cellRoot }}
                    component="th"
                    scope="row"
                  >
                    {row.document_type === 'Full Tax Return' ||
                    row.document_type === 'Full Tax Return/Bank Docs'
                      ? 'FINAL TAX RETURN'
                      : row.document_name}
                  </TableCell>
                  <TableCell
                    key={'archiveDescription-' + row.document_id.toString()}
                    variant="body"
                    classes={{ root: classes.cellRoot }}
                    component="th"
                    scope="row"
                  >
                    {buildPreviewString(row.document_description)}
                  </TableCell>
                  <TableCell
                    key={'archiveType-' + row.document_id.toString()}
                    variant="body"
                    classes={{ root: classes.cellRoot }}
                    component="th"
                    scope="row"
                  >
                    {row.document_type}
                  </TableCell>
                  <TableCell
                    key={'archiveDate-' + row.document_id.toString()}
                    variant="body"
                    classes={{ root: classes.cellRoot }}
                    component="th"
                    scope="row"
                  >
                    {convertTimestampToLocal(row.create_date)}
                  </TableCell>
                  <TableCell
                    key={'archiveAction-' + row.document_id.toString()}
                    variant="body"
                    classes={{ root: classes.cellRoot }}
                    component="th"
                    scope="row"
                  >
                    <div style={{ display: 'inline-flex', width: '70px' }}>
                      <Button
                        size="small"
                        id={`archiveRowPreviewButton-${i}`}
                        classes={{ root: classes.previewButton }}
                        onClick={() => {
                          openAttachment(
                            row.document_id,
                            row.document_name,
                            row.document_description,
                            row.document_type,
                            row.create_date.substring(0, 10),
                          );
                        }}
                      >
                        Preview
                      </Button>
                      <div
                        hidden={Boolean(state.hoveredRow !== row.document_id)}
                        style={{
                          textAlign: 'left',
                          marginLeft: '10px',
                          alignSelf: 'center',
                        }}
                      >
                        <Button
                          className="contextMenuButton"
                          aria-haspopup="true"
                          aria-owns={open ? 'button-menu' + row.document_id : null}
                          onClick={e => {
                            if (!props.locked) {
                              handleContextMenuToggle(e, 'button-menu' + row.document_id);
                            }
                          }}
                          style={{
                            minHeight: '1.5rem',
                            maxHeight: '1.5rem',
                            minWidth: '0.5rem',
                            maxWidth: '0.5rem',
                            padding: '0px 8px 0px 8px',
                          }}
                        >
                          <img src={menuVertical} style={{ height: `1em` }} />
                        </Button>
                        <Menu
                          id={'button-menu' + row.document_id}
                          anchorEl={state.contextMenuAnchor}
                          open={Boolean(
                            state.contextMenuSelected === 'button-menu' + row.document_id,
                          )}
                          onClose={handleContextMenuClose}
                        >
                          <AccessControl
                            requiredAction="write"
                            accessLevel="delete_document_from_archive"
                            disableOnFalse={true}
                          >
                            <MenuItem
                              disabled={UNDELETABLE_DOC_TYPES.includes(row.document_type)}
                              onClick={e => {
                                handleContextMenuClose();
                                setState({ docID: row.document_id });
                                toggleModal('isDocArchiveConfirmDeleteModalOpen');
                              }}
                            >
                              Delete
                            </MenuItem>
                          </AccessControl>
                          <MenuItem
                            onClick={() => {
                              handleContextMenuClose();
                              openAttachment(
                                row.document_id,
                                row.document_name,
                                row.document_description,
                                row.document_type,
                                row.create_date.substring(0, 10),
                              );
                            }}
                          >
                            Download
                          </MenuItem>
                        </Menu>
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              </Fragment>
            );
          })}
        </TableBody>
      </Table>
    );
  };

  const getInvoiceTable = () => {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell classes={{ root: classes.cellHeader }}>INVOICE </TableCell>
            <TableCell classes={{ root: classes.cellHeader }}>STATUS </TableCell>
            <TableCell classes={{ root: classes.cellHeader }} />
            <TableCell classes={{ root: classes.cellHeader }}>TIME </TableCell>
            <TableCell classes={{ root: classes.cellHeader }}>ACTION</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {state.remoteInvoices ? (
            state.remoteInvoices.map((row, i) => {
              return (
                <Fragment key={'remoteFrag-' + i}>
                  <TableRow key={'remoteRow-' + i}>
                    <TableCell
                      variant="body"
                      classes={{ root: classes.cellRoot }}
                      component="th"
                      scope="row"
                    >
                      Remote Invoice
                    </TableCell>
                    <TableCell
                      variant="body"
                      classes={{ root: classes.cellRoot }}
                      component="th"
                      scope="row"
                    >
                      {row.invoiceData?.invoiceStatus
                        ? row.invoiceData?.invoiceStatus
                        : 'Cancelled'}
                    </TableCell>
                    <TableCell
                      variant="body"
                      classes={{ root: classes.cellRoot }}
                      component="th"
                      scope="row"
                    ></TableCell>
                    <TableCell
                      variant="body"
                      classes={{ root: classes.cellRoot }}
                      component="th"
                      scope="row"
                    >
                      {convertTimestampToLocal(row.createDate)}
                    </TableCell>
                    <TableCell
                      align="right"
                      variant="body"
                      classes={{ root: classes.cellRoot }}
                      component="th"
                      scope="row"
                    >
                      <div style={{ display: 'inline-flex', width: '70px' }}>
                        <Button
                          size="small"
                          id={`archiveRowPreviewButton-${i}`}
                          classes={{ root: classes.previewButton }}
                          onClick={() => openRemoteInvoiceModal(row.invoiceData)}
                        >
                          View
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                </Fragment>
              );
            })
          ) : (
            <></>
          )}
        </TableBody>
      </Table>
    );
  };

  // Handles retrieving the selected remote document id and sending the request to delete the remote document request
  const deleteRemoteDocRequest = async remoteDocumentID => {
    try {
      props.toggleRequestDocsModal();
      dispatch(remoteDocActions.setIsEditingRMD(false));
      dispatch(remoteDocActions.resetRMDFormFields());

      // delete the selected document
      await XlinkAPI.deleteRemoteDocumentRequest(returnID, remoteDocumentID);

      dispatch(remoteDocActions.deleteRMDRequest(remoteDocumentID));

      fetchRemoteRequestsCount(returnID);
      dispatch(
        appActions.showSnackbarMessage('Document has been deleted.', 'success', 3500, {
          vertical: 'top',
          horizontal: 'center',
        }),
      );
    } catch (err) {
      dispatch(
        appActions.showSnackbarMessage(err, 'error', 3500, {
          vertical: 'top',
          horizontal: 'center',
        }),
      );
      ErrorHelpers.handleError('Delete Remote Document Error: ', err);
    }
  };

  /**
   * Handles pagination change for remote document requests.
   *
   * @param {number} page
   */
  const handlePageChange = page => {
    dispatch(remoteDocActions.setCurrentPage(page.selected + 1));
    fetchRemoteDocuments(returnID, page.selected + 1);
  };

  /**
   * Handles building the status ui using the document status
   *
   * @param {number} status document status used to determine ui
   * @returns
   */
  const buildStatusUI = status => {
    const label = REMOTE_DOC_STATUS_LABELS[status];
    const icon =
      status === 0 ? (
        <ErrorOutlineIcon className={classes.statusIconStyle} />
      ) : status === 1 ? (
        <WarningAmberIcon className={classes.statusIconStyle} />
      ) : (
        <CheckIcon className={classes.statusIconStyle} />
      );

    return (
      <div className={`doc-archive-rmd-list-status-icon remote-doc-status ${label.toLowerCase()}`}>
        {icon}
        {label}
      </div>
    );
  };

  // Handles building the UI for the remote document requests Table
  const getRemoteDocumentsTable = () => {
    const totalPages = Math.ceil(totalRequestsCount / 15); // the backend has limit of 15 hardcoded

    return (
      <>
        <Table onMouseLeave={updateRowHoverLeave}>
          <TableHead>
            <TableRow>
              {REMOTE_COLUMN_HEADERS.map(column => {
                return (
                  <TableCell
                    key={`tableCell${column}`}
                    onMouseEnter={updateRowHoverLeave}
                    classes={{ root: classes.cellHeader }}
                  >
                    {column}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {remoteDocRequestsList?.length > 0 &&
              getRemoteDocumentList(remoteDocRequestsList).map((row, i) => {
                // if document status is already in complete state, then do not display the expired icon.
                const determineStatus =
                  row.is_expired === true && row.document_status !== 2 ? 0 : row.document_status;

                // documentTypes has the current doc types lookup id references
                const documentTypeName = documentTypes.find(
                  type => row.document_type_id === type.document_type_id,
                )?.document_type;

                return (
                  <TableRow
                    key={`remoteDocRow-${row.remote_document_id}`}
                    hover
                    onMouseEnter={() => updateRowHoverEnter(row.remote_document_id)}
                  >
                    <TableCell
                      key={`personRequestingType-${i}`}
                      variant="body"
                      classes={{ root: classes.cellRoot }}
                      component="th"
                      scope="row"
                    >
                      {REQUESTEE_TYPE[row.requestee_type]}
                    </TableCell>
                    <TableCell
                      key={`subject-${i}`}
                      variant="body"
                      classes={{ root: classes.cellRoot }}
                      component="th"
                      scope="row"
                    >
                      {buildPreviewString(row.subject)}
                    </TableCell>
                    <TableCell
                      key={`documentType-${i}`}
                      variant="body"
                      classes={{ root: classes.cellRoot }}
                      component="th"
                      scope="row"
                    >
                      {documentTypeName}
                    </TableCell>
                    <TableCell
                      key={`updateDate-${i}`}
                      variant="body"
                      classes={{ root: classes.cellRoot }}
                      component="th"
                      scope="row"
                    >
                      {convertTimestampToLocal(row.update_date)}
                    </TableCell>
                    <TableCell
                      key={`documentStatus-${i}`}
                      variant="body"
                      classes={{ root: classes.cellRoot }}
                      component="th"
                      scope="row"
                    >
                      {buildStatusUI(determineStatus)}
                    </TableCell>
                    <TableCell
                      key={`remoteDocAction-${i}`}
                      variant="body"
                      classes={{ root: classes.cellRoot }}
                      component="th"
                      scope="row"
                    >
                      <div style={{ display: 'inline-flex', width: '70px' }}>
                        <Button
                          size="small"
                          id={`archiveRowPreviewButton-${i}`}
                          classes={{ root: classes.previewButton }}
                          onClick={() => {
                            props.toggleRequestDocsModal();
                            dispatch(remoteDocActions.setSelectedRemoteDoc(row));
                            dispatch(remoteDocActions.setIsEditingRMD(true));
                          }}
                          disabled={props.locked}
                        >
                          Edit
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>

        <ReactPaginate
          onPageChange={page => handlePageChange(page)}
          pageCount={totalPages}
          initialPage={currentPage - 1}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          forcePage={currentPage - 1}
          previousLabel={'<'}
          nextLabel={'>'}
          breakLabel={'...'}
          breakClassName={'paginate-break'}
          pageClassName={totalPages === 0 ? 'paginate-hide' : 'paginate-page'}
          activeClassName={totalPages <= 1 ? 'paginate-hide' : 'paginate-activepage'}
          previousClassName={totalPages <= 1 ? 'paginate-hide' : 'paginate-prev'}
          nextClassName={totalPages <= 1 ? 'paginate-hide' : 'paginate-next'}
          disabledClassName={'paginate-disabled'}
          containerClassName={'pagination'}
          subContainerClassName={'pages pagination'}
        />
      </>
    );
  };

  return (
    <div id="taxform" className={classes.archiveDocContainer}>
      <div className={classes.archiveHeader}>
        <Grid container spacing={8}>
          <Grid container justify="flex-start">
            <Typography
              onClick={() => setState({ selectedHeader: 0 })}
              className={`${classes.archiverHeaderTitle} ${
                state.selectedHeader === 0
                  ? classes.selectedHeaderStyle
                  : classes.unselectedHeaderStyle
              }`}
              variant="title"
            >
              Archived Documents
            </Typography>
            <Typography variant="title" className={classes.archiveHeaderVertDivider}></Typography>
            <Typography
              onClick={() => setState({ selectedHeader: 1 })}
              className={`${classes.archiverHeaderTitle} ${
                state.selectedHeader === 1
                  ? classes.selectedHeaderStyle
                  : classes.unselectedHeaderStyle
              }`}
              variant="title"
            >
              Requested Documents
            </Typography>
            <Typography variant="title" className={classes.archiveHeaderVertDivider}></Typography>
            <Typography
              onClick={() => setState({ selectedHeader: 2 })}
              className={`${classes.archiverHeaderTitle} ${
                state.selectedHeader === 2
                  ? classes.selectedHeaderStyle
                  : classes.unselectedHeaderStyle
              }`}
              variant="title"
            >
              Remote Invoices
            </Typography>
          </Grid>
          <Grid container justify="flex-end">
            {state.selectedHeader === 0 ? (
              <Button
                id="btnOpenAttachModalDocArchive"
                className={classes.addDocButton}
                size="small"
                color="primary"
                onClick={() => {
                  if (!props.locked) {
                    toggleAddDocumentModal();
                  }
                }}
                disabled={props.locked}
              >
                Add New Documents
              </Button>
            ) : null}
            {state.selectedHeader === 1 ? (
              <Button
                id="btnOpenRequestModalDocArchive"
                className={classes.addDocButton}
                size="small"
                color="primary"
                onClick={() => {
                  props.saveReturn();
                  props.toggleRequestDocsModal();
                }}
                disabled={props.locked}
              >
                Request Documents
              </Button>
            ) : null}
          </Grid>
        </Grid>
      </div>
      <div>
        {props.attachments != null && state.selectedHeader === 1
          ? getRemoteDocumentsTable()
          : state.selectedHeader === 0
          ? getArchiveTable()
          : getInvoiceTable()}
      </div>
      <hr className="doc-archive-table-div-hr" />
      {state.isAddDocumentModalOpen && (
        <AddDocumentModal
          handleCloseDocumentModalOk={props.closeAddDocumentModalOk}
          showSnackBarErr={props.showSnackBarErr}
          isAddDocumentModalOpen={state.isAddDocumentModalOpen}
          toggleAddDocumentModal={toggleAddDocumentModal}
        />
      )}
      {props.isRequestDocumentModalOpen && (
        <RequestDocumentsModal
          toggleModal={toggleModal}
          showSnackBarErr={props.showSnackBarErr}
          isRequestDocumentModalOpen={props.isRequestDocumentModalOpen}
          clientFilingStatus={clientFilingStatus}
          toggleRequestDocsModal={props.toggleRequestDocsModal}
          sendDocumentRequest={sendDocumentRequest}
          returnID={returnID}
          isBusiness={props.isBusiness}
          taxpayerEmail={taxpayerEmailForPdf}
          spouseEmail={spouseEmailForPdf}
          rmdFormFields={rmdFormFields}
          isEditingRMD={isEditingRMD}
          currentRequestStep={currentRequestStep}
          documentTypes={documentTypes}
        />
      )}
      {state.isPreviewModalOpen && (
        <PreviewDocumentModal
          toggleModal={toggleModal}
          isPreviewModalOpen={state.isPreviewModalOpen}
          currentOpenAttachment={state.currentOpenAttachment}
          currentOpenAttachmentDesc={state.currentOpenAttachmentDesc}
          currentOpenAttachmentTitle={state.currentOpenAttachmentTitle}
          currentOpenAttachmentDateUp={state.currentOpenAttachmentDateUp}
          currentOpenAttachmentType={state.currentOpenAttachmentType}
        />
      )}
      {state.isRequestPDFModalOpen && (
        <RequestPDFModal
          toggleModal={toggleModal}
          isRequestPDFModalOpen={state.isRequestPDFModalOpen}
          currentOpenAttachment={state.currentOpenAttachment}
          currentOpenAttachmentType={state.currentOpenAttachmentType}
          currentOpenAttachmentDesc={state.currentOpenAttachmentDesc}
        />
      )}
      {state.isConfirmDeleteModalOpen && (
        <SimpleDialog
          open={state.isConfirmDeleteModalOpen}
          onClose={() => toggleModal('isConfirmDeleteModalOpen')}
          onConfirm={() => {
            toggleModal('isConfirmDeleteModalOpen');
            deleteRemoteDocRequest(rmdFormFields.remoteDocumentID);
          }}
          dialogTitle="WARNING"
          contentText="Are you sure you want to Delete this remote document request?"
          confirmText="Delete"
          cancelText="Cancel"
        />
      )}
      {state.isDocArchiveConfirmDeleteModalOpen && (
        <SimpleDialog
          open={state.isDocArchiveConfirmDeleteModalOpen}
          onClose={() => toggleModal('isDocArchiveConfirmDeleteModalOpen')}
          onConfirm={() => {
            toggleModal('isDocArchiveConfirmDeleteModalOpen');
            props.removeDocument(state.docID);
          }}
          dialogTitle="WARNING"
          contentText="By confirming you are deleting this document and it will not be recoverable."
          confirmText="Delete"
          cancelText="Cancel"
        />
      )}
    </div>
  );
};

export default withStyles(styles)(ArchivedDocuments);
