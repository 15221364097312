// External imports
import React, { useEffect, Fragment } from 'react';
import { Paper, Grid, Divider, Typography, LinearProgress, withStyles } from '@material-ui/core';
// Internal imports
import { PAYJUNCTION_TERMINAL_STATUSES } from '~/app/constants.js';
import XlinkAPI from '~/app/api/xlinkAPI.js';
import ErrorHelpers from '~/app/errorHelpers.js';
import ProgressButtons from './ProgressButtons.jsx';
// Styling imports
import { styles } from '~/app/Pages/Payments/PaymentModal/css/CapturePaymentStyles.js';
// Image imports
import checkmark from '~/images/icons/checkmark_complete_green.png';
import erroricon from '~/images/icons/erroricon.png';

const PayJunctionPaymentProgress = props => {
  const [progress, setProgress] = React.useState(10);
  const [message, setMessage] = React.useState('Please wait, connecting to terminal...');
  const [transactionID, setTransactionID] = React.useState(null);

  const processPaymentDetails = transactionDetails => {
    const createDate = new Date(transactionDetails.created);
    let month = createDate.getMonth() + 1;
    let day = createDate.getDate();
    const year = createDate.getFullYear();
    if (month.toString().length === 1) {
      month = '0' + month.toString();
    }
    if (day.toString().length === 1) {
      day = '0' + day.toString();
    }
    const formattedDate = month + '/' + day + '/' + year;

    const receievedFrom = transactionDetails.billing.companyName
      ? transactionDetails.billing.companyName
      : transactionDetails.billing.firstName + ' ' + transactionDetails.billing.lastName;

    let memo;
    let paymentType;

    if (props.isACHPayment) {
      paymentType = 'CHECK';
      memo = 'Captured via PayJunction ACH Payment';
    } else {
      paymentType = 'CREDIT CARD';
      memo = 'Captured via PayJunction Smart Terminal';
    }

    setTransactionID(transactionDetails.transactionId);

    props.processPaymentDetails(
      formattedDate,
      receievedFrom,
      paymentType,
      transactionDetails.amountTotal,
      transactionDetails.transactionId.toString(),
      memo,
      props.returnPaymentInfo.paymentGUID,
    );

    // smart terminal payment, mark as complete in db
    if (props.requestPaymentID) {
      completeSmartTerminalPayment(props.requestPaymentID, transactionDetails.transactionId);
    }
  };

  // handleProgressUpdate sets the progress bar percentage and message to display in message()
  const handleProgressUpdate = terminalStatus => {
    // Default to connecting message, terminal very often connects before the "CONNECTING" status has a chance to be returned
    if (props.isACHPayment) {
      setProgress(100);
      setMessage('Transaction Complete');
      processPaymentDetails(props.achTransactionDetails);
      return;
    } else if (!terminalStatus) {
      setProgress(10);
      setMessage('Please wait, connecting to terminal...');
      return;
    }
    switch (terminalStatus.status) {
      case PAYJUNCTION_TERMINAL_STATUSES.NO_CONNECTION:
        setProgress(20);
        setMessage(
          'Unable to connect to terminal. Please ensure the selected terminal is connected and running, and try again.',
        );
        break;
      case PAYJUNCTION_TERMINAL_STATUSES.BUSY:
        setProgress(20);
        setMessage('Terminal is busy. Please ensure the terminal is idle, and try again.');
        break;
      case PAYJUNCTION_TERMINAL_STATUSES.CONNECTING:
        setProgress(20);
        setMessage('Please wait, connecting to terminal...');
        break;
      case PAYJUNCTION_TERMINAL_STATUSES.IN_PROGRESS:
        setProgress(50);
        setMessage('Please wait, payment is in progress...');
        break;
      case PAYJUNCTION_TERMINAL_STATUSES.COMPLETE:
        setProgress(100);
        if (
          terminalStatus.transactionId !== 0 &&
          terminalStatus.transactionDetails !== undefined &&
          terminalStatus.transactionDetails.status !== PAYJUNCTION_TERMINAL_STATUSES.DECLINED
        ) {
          setMessage('Transaction Complete');
          // Complete recieved along with valid transaction details, save payment to return
          processPaymentDetails(terminalStatus.transactionDetails);
        } else if (
          terminalStatus.transactionDetails !== undefined &&
          terminalStatus.transactionDetails.status === PAYJUNCTION_TERMINAL_STATUSES.DECLINED
        ) {
          setMessage('Transaction Declined');
        } else {
          setMessage('Transaction Failed');
        }
        break;
      default:
        setProgress(20);
        setMessage('Please wait, connecting to terminal...');
        break;
    }
  };

  useEffect(() => {
    if (!props.isACHPayment && props.requestPaymentID) {
      props.pollTerminalStatus(props.requestPaymentID);
    }
  }, [props.requestPaymentID]);

  useEffect(() => {
    if (progress !== 100) {
      handleProgressUpdate(props.terminalStatus);
    }
  }, [props.terminalStatus, props.isACHPayment]);

  // handleTryAgain sets the modal view back to the capture view
  const handleTryAgain = () => {
    props.setTerminalStatus(null);
    props.setPaymentStage(1);
  };

  const handleViewReceipt = async () => {
    try {
      const response = await XlinkAPI.getTransactionReceipt(transactionID);
      if (response) {
        // getTransactionReceipt returns a raw html string of the transaction receipt,
        const printWindow = window.open();
        printWindow.document.write(response.data);
        printWindow.document.title = 'Transaction Receipt';
      }
    } catch (err) {
      ErrorHelpers.handleError(
        'Unable to fetch receipt',
        ErrorHelpers.createSimpleError(
          'If this problem persists, please contact technical support',
        ),
      );
    }
  };

  const completeSmartTerminalPayment = async (requestPaymentID, transID) => {
    try {
      await XlinkAPI.completeSmartTerminalPayment(requestPaymentID, transID);
    } catch (err) {
      ErrorHelpers.handleError(
        'Unknown error',
        ErrorHelpers.createSimpleError(
          'If this problem persists, please contact technical support',
        ),
      );
    }
  };

  // message returns the message and/or details of the completed or failed transaction
  const processMessage = () => {
    const terminalStatus = props.terminalStatus;
    const transactionDetails = props.isACHPayment
      ? props.achTransactionDetails
      : props.terminalStatus?.transactionDetails;
    let messageIcon = '';
    let details = <Fragment />;
    if (
      (terminalStatus && terminalStatus?.status === PAYJUNCTION_TERMINAL_STATUSES.COMPLETE) ||
      (props.isACHPayment && progress === 100)
    ) {
      if (
        (terminalStatus?.transactionId !== 0 &&
          transactionDetails !== null &&
          terminalStatus?.transactionDetails?.status !== PAYJUNCTION_TERMINAL_STATUSES.DECLINED) ||
        props.isACHPayment
      ) {
        const createDate = new Date(transactionDetails.created);

        messageIcon = <img src={checkmark} />;
        details = (
          <Fragment>
            <Grid item xs={6}>
              <Typography variant="body1" gutterBottom>
                ID: {transactionDetails.transactionId}{' '}
              </Typography>
              <Typography variant="body1" gutterBottom>
                Recieved From:{' '}
                {transactionDetails.billing.firstName + ' ' + transactionDetails.billing.lastName}
              </Typography>
            </Grid>
            <Grid item xs={6} classes={{ item: classes.payJunctionPaymentProgressEndGrid }}>
              <Typography variant="body1" gutterBottom>
                {createDate.toLocaleDateString() + ' ' + createDate.toLocaleTimeString()}
              </Typography>
              <Typography variant="title" gutterBottom>
                ${transactionDetails.amountTotal}
              </Typography>
              <Typography variant="body1" gutterBottom>
                {transactionDetails.vault.accountType + ' '} ****-
                {transactionDetails.vault.lastFour}
              </Typography>
            </Grid>
          </Fragment>
        );
      } else {
        messageIcon = <img src={erroricon} />;
      }
    }
    return (
      <Fragment>
        <Grid item xs={12}>
          <Typography variant="title" gutterBottom={true}>
            {' '}
            {messageIcon}
            {message}
          </Typography>
        </Grid>
        {details}
      </Fragment>
    );
  };

  // details returns the view rendered below the progress bar
  const details = () => {
    return (
      <Paper elevation={0} classes={{ root: classes.capturePaymentModalContent }}>
        <Grid
          container
          spacing={8}
          alignContent="flex-start"
          justify="flex-start"
          alignItems="baseline"
        >
          {processMessage()}
          <ProgressButtons
            selectedSmartTerminal={props.selectedSmartTerminal}
            terminalStatus={props.terminalStatus}
            onClose={props.onClose}
            isACHPayment={props.isACHPayment}
            handleTryAgain={() => {
              handleTryAgain();
            }}
            handleViewReceipt={() => {
              handleViewReceipt();
            }}
          />
        </Grid>
      </Paper>
    );
  };

  const { classes } = props;
  return (
    <Paper elevation={0} classes={{ root: classes.capturePaymentModalContent }}>
      {/* Progress Bar + Labels */}
      <Grid
        container
        spacing={8}
        alignContent="flex-start"
        justify="flex-start"
        alignItems="baseline"
      >
        <Grid item xs={4}>
          <Typography variant="body1" gutterBottom>
            Choose Payment
          </Typography>
        </Grid>
        <Grid item xs={4} classes={{ item: classes.capturePaymentContentGridItem }}>
          <Typography variant="body1" gutterBottom>
            Processing
          </Typography>
        </Grid>
        <Grid item xs={4} classes={{ item: classes.payJunctionPaymentProgressEndGrid }}>
          <Typography variant="body1" gutterBottom>
            Status
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <LinearProgress variant="determinate" value={progress} />
        </Grid>
      </Grid>
      <Divider variant="middle" classes={{ root: classes.capturePaymentModalDivider }} />
      {details()}
    </Paper>
  );
};

export default withStyles(styles)(PayJunctionPaymentProgress);
