/* eslint-disable no-case-declarations */
import * as Common from '~/app/redux/commonActions.js';

export const SET_TAB = 'xlinkonline/setupPages/SETTAB';

const TOGGLE_SETUP_ACCOUNT = 'xlinkonline/app/TOGGLESETUPACCOUNT';
export const DB_PAGE_CHANGE = 'xlinkonline/setupPages/DBPAGECHANGE';
export const CLEAR_ADDRESS_DATA = 'xlinkonline/setupPages/CLEARADDRESSDATA';

export const FETCH_OFFICE_INFO = 'xlinkonline/setupPages/FETCHOFFICEINFO';
export const ON_SELECT_SIDEBAR = 'xlinkonline/setupPages/ONSELECTSIDEBAR';
export const ON_DB_SELECT_SIDEBAR = 'xlinkonline/setupPages/ONDBSELECTSIDEBAR';
export const SET_EXPANDED_HEADERS = 'xlinkonline/setupPages/SETEXPANDEDHEADERS';
export const UPDATE_DB_TYPE = 'xlinkonline/setupPages/UPDATEDBTYPE';
export const SET_DB_TABLE_LABELS = 'xlinkonline/setupPages/SETDBTABLELABELS';
export const UPDATE_CURRENT_EDIT_RECORD = 'xlinkonline/setupPages/UPDATECURRENTEDITRECORD';
export const UPDATE_STATE_CODE = 'xlinkonline/setupPages/UPDATESTATECODE';
export const SET_HIDDEN_FOREIGN_IND = 'xlinkonline/setupPages/SETHIDDENFOREIGNIND';

export const ON_OPEN_USER_DB_ADD_MODAL = 'xlinkonline/setupPages/ONOPENUSERDBADDMODAL';
export const ON_CLOSE_USER_DB_MODAL = 'xlinkonline/setupPages/ONCLOSEUSERDBMODAL';

export const FETCH_USER_DB_LIST = 'xlinkonline/setupPages/FETCHUSERDBLIST';
export const FETCH_USER_DB_LIST_SUCCESS = 'xlinkonline/setupPages/FETCHUSERDBLISTSUCCESS';
export const FETCH_USER_DB_LIST_FAILURE = 'xlinkonline/setupPages/FETCHUSERDBLISTFAILED';

export const REQUEST_UPSERT_USER_DB_RECORD = 'xlinkonline/setupPages/REQUESTUPSERTUSERDB';
export const UPSERT_USER_DB_RECORD_SUCCESS = 'xlinkonline/setupPages/UPSERTUSERDRECORDBSUCCESS';
export const UPSERT_USER_DB_RECORD_FAILURE = 'xlinkonline/setupPages/UPSERTUSERDBRECORDFAILURE';

export const REQUEST_DELETE_USER_DB_RECORD = 'xlinkonline/setupPages/REQUESTDELETEUSERDB';
export const DELETE_USER_DB_RECORD_SUCCESS = 'xlinkonline/setupPages/DELETEUSERDBECORDSUCCESS';
export const DELETE_USER_DB_RECORD_FAILURE = 'xlinkonline/setupPages/DELETEUSERDBECORDFAILURE';

export const UPDATE_OFFICE_SETUP_FLAG = 'xlinkonline/setupPages/UPDATEOFFICESETUPFLAG';
export const FETCH_OFFICE_SETUP_PROGESSS_FLAGS =
  'xlinkonline/setupPages/FETCHOFFICESETUPPROGESSSFLAGS';
export const SET_OFFICE_SETUP_FLAGS = 'xlinkonline/setupPages/SETOFFICESETUPFLAGS';
export const SET_OFFICE_SETUP_FLAGS_FAILURE = 'xlinkonline/setupPages/SETOFFICESETUPFLAGSFAILURE';

export const initialState = {
  listOpen: true,
  extendedSetuplistOpen: true,
  selectedSidebarItemExtended: 0,
  expandedHeaders: [],
  selectedSidebarItem: 0,

  officeSetup: {},
  eroSetup: {},
  billingSetup: {},
  mylogins: [],

  selectedTab: 1,
  settingsShortcut: 0,
  officeSetupProgressFlags: {},

  setupAccount: false,
 
  dbSelectedSidebarItem: 0,
  dbType: 0,
  dbListTitle: '',
  idColHeader: '',
  dataColHeader: '',

  dbList: [],
  currentDBPage: 1,
  perPage: 10,

  currentEditRecord: {},
  isEditingRecord: false,
  addNewRecordModalOpen: false,
};

export const actions = {
  setSetupTab: tabVal => ({
    type: SET_TAB,
    tabVal,
  }),
  fetchOfficeInfo: (loginID, preparerID) => ({
    type: FETCH_OFFICE_INFO,
    loginID,
    preparerID,
  }),
  onSelectSidebar: row => ({
    type: ON_SELECT_SIDEBAR,
    row,
  }),
  onDBSelectSidebar: index => ({
    type: ON_DB_SELECT_SIDEBAR,
    index,
  }),
  toggleSetupAccount: data => ({
    type: TOGGLE_SETUP_ACCOUNT,
    data,
  }),
  setExpandedHeaders: headers => ({
    type: SET_EXPANDED_HEADERS,
    headers,
  }),
  onOpenUserDBAddModal: (currentEditRecord, isEditingRecord) => ({
    type: ON_OPEN_USER_DB_ADD_MODAL,
    currentEditRecord,
    isEditingRecord,
  }),
  onCloseUpsertUserDBModal: () => ({
    type: ON_CLOSE_USER_DB_MODAL,
  }),
  updateDBType: (dbType, idColHeader, dataColHeader, dbListTitle) => ({
    type: UPDATE_DB_TYPE,
    dbType,
    idColHeader,
    dataColHeader,
    dbListTitle,
  }),
  setDBTableLabels: (idCol, dataCol, title) => ({
    type: SET_DB_TABLE_LABELS,
    idCol,
    dataCol,
    title,
  }),
  onDBPageChange: page => ({
    type: DB_PAGE_CHANGE,
    page,
  }),
  setHiddenForeignInd: () => ({
    type: SET_HIDDEN_FOREIGN_IND,
  }),
  clearAddressData: (domesticFlag, foreignFlag) => ({
    type: CLEAR_ADDRESS_DATA,
    domesticFlag,
    foreignFlag,
  }),
  handleUpdateSelectedState: stateCode => ({
    type: UPDATE_STATE_CODE,
    stateCode,
  }),
  updateCurrentEditRecord: event => ({
    type: UPDATE_CURRENT_EDIT_RECORD,
    event,
  }),
  fetchUserDBList: (dbType, loginID, idColHeader, dataColHeader, dbListTitle) => ({
    type: FETCH_USER_DB_LIST,
    dbType,
    loginID,
    idColHeader,
    dataColHeader,
    dbListTitle,
  }),
  requestDBListSuccess: dbEntryList => ({
    type: FETCH_USER_DB_LIST_SUCCESS,
    dbEntryList,
  }),
  requestDBListError: () => ({
    type: FETCH_USER_DB_LIST_FAILURE,
  }),
  requestUpsertUserDBRecord: (dbType, loginID, dbData) => ({
    type: REQUEST_UPSERT_USER_DB_RECORD,
    dbType,
    loginID,
    dbData,
  }),
  upsertUserDBRecordSuccess: () => ({
    type: UPSERT_USER_DB_RECORD_SUCCESS,
  }),
  upsertUserDBRecordError: () => ({
    type: UPSERT_USER_DB_RECORD_FAILURE,
  }),
  requestDeleteUserDBRecord: (dbType, loginID, key) => ({
    type: REQUEST_DELETE_USER_DB_RECORD,
    dbType,
    loginID,
    key,
  }),
  deleteUserDBRecordSuccess: key => ({
    type: DELETE_USER_DB_RECORD_SUCCESS,
    key,
  }),
  deleteUserDBRecordError: () => ({
    type: DELETE_USER_DB_RECORD_FAILURE,
  }),
  updateOfficeSetupFlag: (field, status) => ({
    type: UPDATE_OFFICE_SETUP_FLAG,
    field,
    status,
  }),
  fetchOfficeSetupProgressFlags: loginID => ({
    type: FETCH_OFFICE_SETUP_PROGESSS_FLAGS,
    loginID,
  }),
  setOfficeSetupFlags: allFlags => ({
    type: SET_OFFICE_SETUP_FLAGS,
    allFlags,
  }),
  setOfficeSetupFlagsFailure: () => ({
    type: SET_OFFICE_SETUP_FLAGS_FAILURE,
  }),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_TAB:
      return {
        ...state,
        selectedTab: action.tabVal,
      };
    case ON_SELECT_SIDEBAR:
      return {
        ...state,
        selectedSidebarItem: action.row,
      };
    case ON_DB_SELECT_SIDEBAR:
      return {
        ...state,
        dbSelectedSidebarItem: action.index,
      };
    case TOGGLE_SETUP_ACCOUNT:
      return {
        ...state,
        setupAccount: !state.setupAccount,
        settingsShortcut:
          action.data && action.data.settingsShortcut ? action.data.settingsShortcut : undefined,
        selectedTab: action.data && action.data.setupTabID ? action.data.setupTabID : 1, // account setup tab is default
      };
    case SET_EXPANDED_HEADERS:
      return {
        ...state,
        expandedHeaders: action.expandedHeaders,
      };
    case ON_OPEN_USER_DB_ADD_MODAL:
      return {
        ...state,
        currentEditRecord: action.currentEditRecord,
        isEditingRecord: action.isEditingRecord,
        addNewRecordModalOpen: true,
      };
    case DB_PAGE_CHANGE:
      return {
        ...state,
        currentDBPage: action.page,
      };
    case ON_CLOSE_USER_DB_MODAL:
      return {
        ...state,
        addNewRecordModalOpen: false,
      };
    case UPDATE_DB_TYPE:
      return {
        ...state,
        dbType: action.dbType,
        idColHeader: action.idColHeader,
        dataColHeader: action.dataColHeader,
        dbListTitle: action.dbListTitle,
        currentDBPage: 1,
      };
    case UPDATE_CURRENT_EDIT_RECORD:
      if (action.event.target.name === 'referral') {
        return {
          ...state,
          currentEditRecord: {
            ...state.currentEditRecord,
            [action.event.target.name]: action.event.target.value
              .replace(/[^a-z0-9]+/gi, '')
              .toUpperCase(),
          },
        };
      } else {
        return {
          ...state,
          currentEditRecord: {
            ...state.currentEditRecord,
            [action.event.target.name]: action.event.target.value,
          },
        };
      }
    case UPDATE_STATE_CODE:
      return {
        ...state,
        currentEditRecord: {
          ...state.currentEditRecord,
          state_code: action.stateCode,
        },
      };
    case CLEAR_ADDRESS_DATA:
      return {
        ...state,
        currentEditRecord: {
          ...state.currentEditRecord,
          address1: '',
          address2: '',
          postal_code: '',
          state_code: null,
          city: '',
          hidden_foreign_ind: action.domesticFlag,
          hidden_domestic_ind: action.foreignFlag,
        },
      };
    case FETCH_USER_DB_LIST_SUCCESS:
      return {
        ...state,
        dbList: action.dbEntryList,
      };
    case FETCH_USER_DB_LIST_FAILURE:
      return {
        ...state,
        dbList: [],
      };
    case UPSERT_USER_DB_RECORD_SUCCESS:
      const list = state.dbList.map(r => {
        if (r.key !== state.currentEditRecord.key) {
          return r;
        } else {
          // special cases where key is updated
          r = state.currentEditRecord;
          if (r.occupation) {
            r.key = state.currentEditRecord.occupation;
          }
          if (r.referral) {
            r.key = state.currentEditRecord.referral;
          }
          return state.currentEditRecord;
        }
      });
      return {
        ...state,
        dbList: list,
      };
    case SET_HIDDEN_FOREIGN_IND:
      return {
        ...state,
        currentEditRecord: {
          ...state.currentEditRecord,
          hidden_foreign_ind: true,
        },
      };
    case UPSERT_USER_DB_RECORD_FAILURE:
      return {
        ...state,
      };
    case DELETE_USER_DB_RECORD_SUCCESS:
      const newList = state.dbList.filter(r => r.key !== action.key);
      return {
        ...state,
        dbList: newList,
      };
    case DELETE_USER_DB_RECORD_FAILURE:
      return {
        ...state,
      };
    case SET_OFFICE_SETUP_FLAGS:
      return {
        ...state,
        officeSetupProgressFlags: action.allFlags,
      };
    case UPDATE_OFFICE_SETUP_FLAG:
      return {
        ...state,
        officeSetupProgressFlags: {
          ...state.officeSetupProgressFlags,
          [action.field]: action.status,
        },
      };
    case Common.LOGOUT:
      return {
        ...state,
        dbList: [],
        currentPage: 1,
        setupAccount: false,
      };
  }
  return state;
}
