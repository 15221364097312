import React from 'react';
import Loadable from 'react-loadable';
import LinearProgress from '@material-ui/core/LinearProgress';

const tempLoadingComponent = () => <LinearProgress />; // <div>Loading...</div>

export const LoadablePreparers = Loadable({
  loader: () => import('~/app/Pages/Preparers/Preparers.jsx'),
  loading: tempLoadingComponent,
});
export const LoadableAccounts = Loadable({
  loader: () => import('~/app/Pages/Accounts/Accounts.jsx'),
  loading: tempLoadingComponent,
});
export const LoadableOffices = Loadable({
  loader: () => import('~/app/Pages/Offices/Offices.jsx'),
  loading: tempLoadingComponent,
});
export const LoadableTaxReturns = Loadable({
  loader: () => import('~/app/Pages/TaxReturns/TaxReturns.jsx'),
  loading: tempLoadingComponent,
});
export const LoadableServer = Loadable({
  loader: () => import('~/app/Pages/ServerStatuses/ServerStatuses.jsx'),
  loading: tempLoadingComponent,
});
export const LoadablePackages = Loadable({
  loader: () => import('~/app/Pages/Packages/StatePackages.jsx'),
  loading: tempLoadingComponent,
});
export const LoadableAppointments = Loadable({
  loader: () => import('~/app/Pages/Appointment/Appointments.jsx'),
  loading: tempLoadingComponent,
});
export const LoadableMessages = Loadable({
  loader: () => import('~/app/Pages/Messages/Messages.jsx'),
  loading: tempLoadingComponent,
});
export const LoadableUserLookup = Loadable({
  loader: () => import('~/app/Pages/TechSupport/UserLookup/UserLookup.jsx'),
  loading: tempLoadingComponent,
});
export const LoadableTechLogins = Loadable({
  loader: () => import('~/app/Pages/TechSupport/TechLogin/TechLogins.jsx'),
  loading: tempLoadingComponent,
});
export const LoadableCustomerReminder = Loadable({
  loader: () => import('~/app/Pages/SoftwareRelease/SoftwareReleaseEmail.jsx'),
  loading: tempLoadingComponent,
});
export const LoadableReadyToPrint = Loadable({
  loader: () => import('~/app/Pages/Checks/pages/ReadyToPrint/ReadyToPrint.jsx'),
  loading: tempLoadingComponent,
});
export const LoadableCheckRegister = Loadable({
  loader: () => import('~/app/Pages/Checks/pages/ChecksRegister/ChecksRegister.jsx'),
  loading: tempLoadingComponent,
});
export const LoadableReporting = Loadable({
  loader: () => import('~/app/Pages/Reporting/Reporting.jsx'),
  loading: tempLoadingComponent,
});
export const LoadableCustomReporting = Loadable({
  loader: () => import('~/app/Pages/Reporting/Reporting.jsx'),
  loading: tempLoadingComponent,
});
export const LoadablePayments = Loadable({
  loader: () => import('~/app/Pages/Payments/Payments.jsx'),
  loading: tempLoadingComponent,
});
export const LoadableMoveReturn = Loadable({
  loader: () => import('~/app/Pages/TechSupport/MoveReturn/MoveReturn.jsx'),
  loading: tempLoadingComponent,
});

// The loadable pages below are not being used yet. These may need to be deprecated eventually.
export const LoadableAdminOfficeList = Loadable({
  loader: () => import('~/app/Pages/Offices/components/OfficeAdminList.jsx'),
  loading: tempLoadingComponent,
});
