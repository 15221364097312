// External imports
import React, { useEffect } from 'react';
import {
  AppBar,
  Button,
  Paper,
  Grid,
  MenuItem,
  TextField,
  FormControl,
  Toolbar,
  Typography,
  withStyles,
} from '@material-ui/core';
// Internal imports
import { useSetState } from '~/app/Utility/customHooks';
import XlinkAPI from '~/app/api/xlinkAPI';
import ErrorHelpers from '~/app/errorHelpers.js';
import { statusOK } from '~/app/webHelpers';
// Redux imports
import { useDispatch } from 'react-redux';
import { actions as appActions } from '~/app/redux/modules/app';
// Styling imports
import { styles } from '~/app/Components/NewAccount/SingleOffice/css/activateCheck.js';
import '~/app/Components/NewAccount/SingleOffice/css/activateCheck.css';

const ActivateCheck = props => {
  const { classes } = props;
  const dispatch = useDispatch();
  const [state, setState] = useSetState({
    startingCheckNum: '',
    endingCheckNum: '',
    siteCode: '',
    siteIDKey: 0,
    bank: 1,
    checkLayoutCode: '',
  });

  useEffect(() => {
    if (props.selected_starting_chk_number != null) {
      const bankcode = getBankCode(props.selected_bank);
      setState({
        startingCheckNum: props.selected_starting_chk_number,
        endingCheckNum: props.selected_ending_chk_number,
        siteCode: props.selected_site_code || '',
        bank: bankcode,
      });
    }

    if (props.selected_layoutCode) {
      setState({
        checkLayoutCode: props.selected_layoutCode,
      });
    }
  }, []);

  const getBankCode = bankName => {
    switch (bankName) {
      case 'Republic Bank':
        return 1;
      case 'TPG':
        return 2;
    }
  };

  // Handle onChange without validation for input fields
  const handleInputChange = e => {
    const name = e.target.name;

    if (name === 'siteCode') {
      // filter the list by the site id to find the selected option
      const value = parseInt(e.currentTarget.id);
      const selectedOption = props.dbList.filter(option => option.id === value)?.[0];
      setState({ siteCode: selectedOption?.site_code, siteIDKey: selectedOption?.id });
    } else {
      setState({ [name]: e.target.value });
    }
  };

  // Adding function that will return object and make call to xlinkAPI
  const submitCheckData = async () => {
    const startingCheckNum = parseInt(state.startingCheckNum);
    const endingCheckNum = parseInt(state.endingCheckNum);
    const siteIDKey = state.siteIDKey ? parseInt(state.siteIDKey) : props.selectedSiteKeyID;
    const siteCode = state.siteCode;
    const bankId = parseInt(state.bank);
    const checkLayoutCode = state.checkLayoutCode;

    if (startingCheckNum > endingCheckNum) {
      dispatch(
        appActions.showSnackbarMessage(
          'Starting check number should be higher than the ending check number',
          'error',
          3500,
          {
            vertical: 'top',
            horizontal: 'center',
          },
        ),
      );
      return;
    }
    if (siteCode.length > 7) {
      dispatch(
        appActions.showSnackbarMessage('Office Cannot Exceed Seven Characters', 'error', 3500, {
          vertical: 'top',
          horizontal: 'center',
        }),
      );
      return;
    }

    const check = {
      startingCheckNum,
      endingCheckNum,
      siteIDKey,
      siteCode,
      bankId,
      checkLayoutCode,
    };

    if (props.selected_starting_chk_number == null) {
      try {
        const res = await XlinkAPI.addUserCheckRangeSetup(check, props.loginID);
        if (statusOK(res, false)) {
          props.getChecks();
          props.handleCloseActivateCheckModal();
          props.clearCheckState();
        }
      } catch (error) {
        ErrorHelpers.handleError('Error with check range: ', error);
      }
    } else {
      try {
        const res = await XlinkAPI.updateUserCheck(check, props.selected_check_id, props.loginID);
        if (statusOK(res, false)) {
          props.getChecks();
          props.handleCloseActivateCheckModal();
          props.clearCheckState();
        }
      } catch (error) {
        ErrorHelpers.handleError('Error updating check: ', error);
      }
    }
  };

  return (
    <Paper>
      <AppBar position="static">
        <Toolbar classes={{ root: classes.toolbarColor }}>
          <Typography classes={{ root: classes.typographyStyle }}>Activate New Check</Typography>
        </Toolbar>
      </AppBar>
      <Grid className="activate-check-container" container spacing={32}>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <TextField
              id="txtStartingCheckNumActivateCheck"
              name="startingCheckNum"
              value={state.startingCheckNum}
              label="Starting Check Number"
              placeholder="1234..."
              onChange={handleInputChange}
              inputProps={{ maxLength: 8 }}
              variant="outlined"
              autoComplete="off"
              required
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth>
            <TextField
              id="txtEndingCheckNumActivateCheck"
              name="endingCheckNum"
              value={state.endingCheckNum}
              label="Ending Check Number"
              placeholder="1500..."
              onChange={handleInputChange}
              inputProps={{ maxLength: 8 }}
              variant="outlined"
              autoComplete="off"
              required
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth>
            <TextField
              select
              name="siteCode"
              id="txtSiteIDActivateCheck"
              value={state.siteCode}
              onChange={handleInputChange}
              label="Site ID"
              variant="outlined"
              disabled={!props.dbList?.length}
            >
              {props.dbList.map((val, i) => (
                <MenuItem key={i} id={val.id} value={val.site_code}>
                  {val.site_code}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth>
            <TextField
              select
              name="bank"
              id="txtBank"
              value={state.bank}
              onChange={e => setState({ bank: e.target.value })}
              label="Bank"
              variant="outlined"
              required
            >
              <MenuItem value={1}>Republic</MenuItem>
              <MenuItem value={2}>TPG</MenuItem>
            </TextField>
          </FormControl>
        </Grid>

        {state.bank === 2 ? (
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                id="txtCheckLayoutVersion"
                name="checkLayoutCode"
                value={state.checkLayoutCode}
                label="Check Layout Version"
                placeholder="TYIG..."
                onChange={handleInputChange}
                inputProps={{ maxLength: 4 }}
                variant="outlined"
                autoComplete="off"
                required
              />
            </FormControl>
          </Grid>
        ) : (
          <></>
        )}

        <Grid item xs={12} container justify="flex-end">
          <div>
            <Button
              id="btnCancelActivateCheck"
              onClick={() => {
                props.clearCheckState();
                props.handleCloseActivateCheckModal();
              }}
              style={{ marginRight: '1rem' }}
            >
              Cancel
            </Button>
            <Button
              id="btnUpdateOrAddActivateCheck"
              onClick={submitCheckData}
              color="primary"
              variant="contained"
            >
              {props.selected_starting_chk_number != null ? 'Update' : 'Activate'}
            </Button>
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default withStyles(styles)(ActivateCheck);
