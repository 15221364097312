import WebHelpers from '~/app/webHelpers.js';
import UtilityAPI from '~/app/api/utilityAPI';

import * as Global from '../constants';
import { isSuperOrTechUserReadOnly } from '../Utility/general';

export default class CalcAPI {
  constructor(socketWorker, returnID, ddh, returnInitialized = false) {
    this.ddh = ddh;
    this.socketWorker = socketWorker;
    this.returnID = returnID;
    this.returnInitialized = returnInitialized;
    this.InitializeSocket();
  }

  // useful for external refund calculator
  switchReturnID = newID => {
    this.returnID = newID;
  };

  constructJSONMessage = (command, jsonObject) => {
    return JSON.stringify(
      Object.assign(jsonObject, {
        command: command,
        returnID: this.returnID,
      }),
    );
  };

  InitializeSocket = () => {
    this.socketWorker.postMessage(
      JSON.stringify({
        command: 'init',
        socketURL: WEBSOCKURL,
        accessToken: WebHelpers.getJWT(),
        drilldownToken: WebHelpers.getDrilldownToken(),
        returnID: this.returnID,
        returnInitialized: this.returnInitialized,
      }),
    );
  };

  CloseSession = () => {
    this.socketWorker.postMessage(
      JSON.stringify({
        command: 'session_close',
      }),
    );
  };

  CloseSocket = () => {
    this.socketWorker.postMessage(
      JSON.stringify({
        command: 'socket_close',
      }),
    );
  };

  Retry = () => {
    this.socketWorker.postMessage(
      JSON.stringify({
        command: 'retry',
      }),
    );
  };

  // Instead of opening a new websocket, we can reuse the same one and just swap the tokens.
  RefreshAndRetry = () => {
    this.socketWorker.postMessage(
      JSON.stringify({
        command: 'refresh_and_retry',
        accessToken: WebHelpers.getJWT(),
        drilldownToken: WebHelpers.getDrilldownToken(),
      }),
    );
  };

  SetReturnInitStatus = isReturnInitialized => {
    this.socketWorker.postMessage(
      JSON.stringify({
        command: 'set_return_init_status',
        returnInitialized: isReturnInitialized,
      }),
    );
  };

  ReturnInit = async (userKey, isEstimator = false, blob = null) => {
    let activeEfinID = 0;
    const payload = WebHelpers.getJWTPayload();
    if (payload.hierarchy_type_id === Global.HIERARCHY_TYPE.PREPARER) {
      activeEfinID = payload.preparer_info.active_efin_id;
    } else {
      activeEfinID = await UtilityAPI.getLastDrilledOfficeEfinID(this.ddh);
    }

    let body = {
      login_id: userKey,
      role: payload.hierarchy_type_id,
      is_estimator: isEstimator,
      active_efin_id: activeEfinID,
    };
    if (blob !== null) {
      body = {
        ...body,
        blob,
      };
    }
    const message = this.constructJSONMessage('return_init', body);
    this.socketWorker.postMessage(message);
  };

  ReturnCalc = (formName, assetCalc, field, val, calcType) => {
    // Form name must be uppercase, however occurrance is case sensitive past 9 entries in return.
    const occuranceNumber = formName.substring(4, 6); // get occuranceNumber of form
    let adjustedFormName = formName.toUpperCase(); // Uppercase entire formname
    adjustedFormName =
      adjustedFormName.substring(0, 4) + occuranceNumber + adjustedFormName.substring(6); // add back orginal occuranceNumber

    const message = this.constructJSONMessage('calc', {
      formName: adjustedFormName,
      astCalc: assetCalc, // TODO: Temporary flag for calc on an asset form
      field: field,
      value: val,
      calcType: calcType,
    });
    this.socketWorker.postMessage(message);
  };

  RefundCalcCreateNewReturn = (rtnId, rfndCalcBlob, ssn, rtnBlob) => {
    const message = this.constructJSONMessage('refundCalculator_createReturn', {
      rtnId: rtnId,
      rtnBlob: rtnBlob,
      newSSN: ssn,
      rfndCalcBlob: rfndCalcBlob,
    });
    this.socketWorker.postMessage(message);
  };

  WizardCalc = returnFlds => {
    const message = this.constructJSONMessage('wizard_calc', {
      returnFlds, // return field is an array of fields with associated value
    });
    this.socketWorker.postMessage(message);
  };

  ReturnChoiceList = (formOccur, field, filter = '') => {
    field = field.substring(0, 4); // Calcserver only needs 4 character field name
    const message = this.constructJSONMessage('return_choicelist', {
      formOccur: formOccur,
      field: field,
      filter: filter,
      limit: filter !== '' ? 5 : null, // Limit 5 if  included filter
    });
    this.socketWorker.postMessage(message);
  };

  ReturnFieldLinkage = formOccur => {
    const message = this.constructJSONMessage('return_linkage', {
      formOccur: formOccur,
    });
    this.socketWorker.postMessage(message);
  };

  ReturnFieldLinkPick = formOccur => {
    const message = this.constructJSONMessage('return_linkPick', {
      listPick: formOccur,
    });
    this.socketWorker.postMessage(message);
  };

  ReturnFormList = (pkg, readOnly = false) => {
    this.socketWorker.postMessage(
      this.constructJSONMessage('return_formslist', {
        pkg: pkg,
        readOnly: readOnly,
      }),
    );
  };

  ReturnListK1s = pkg => {
    this.socketWorker.postMessage(
      this.constructJSONMessage('return_listk1s', {
        pkg: pkg,
      }),
    );
  };

  ReturnAddK1 = () => {
    this.socketWorker.postMessage(this.constructJSONMessage('return_addk1', {}));
  };

  ReturnDeleteK1 = formOccur => {
    this.socketWorker.postMessage(
      this.constructJSONMessage('return_deletek1', {
        formOccur: formOccur,
      }),
    );
  };

  ReturnEmailK1 = formOccur => {
    this.socketWorker.postMessage(
      this.constructJSONMessage('return_emailk1', {
        formOccur: formOccur,
      }),
    );
  };

  // Flag) 0 = show, 1 = do not show, see corpnav, :NAVLINE
  ReturnHideK1s = flag => {
    this.socketWorker.postMessage(
      this.constructJSONMessage('return_hidek1s', {
        hideFlag: flag,
      }),
    );
  };

  ReturnNewAsset = (id, type, desc, date, assetCode) => {
    const calcDate = date.replace('/', '');
    this.socketWorker.postMessage(
      this.constructJSONMessage('return_newasset', {
        dispID: id,
        astType: type,
        desc: desc,
        date: calcDate,
        astCode: assetCode,
      }),
    );
  };

  ReturnViewAsset = id => {
    this.socketWorker.postMessage(
      this.constructJSONMessage('return_viewasset', {
        astID: id,
      }),
    );
  };

  ReturnDeleteAsset = id => {
    this.socketWorker.postMessage(
      this.constructJSONMessage('return_deleteasset', {
        astID: id,
      }),
    );
  };

  ReturnReassignAssetList = astID => {
    this.socketWorker.postMessage(
      this.constructJSONMessage('return_reassignassetlist', {
        astID: astID,
      }),
    );
  };

  ReturnReassignAsset = (astID, actvID) => {
    this.socketWorker.postMessage(
      this.constructJSONMessage('return_reassignasset', {
        astID: astID,
        actvID: actvID,
      }),
    );
  };

  ReturnRemoveDocument = docID => {
    this.socketWorker.postMessage(
      this.constructJSONMessage('return_remove_document', {
        document_id: parseInt(docID),
      }),
    );
  };

  ReturnDetachDocument = (docID, attachVar) => {
    this.socketWorker.postMessage(
      this.constructJSONMessage('return_document_detach', {
        document_id: parseInt(docID),
        document_status_var: attachVar,
      }),
    );
  };

  ReturnPDFAttach = (
    returnID,
    btnID,
    efcode,
    description,
    activeForm,
    document,
    documentName,
    documentDescription,
    documentType,
    attachmentType,
    documentID,
    interviewMode,
  ) => {
    this.socketWorker.postMessage(
      this.constructJSONMessage('return_pdfAttach', {
        returnID: parseInt(returnID),
        btnid: btnID,
        efcode: efcode,
        userDesc: description,
        formOccur: activeForm,
        document: document,
        documentName: documentName,
        documentDescription: documentDescription,
        documentType: parseInt(documentType),
        attachmentType: attachmentType,
        documentID: parseInt(documentID),
        interviewMode: interviewMode,
      }),
    );
  };

  ReturnUploadCSV = (file, spouse) => {
    this.socketWorker.postMessage(
      this.constructJSONMessage('return_stockImport', {
        file: file,
        spouse: spouse,
      }),
    );
  };

  ReturnSaveAndClose = (returnMetaData, isWizard = false) => {
    this.socketWorker.postMessage(
      this.constructJSONMessage('return_saveclose', {
        returnMetaData,
        isWizard,
      }),
    );
  };

  ReturnSave = (returnMetaData, readOnly = false, isWizard = false) => {
    this.socketWorker.postMessage(
      this.constructJSONMessage('return_save', {
        returnMetaData,
        readOnly,
        isWizard,
      }),
    );
  };

  TriggerAutoSave = (readOnly = false) => {
    this.socketWorker.postMessage(
      this.constructJSONMessage('return_auto_save', { readOnly: readOnly }),
    );
  };

  GetTextlinkMessageContent = (cltr = 'English') => {
    this.socketWorker.postMessage(this.constructJSONMessage('get_sms_message', { cltr: cltr }));
  };

  CrosslinkVersions = returnMetaData => {
    this.socketWorker.postMessage(
      this.constructJSONMessage('crosslink_versions', {
        returnMetaData,
      }),
    );
  };

  ReturnClose = (readOnly = false) => {
    this.socketWorker.postMessage(
      this.constructJSONMessage('return_close', { readOnly: readOnly }),
    );
  };

  ReturnDelete = () => {
    this.socketWorker.postMessage(this.constructJSONMessage('return_delete', {}));
  };

  ReturnVerify = () => {
    const payload = WebHelpers.getJWTPayload();

    // grab readOnly flag from isSuperOrTechUserReadOnly() to match with what's being passed on return_init
    const readOnly = isSuperOrTechUserReadOnly(payload);
    this.socketWorker.postMessage(
      this.constructJSONMessage('return_verify', {
        readOnly: readOnly,
      }),
    );
  };

  ReturnFormLoad = (formOccur, stmType, file, field) => {
    this.socketWorker.postMessage(
      this.constructJSONMessage('form_load', {
        formName: formOccur,
        stmType: stmType,
        formFile: file,
        field: field,
      }),
    );
  };

  ReturnAddForm = (formOccur, tors) => {
    this.socketWorker.postMessage(
      this.constructJSONMessage('return_addform', {
        formOccur: formOccur,
        tors: tors,
      }),
    );
  };

  ReturnChoiceListCalc = (formOccur, field, value) => {
    this.socketWorker.postMessage(
      this.constructJSONMessage('return_choicelist_calc', {
        formName: formOccur,
        field: field,
        value: value,
      }),
    );
  };

  ReturnRemoveForm = formOccur => {
    this.socketWorker.postMessage(
      this.constructJSONMessage('return_removeform', {
        formOccur: formOccur,
      }),
    );
  };

  ReturnReloadBilling = () => {
    this.socketWorker.postMessage(this.constructJSONMessage('return_bill_reload', {}));
  };

  SetReturnReviewStatus = flag => {
    this.socketWorker.postMessage(
      this.constructJSONMessage('submit_for_review', {
        reviewStatus: flag,
      }),
    );
  };

  ReturnRemovePackage = pkg => {
    console.log('returnremovepackage called with: ' + pkg);
    this.socketWorker.postMessage(
      this.constructJSONMessage('return_removepackage', {
        pkg: pkg,
      }),
    );
  };

  ReturnAddAsset = () => {
    this.socketWorker.postMessage(
      this.constructJSONMessage('return_addasset', {
        // TODO: Send Data
      }),
    );
  };

  ReturnMessageResponse = (resp, formOccur, labelName) => {
    this.socketWorker.postMessage(
      this.constructJSONMessage('return_msgresp', {
        resp: resp,
        formName: formOccur,
        var: labelName,
      }),
    );
  };

  ReturnPrint = (type, prefs, formOccur, sigs, pAction, password, cltr = 'English', isReadOnly) => {
    this.socketWorker.postMessage(
      this.constructJSONMessage('return_print', {
        formOccur: formOccur,
        pType: type,
        prefs: prefs,
        sigs: sigs,
        pAction: pAction,
        val: formOccur,
        pdfPwd: password,
        cltr: cltr,
        readOnly: isReadOnly,
      }),
    );
  };

  ReturnPrintStateClientLetter = (
    type,
    prefs,
    formOccur,
    sigs,
    pAction,
    password,
    cltr = 'English',
    val = '',
    isReadOnly,
  ) => {
    this.socketWorker.postMessage(
      this.constructJSONMessage('return_print', {
        formOccur: formOccur,
        pType: type,
        prefs: prefs,
        sigs: sigs,
        pAction: pAction,
        val: val,
        pdfPwd: password,
        cltr: cltr,
        readOnly: isReadOnly,
      }),
    );
  };

  ReturnPrintSignatures = (formOccur, type, LPA5, LPB5, readOnly = false, val) => {
    this.socketWorker.postMessage(
      this.constructJSONMessage('return_print_query', {
        formOccur: formOccur,
        pType: type,
        LPA5: LPA5,
        LPB5: LPB5,
        readOnly: readOnly,
        val: val,
      }),
    );
  };

  ReturnStateAck = () => {
    this.socketWorker.postMessage(this.constructJSONMessage('return_stateacks', {}));
  };

  ReturnAttachedStates = () => {
    this.socketWorker.postMessage(this.constructJSONMessage('return_attachedstates', {}));
  };

  ReturnYearToYear = (formOccur, priorID) => {
    this.socketWorker.postMessage(
      this.constructJSONMessage('return_yty', {
        formName: formOccur,
        priorReturnID: priorID,
      }),
    );
  };

  ReturnYearToYearOption = (formOccur, priorID, option) => {
    this.socketWorker.postMessage(
      this.constructJSONMessage('return_yty', {
        formName: formOccur,
        priorReturnID: priorID,
        reloadOption: option,
      }),
    );
  };

  ReturnButtonPress = (buttonID, formOccur) => {
    this.socketWorker.postMessage(
      this.constructJSONMessage('return_buttonpress', {
        button_id: buttonID,
        formName: formOccur,
      }),
    );
  };

  ReturnPaymentQuery = () => {
    this.socketWorker.postMessage(this.constructJSONMessage('return_payments_query', {}));
  };

  OutsideReturnPaymentQuery = async (userKey, blob = null) => {
    let activeEfinID = 0;
    const payload = WebHelpers.getJWTPayload();
    if (payload.hierarchy_type_id === Global.HIERARCHY_TYPE.PREPARER) {
      activeEfinID = payload.preparer_info.active_efin_id;
    } else {
      activeEfinID = await UtilityAPI.getLastDrilledOfficeEfinID(this.ddh);
    }

    let body = {
      login_id: userKey,
      role: payload.hierarchy_type_id,
      active_efin_id: activeEfinID,
    };
    if (blob !== null) {
      body = {
        ...body,
        blob,
      };
    }
    const message = this.constructJSONMessage('outside_return_payments_query', body);
    this.socketWorker.postMessage(message);
  };

  CrosslinkVersions = async () => {
    this.socketWorker.postMessage(this.constructJSONMessage('crosslink_versions', {}));
  };

  PayJunctionTerminalStatus = paymentRequestID => {
    this.socketWorker.postMessage(
      this.constructJSONMessage('payjunction_terminal_status', {
        paymentRequestID: paymentRequestID,
      }),
    );
  };

  ReturnProcessPaymentDetails = (
    dateRcvd,
    rcvdFrom,
    payMethod,
    amt,
    refNum,
    memo,
    formOccur,
    guid,
    readOnly = false,
  ) => {
    this.socketWorker.postMessage(
      this.constructJSONMessage('return_payments', {
        dateRcvd: dateRcvd,
        rcvdFrom: rcvdFrom,
        payMethod,
        amt,
        refNum: refNum,
        memo: memo,
        formOccur: formOccur,
        paymentGUID: guid,
        readOnly: readOnly,
      }),
    );
  };

  OutsideReturnProcessPaymentDetails = async (
    dateRcvd,
    rcvdFrom,
    payMethod,
    amt,
    refNum,
    memo,
    formOccur,
    guid,
    userKey,
  ) => {
    let activeEfinID = 0;
    const payload = WebHelpers.getJWTPayload();
    if (payload.hierarchy_type_id === Global.HIERARCHY_TYPE.PREPARER) {
      activeEfinID = payload.preparer_info.active_efin_id;
    } else {
      activeEfinID = await UtilityAPI.getLastDrilledOfficeEfinID(this.ddh);
    }

    this.socketWorker.postMessage(
      this.constructJSONMessage('outside_return_payments', {
        dateRcvd: dateRcvd,
        rcvdFrom: rcvdFrom,
        payMethod,
        amt,
        refNum: refNum,
        memo: memo,
        formOccur: formOccur,
        paymentGUID: guid,
        login_id: userKey,
        role: payload.hierarchy_type_id,
        active_efin_id: activeEfinID,
      }),
    );
  };

  ReturnProfile = () => {
    this.socketWorker.postMessage(this.constructJSONMessage('return_profile', {}));
  };

  // Not a catchall for all errors and is very specific to just invalid json being sent from calc, but this can be later expanded on as needed.
  ReturnRaiseError = (badmsg, errorMessage, errorStack) => {
    this.socketWorker.postMessage(
      this.constructJSONMessage('return_raise_error', {
        invalidJson: badmsg,
        errorMessage: errorMessage,
        errorStack: errorStack,
      }),
    );
  };

  ReturnBookmarkQuery = (formOccur, fieldID) => {
    if (fieldID.length > 4) {
      fieldID = fieldID.substring(0, 4);
    }
    this.socketWorker.postMessage(
      this.constructJSONMessage('return_bookmark_query', {
        formOccur: formOccur,
        fieldID: fieldID,
      }),
    );
  };

  ReturnBookmarkUpsert = (formOccur, fieldID, desc, timestamp, deleteBookmark) => {
    if (fieldID.length > 4) {
      fieldID = fieldID.substring(0, 4);
    }
    this.socketWorker.postMessage(
      this.constructJSONMessage('return_bookmark_upsert', {
        formOccur: formOccur,
        fieldID: fieldID,
        desc: desc,
        timestamp: timestamp,
        deleteBookmark: deleteBookmark,
      }),
    );
  };

  ReturnNotesUpsert = (note, deleteNote = false) => {
    this.socketWorker.postMessage(
      this.constructJSONMessage('return_notes_upsert', {
        id: note.id.toString(),
        timestamp: note.timestamp,
        note: note.note,
        owner: note.owner,
        deleteNote: deleteNote,
      }),
    );
  };

  ReturnToggleStatusLock = () => {
    this.socketWorker.postMessage(this.constructJSONMessage('return_toggle_status_lock', {}));
  };

  CopyToTraining = () => {
    this.socketWorker.postMessage(this.constructJSONMessage('return_copy_to_training', {}));
  };

  CopyToTrainingDupeCheck = () => {
    this.socketWorker.postMessage(
      this.constructJSONMessage('return_copy_to_training_dupe_check', {}),
    );
  };

  SendRemotePayment = () => {
    this.socketWorker.postMessage(this.constructJSONMessage('return_rmt_pay', {}));
  };

  SendOutsideRemotePayment = () => {
    this.socketWorker.postMessage(this.constructJSONMessage('outside_return_rmt_pay', {}));
  };

  CopyToLive = () => {
    this.socketWorker.postMessage(this.constructJSONMessage('return_copy_to_live', {}));
  };

  CopyToLiveDupeCheck = () => {
    this.socketWorker.postMessage(this.constructJSONMessage('return_copy_to_live_dupe_check', {}));
  };

  SendTextLinkMessage = msg => {
    this.socketWorker.postMessage(
      this.constructJSONMessage('send_textlink_message', {
        name: msg.name,
        cellNumber: msg.number,
        cellCarrier: msg.carrier,
        message: msg.message,
        returnID: msg.returnID,
        carrierDomain: msg.carrierDomain,
      }),
    );
  };

  SendTaxpassMessage = msg => {
    this.socketWorker.postMessage(
      this.constructJSONMessage('send_taxpass_message', {
        message: msg,
      }),
    );
  };

  UpdateRestrictedFieldStatus = (row, formOccur) => {
    this.socketWorker.postMessage(
      this.constructJSONMessage('return_restricted_field', {
        field: row.field,
        description: row.description,
        formID: row.formID,
        formTitle: row.formTitle,
        status: row.status,
        formOccur: formOccur,
      }),
    );
  };
}
