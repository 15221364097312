// Public Routes
import NotFound from '~/app/Pages/NotFound/NotFound.jsx';
import Login from '~/app/Pages/Login/Login.jsx';
import ForgotUsername from '~/app/Pages/ForgotUsername/ForgotUsername.jsx';
import ForgotPassword from '~/app/Pages/ForgotPassword/ForgotPassword.jsx';
import ActivateAcc from '~/app/Components/NewAccount/ActivateAcc.jsx';
import ShowHeaders from '~/app/Components/Common/ShowHeaders.jsx';
import RemoteSignatureAuth from '~/app/Components/RemoteSign/RemoteSignatureAuth.jsx';
import RemoteRequestDocs from '~/app/Components/RemoteRequestDocs/RemoteRequestDocs.jsx';
import PreparerRemoteSign from '~/app/Components/RemoteSign/PreparerRemoteSign.jsx';
import QRModalMobileDone from '~/app/Components/NewAccount/QRModalMobileDone.jsx';
import ReleaseUpdatePwd from '~/app/Pages/SoftwareRelease/ReleaseUpdatePwd.jsx';
import StatePackages from '../Pages/Packages/StatePackages.jsx';
// Private Routes
import MFAMethod from '~/app/Pages/MFAMethod/MFAMethod.jsx';
import MFACode from '~/app/Pages/MFACode/MFACode.jsx';
import TermsAndConditions from '~/app/Pages/TermsAndConditions/TermsAndConditions.jsx';
import IRSMessage from '~/app/Pages/IRSMessage/IRSMessage.jsx';
import FormViewer from '~/app/Pages/Returns/FormViewer.jsx';
import Settings from '~/app/Pages/Setup/Settings.jsx';
import FormViewerLite from '~/app/Pages/RefundCalculator/FormViewerLite.jsx';
import {
  LoadablePreparers,
  LoadableReporting,
  LoadableTaxReturns,
  LoadableMessages,
  LoadableAppointments,
  LoadablePayments,
  LoadableReadyToPrint,
  LoadableCheckRegister,
  LoadableAccounts,
  LoadableOffices,
  LoadableServer,
  LoadableUserLookup,
  LoadableCustomerReminder,
  LoadableMoveReturn,
  LoadableTechLogins,
} from '~/app/Router/helper/routesHelper.js';
import ReturnProfile from '~/app/Pages/ReturnProfile/ReturnProfile.jsx';
import GenericOverview from '#/Dashboard/Overview/GenericOverview/GenericOverview.jsx';
import NewAccount from '~/app/Pages/NewAccount/NewAccount.jsx';
import WizardMode from '~/app/Pages/WizardMode/WizardMode.jsx';
import AppBody from '~/app/AppBody.jsx';

// Routes accessible without logging in / being authenticated
export const publicRoutes = [
  {
    path: '/',
    exact: true,
    Component: Login,
  },
  {
    path: '/forgot-username',
    exact: true,
    Component: ForgotUsername,
  },
  {
    path: '/forgot-password',
    exact: true,
    Component: ForgotPassword,
  },
  {
    path: '/activate/:code',
    Component: ActivateAcc,
  },
  {
    path: '/showheaders',
    Component: ShowHeaders,
  },
  {
    path: '/remotesign/:code',
    Component: RemoteSignatureAuth,
  },
  {
    path: '/remotedocuments/:code',
    Component: RemoteRequestDocs,
  },
  {
    path: '/capture-prep-signature/:code',
    Component: PreparerRemoteSign,
  },
  {
    path: '/mobactivationdone',
    Component: QRModalMobileDone,
  },
  {
    path: '/updateaccount/:code',
    Component: ReleaseUpdatePwd,
  },
];

// Routes only accessible once logged in / authenticated
export const privateRoutes = [
  {
    path: '/mfa-method',
    exact: true,
    Component: MFAMethod,
  },
  {
    path: '/mfa-code',
    exact: true,
    Component: MFACode,
  },
  {
    path: '/terms-and-conditions',
    exact: true,
    Component: TermsAndConditions,
  },
  {
    path: '/irs-message',
    exact: true,
    Component: IRSMessage,
  },
  {
    path: '/tax-return',
    exact: true,
    Component: FormViewer,
  },
  {
    path: '/refund-calculator',
    exact: true,
    Component: FormViewerLite,
  },
  {
    path: '/account-settings',
    exact: true,
    Component: Settings,
  },
  {
    path: '/create-new-account',
    exact: true,
    Component: NewAccount,
  },
  {
    path: '/wizard-mode',
    exact: true,
    Component: WizardMode,
  },
  // Remove the navigation key once the routes below are moved to their own dedicated directory in Pages.

  {
    path: '/reporting/quick-reports',
    Component: LoadableReporting,
  },
  {
    path: '/tax-returns',
    exact: true,
    Component: LoadableTaxReturns,
  },
  {
    path: '/tax-return-profile',
    exact: true,
    Component: ReturnProfile,
  },
  {
    path: '/preparers',
    exact: true,
    Component: LoadablePreparers,
  },
  {
    path: '/accounts',
    exact: true,
    Component: LoadableAccounts,
  },
  {
    path: '/offices',
    exact: true,
    Component: LoadableOffices,
  },
  {
    path: '/dashboard',
    exact: true,
    Component: GenericOverview,
  },
  {
    path: '/calendar',
    exact: true,
    Component: LoadableAppointments,
  },
  {
    path: '/secure-messages',
    exact: true,
    Component: LoadableMessages,
  },
  {
    path: '/checks/ready-to-print',
    exact: true,
    Component: LoadableReadyToPrint,
  },
  {
    path: '/server',
    exact: true,
    Component: LoadableServer,
  },
  {
    path: '/state-packages',
    exact: true,
    Component: StatePackages,
  },
  {
    path: '/user-lookup',
    exact: true,
    Component: LoadableUserLookup,
  },
  {
    path: '/tech-logins',
    exact: true,
    Component: LoadableTechLogins,
  },
  {
    path: '/payments',
    exact: true,
    Component: LoadablePayments,
  },
  {
    path: '/customer-reminder',
    exact: true,
    Component: LoadableCustomerReminder,
  },
  {
    path: '/move-return',
    exact: true,
    Component: LoadableMoveReturn,
  },
  {
    path: '/checks/checks-register',
    Component: LoadableCheckRegister,
  },
  {
    path: '/reporting/custom-reports',
    Component: LoadableReporting,
  },
  // Remove the navigation key once the routes below are moved to their own dedicated directory in Pages.
  {
    path: '/database',
    navigation: 'database',
    Component: AppBody,
  },
  {
    path: '*',
    Component: NotFound,
  },
];
