export const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  qrCodeTitle: {
    color: '#FFFFFF',
    height: '70px',
    backgroundColor: '#444A59',
    fontFamily: 'Roboto',
    fontSize: '15px',
    letterSpacing: '0.1px',
    lineHeight: '18px',
    textAlign: 'left',
    paddingLeft: '32px',
  },
  qrCodeModal: {
    height: 'auto',
    width: '660px',
    padding: '0',
    position: 'relative',
    margin: 'auto',
    textAlign: 'center',
    top: '20%',
  },
  resetCodeModal: {
    height: '300px',
    width: '618px',
    padding: '0',
    position: 'relative',
    margin: 'auto',
    textAlign: 'center',
  },
  continueButtonStyle: {
    marginLeft: '1vw',
    backgroundColor: '#0077FF',
    color: '#FFFFFF',
    textTransform: 'capitalize',
    borderRadius: '4',
  },
  spinner: {
    position: 'absolute',
    top: '40%',
    left: '50%',
  },
  inputField: {
    width: '75%',
  },
});

export const styleResetPassword = {
  minWidth: '0',
  minHeight: '0',
  height: '15px',
  width: '15px',
  paddingLeft: '10px',
  paddingRight: '10px',
  border: 'none',
  display: 'inline',
  color: '#FFFFFF',
  float: 'right',
  marginRight: '2rem',
};
export const styleQRCode = {
  minWidth: '0',
  minHeight: '0',
  height: '15px',
  width: '15px',
  paddingLeft: '10px',
  paddingRight: '10px',
  border: 'none',
  display: 'inline',
  color: '#FFFFFF',
  float: 'right',
  marginRight: '2rem',
};
export const styleDivButtonsPassword = {
  display: 'flex',
  paddingBottom: '30px',
  paddingLeft: '57%',
  paddingRight: '30px',
};
export const styleDivButtonsQRCode = {
  display: 'flex',
  paddingBottom: '30px',
  paddingLeft: '61%',
  paddingRight: '30px',
};
