import React, { Component, Fragment } from "react";
import { Paper, withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { actions as appActions } from "~/app/redux/modules/app";
import { MuiThemeProvider } from "@material-ui/core/styles";
import theme from "~/themes/GenericTheme.jsx";
import GenericPasswordField from "#/Common/GenericPasswordField.jsx";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import AuthAPI from "~/app/api/authAPI.js";
import ErrorHelpers from "~/app/errorHelpers.js";
import SimpleDialog from "#/Common/SimpleDialog.jsx";
import { styles } from "~/app/Pages/SoftwareRelease/css/softwareRelease.js";
import "~/app/Pages/SoftwareRelease/css/softwareRelease.css";

const mapStateToProps = state => {
  return {
    alert: state.app.alert
  };
};

const mapDispatchToProps = {
  ...appActions
};

class ReleaseUpdatePwd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      verifyCode: "",
      decodedVerifyCode: "",
      username: "",
      windowHeight: "",
      windowWidth: "",
      password: "",
      passwordMeetsRequirements: false,
      retypeRequirementsMet: false,
      openSuccessful: false,
      linkExpired: false,
      newLinkSent: false
    };
  }

  componentDidMount = () => {
    //When the ReleaseUpdatePassword component mounts we need to call endpoint to check if the link has expired
    this.handleVerifyLinkExpired();
    //At this point, we have the verify code, which will be used to check if the link is expired
    let decodedString = atob(this.props.match.params.code);
    let userName = decodedString.substring(0, decodedString.length - 8);
    this.setState({
      verifyCode: this.props.match.params.code,
      decodedVerifyCode: decodedString,
      username: userName
    });
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  };

  //Adding function that will handle resizing
  handleResize = () => {
    this.setState({
      windowHeight: window.innerHeight - 200,
      windowWidth: window.innerWidth * 0.59
    });
  };

  setPasswordState = (password, passwordMeetsRequirements) => {
    this.setState({
      password: password,
      passwordMeetsRequirements: passwordMeetsRequirements
    });
  };

  setRetypeState = (pw, flag) => {
    this.setState({
      confirmNewPassword: pw,
      retypeRequirementsMet: flag
    });
  };

  handleVerifyLinkExpired = () => {
    //Call endpoint to check if link is expired by passing verify code
    //When pasword changes successfuly, call endpoint to send an email to user stating that their password has been changed
    AuthAPI.checkResetPswLinkExpired(this.props.match.params.code)
      .then(res => {
        //Depending on value returned prompt user to get a new link (if the link is expired)
        //We need to open modal here, or change the content that is shown within the page
        let expiredLink = res.data;
        if (expiredLink === "true" || expiredLink === true) {
          this.setState({ linkExpired: true });
        }
      })
      .catch(error => {
        ErrorHelpers.handleError(
          "Error Checking if Reset Password Link is Expired",
          error
        );
      });
  };

  handleEmailLink = () => {
    //Call endpoint, and update verify code when the link has already expired for the user
    AuthAPI.updateResetPswLinkExpired(this.props.match.params.code)
      .then(res => {
        //If the verify code is updated successfully, open modal stating that if all the information is correct
        //User will be receiving an email with a new link
        this.setState({ newLinkSent: true });
      })
      .catch(error => {
        ErrorHelpers.handleError("Error Generating New Link", error);
      });
  };

  handleResetPassword = async () => {
    //Call endpoint to update password
    let verifyCode = this.state.verifyCode;
    let newPassword = this.state.password;
    const respResetPsw = await AuthAPI.resetAccountPassword(
      verifyCode,
      newPassword
    );
    //If response OK
    if (respResetPsw.status === 200) {
      //Password was updated successfully, so open message for user
      this.setState({ openSuccessful: true });
      //When pasword changes successfuly, call endpoint to send an email to user stating that their password has been changed
      const sendPswChanged = await AuthAPI.sendEmailPasswordChanged(verifyCode);

      if (sendPswChanged.status !== 200) {
        //If an error occurred while sending email to inform the user that their password was changed
        ErrorHelpers.handleError(
          "Error Sending Password Change Email",
          sendPswChanged.ErrorMsg
        );
      }
    } else {
      //If an error occurred while user's password was being changed
      ErrorHelpers.handleError("Reset Password Error", respResetPsw.ErrorMsg);
    }
  };

  render() {
    const { classes } = this.props;
    const height = this.state.windowHeight;
    const width = this.state.windowWidth;
    const isMobile = width <= 500; //We have a mobile device
    const orientationPortrait = width < height; //We have a mobile device on portrait mode
    const orientationLandscape = width > height; //We have a mobile device on ladscape mode

    if (isMobile && orientationPortrait) {
      return (
        <MuiThemeProvider theme={theme}>
          <div className="rotate-device">
            Please Rotate Your Device to Proceed
          </div>
        </MuiThemeProvider>
      );
    } else {
      return (
        <MuiThemeProvider theme={theme}>
          <div className="container-fluid" id="loginContainer">
            {!this.state.linkExpired && (
              <Paper classes={{ root: classes.paperStyle }}>
                <div className="style-two">
                  <form className="link-form">
                    <div className="style-top">
                      Your username is: <b>{this.state.username}</b>
                    </div>
                    <div className="style-top-two">
                      Please update your password below in order to start using
                      your tax software. You will need your <b>username</b> and{" "}
                      <b>password</b> next time you log into your account.
                    </div>
                    <GenericPasswordField
                      label="New Password"
                      id="txtNewPasswordActivateAcc"
                      name="password"
                      type="password"
                      verticalOffset={1}
                      horizontalOffset={-3.5}
                      checkIfMobile={isMobile}
                      handlePasswordCheck={(
                        password,
                        passwordMeetsRequirements
                      ) =>
                        this.setPasswordState(
                          password,
                          passwordMeetsRequirements
                        )
                      }
                    />
                    <GenericPasswordField
                      label="Confirm New Password"
                      id="txtConfirmNewPasswordActivateAcc"
                      name="confirmNewPassword"
                      type="password"
                      firstvalue={this.state.password}
                      retype={true}
                      verticalOffset={
                        isMobile && orientationLandscape ? -2 : -2.3
                      }
                      horizontalOffset={-3.5}
                      checkIfMobile={isMobile}
                      handlePasswordCheck={(
                        password,
                        passwordMeetsRequirements
                      ) =>
                        this.setRetypeState(password, passwordMeetsRequirements)
                      }
                    />
                    <div className="div-password-style">
                      <Fragment>
                        <Tooltip
                          title={
                            !this.state.passwordMeetsRequirements ||
                            !this.state.retypeRequirementsMet
                              ? "Please make sure the password meets the minimum requirements and the passwords entered in both fields match."
                              : ""
                          }
                        >
                          <span>
                            <Button
                              id="btnUpdatePasswordNewSeason"
                              onClick={() => this.handleResetPassword()}
                              classes={{ root: classes.styledButton }}
                              variant="contained"
                              color="primary"
                              disabled={
                                !this.state.passwordMeetsRequirements ||
                                !this.state.retypeRequirementsMet
                              }
                            >
                              Reset Password
                            </Button>
                          </span>
                        </Tooltip>
                      </Fragment>
                    </div>
                  </form>
                </div>
              </Paper>
            )}
            {this.state.linkExpired && (
              <Paper classes={{ root: classes.paperStyleTwo }}>
                <div className="style-two">
                  <form className="link-expired-form">
                    <div className="div-bold">Your Link Has Expired</div>
                    <div className="link-expired-div-two">
                      Please click the "Email Link" button below if you would
                      like us to email you a new link.
                    </div>
                    <div className="link-expired-div">
                      <Button
                        id="btnUpdatePasswordNewSeason"
                        onClick={() => this.handleEmailLink()}
                        classes={{ root: classes.styledButton }}
                        variant="contained"
                        color="primary"
                      >
                        Email Link
                      </Button>
                    </div>
                  </form>
                </div>
              </Paper>
            )}
            <SimpleDialog
              open={this.state.openSuccessful}
              onConfirm={() => {
                isMobile
                  ? this.props.history.push("/mobactivationdone")
                  : this.props.history.push("/"); //Route back to the login page.
                this.setState({ openSuccessful: false });
              }}
              confirmText="OK"
              styled={true}
              dialogTitle="Reset Password"
              contentText="Your password has been changed successfully."
            />
            <SimpleDialog
              open={this.state.newLinkSent}
              onConfirm={() => {
                isMobile
                  ? this.props.history.push("/mobactivationdone")
                  : this.props.history.push("/"); //Route back to the login page.
                this.setState({ newLinkSent: false });
              }}
              confirmText="OK"
              styled={true}
              dialogTitle="New Link"
              contentText="If the information is correct, you will be receiving an email with a new link."
            />
          </div>
        </MuiThemeProvider>
      );
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ReleaseUpdatePwd));
