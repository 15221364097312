// External imports
import React, { Fragment, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
// Internal imports
import { SIGNEE_TYPE, HIERARCHY_TYPE } from '~/app/constants.js';
import AccessControl from '~/app/Components/Auth/AccessControl.jsx';
import defaultAvatar from '~/images/managerDash.png';
import WebHelpers, { statusOK } from '~/app/webHelpers.js';
import ErrorHelpers from '~/app/errorHelpers.js';
import AuthAPI from '~/app/api/authAPI.js';
import XlinkAPI from '~/app/api/xlinkAPI.js';
import { webSocket } from '~/app/Utility/socketConnection.js';
import { displayTaxPassAppUrl } from '~/app/Utility/taxpassHelper.js';
import { openWallet, getWallet } from '~/app/Pages/Payments/helpers/paymentHelpers.js';
import { isDemoAccount } from '~/app/Utility/general';
import { useSetState } from '~/app/Utility/customHooks';
import defaultQR from '~/images/whiteSquare.png';
// Redux imports
import { useDispatch, useSelector } from 'react-redux';
import { actions as appActions } from '~/app/redux/modules/app';
import { actions as loginSetupActions } from '~/app/redux/loginSetup/duck';

const Account = ({
  setAuthManagementOpen,
  setAuthManagementMode,
  toggleLoginSettingsDialog,
  onClickSignature,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const payload = WebHelpers.getJWTPayload();
  const demoAccountFlag = isDemoAccount(payload);
  const { PREPARER, EFIN, FRANCHISE, SB, SSB } = HIERARCHY_TYPE;

  const {
    drilldownHistory,
    isAccountVisible,
    currentView,
    isFeeder,
    efinID,
    customAvatar,
    mobileAppID,
    mobileAppQRCode,
    userIsEfinOwner,
    userHasPreparerLink,
    basicInfo,
    activeAccessLevels,
  } = useSelector(state => ({
    drilldownHistory: state.drilldown.drilldownHistory,
    isAccountVisible: state.app.isAccountVisible,
    currentView: state.drilldown.drilldownHistory[state.drilldown.drilldownHistory.length - 1],
    isFeeder: state.officeProfile.is_feeder_office,
    efinID: state.officeProfile.efin_id,
    customAvatar: state.app.avatarBase64,
    mobileAppID: state.loginSetup.mobileAppID,
    mobileAppQRCode: state.loginSetup.mobileAppQRCode,
    userIsEfinOwner: state.app.userIsEfinOwner,
    userHasPreparerLink: state.app.userHasPreparerLink,
    basicInfo: state.app.loggedInBasicInfo,
    activeAccessLevels: state.loginSetup.activeAccessLevels,
  }));

  const [state, setState] = useSetState({
    prepID: undefined,
    canViewWallet: false,
    generateID: false,
    drilledDownAccount: '',
    activeCompany: '',
    isDrilldown: false,
    showEROSignatureModal: false,
    walletObj: {
      walletAccountOverride: false,
      wallets: [],
    },
    currentRoleAccessLevels: [],
  });

  useEffect(() => {
    if (payload !== undefined) {
      if (payload.preparer_info?.offices !== null && payload.preparer_info?.active_efin_id) {
        const currentOffice = payload.offices?.filter(office => {
          return office.efin_id === payload.preparer_info.active_efin_id;
        });

        setState({
          activeCompany: currentOffice ? currentOffice?.[0]?.company_name : 'ERROR',
        });
      }
    }
    setState({
      showEROSignatureModal: userIsEfinOwner === 1 || currentView?.role === EFIN,
    });

    fetchPrepID();
    fetchAccessLevelsForRole(payload.roles?.[0]?.id, payload.roles?.[0]?.is_custom_role);
  }, []);

  useEffect(() => {
    if (canSignPrepOrERO) {
      dispatch(loginSetupActions.fetchMobileAppIntegration());
    }

    // Check if hierarchy type of current role
    const isHierarchyTypeOf = [SSB, SB, FRANCHISE, EFIN].includes(currentView?.role);
    setState({ canViewWallet: isHierarchyTypeOf });

    // Check permission to access wallet before attempting to retrieve wallet. Custom Access Levels are currently limited
    if (!Object.prototype.hasOwnProperty.call(activeAccessLevels, 'access_wallet')) {
      return;
    }

    getWalletTokenHandler(isHierarchyTypeOf);
  }, [currentView]);

  useEffect(() => {
    if (drilldownHistory) {
      const drilledDownAcct =
        drilldownHistory.length > 1 ? drilldownHistory[drilldownHistory.length - 1].name : '';

      const isDrilldown =
        drilldownHistory.length > 1 &&
        (location.pathname === '/dashboard' || location.pathname === '/checks');

      setState({ drilledDownAccount: drilledDownAcct });
      setState({ isDrilldown });
      initialLoginType();
    }
  }, [drilldownHistory]);

  const fetchPrepID = async () => {
    try {
      const res = await XlinkAPI.getPreparerIDbyLoginID(currentView.loginID);
      setState({ prepID: res.data });
    } catch (err) {
      ErrorHelpers.handleError('Failed to retrieve Preparer ID', err);
    }
  };

  const getRoleName = () => {
    let roleName = '';
    if (payload !== undefined) {
      roleName = payload.roles !== undefined ? payload.roles[0].access_friendly_role_name : '';
    }
    return roleName;
  };

  /**
   * checks against current drilldown role & season; Only multi-office and preparer views should have this option available.
   * if office is type feeder, do not show link
   */
  const canViewWallet = () => {
    return state.canViewWallet && payload?.season >= 2021 && !isFeeder;
  };

  /**
   * Determine wallet label Account or Office
   *
   * @returns wallet type either Account or Office
   */
  const initialLoginType = () => {
    if ([SB, SSB, FRANCHISE].includes(drilldownHistory?.[0]?.role)) {
      setState({ isInitialLoginAccountType: true });
    }
  };

  const onClickTraining = () => {
    dispatch(appActions.toggleAccountModal());
    dispatch(loginSetupActions.toggleTrainingMode());
    dispatch(appActions.toggleSearchBar());
  };

  const onClickLogOut = () => {
    // Once chat window is close, then we can log out.
    AuthAPI.logout().finally(() => {
      const pChatEle = document.querySelector('#jsm-chat');

      // Hide the chat button on successful logout.
      if (pChatEle) {
        pChatEle.style.display = 'none';
      }

      webSocket && webSocket.closeSocketConnection();
      dispatch(appActions.onLogout());
    });
  };

  const getWalletTokenHandler = async canViewWallet => {
    if (!canViewWallet) {
      return;
    }
    try {
      const resp = await getWallet(payload?.season, state.isDrilldown, efinID);

      if (resp && typeof resp?.walletAccountOverride === 'boolean' && resp?.wallets?.length) {
        let walletObj = { ...state.walletObj };
        walletObj = resp;

        setState({ walletObj });
      }
    } catch (err) {
      ErrorHelpers.handleError(
        'Unable to open wallet',
        ErrorHelpers.createSimpleError(
          'Please try again later. If the problem persists, please contact technical support.',
        ),
      );
    }
  };

  /**
   * Fetches Access Levels that are associated to an Access Role
   *
   * @param {number} roleID roleID the id associated to the role name
   */
  const fetchAccessLevelsForRole = async (roleID, isCustom) => {
    try {
      const res = await XlinkAPI.fetchAccessLevelsByRoleID(roleID, isCustom);
      if (statusOK(res)) {
        setState({ currentRoleAccessLevels: res.data });
      }
    } catch (err) {
      dispatch(
        appActions.showSnackbarMessage(err, 'error', 3500, {
          vertical: 'top',
          horizontal: 'center',
        }),
      );
      ErrorHelpers.handleError('Error fetching access levels', err);
    }
  };

  const onSwitchOfficeYear = (toggleTaxyearSwitchModal = true) => {
    dispatch(appActions.toggleAccountModal());
    let toggleModalValue = 2; // Toggle the office switch modal
    if (toggleTaxyearSwitchModal) {
      toggleModalValue = 1; // Toggle the tax year switch modal
    }
    dispatch(appActions.toggleSwitchOfficeYear(toggleModalValue));
  };

  const onClickManageAuth = mode => {
    setAuthManagementOpen(true);
    setAuthManagementMode(mode);

    // If Auth clicked from Account Dropdown hide after click
    if (isAccountVisible) {
      dispatch(appActions.toggleAccountModal());
    }
  };

  const openAvatarModal = () => {
    dispatch(appActions.toggleAccountModal());
    dispatch(appActions.toggleAddAvatar());
  };

  const onClickLoginSettings = () => {
    if (isAccountVisible) {
      dispatch(appActions.toggleAccountModal());
    }
    toggleLoginSettingsDialog(true, false);
  };

  const onClickVersions = () => {
    if (isAccountVisible) {
      dispatch(appActions.toggleAccountModal());
      dispatch(appActions.toggleVersions());
    }
  };

  const onClickSignatureModal = () => {
    dispatch(appActions.toggleAccountModal());
    // If preparer/ero account does not have a signature this.props.toggleSignature()
    dispatch(appActions.toggleNoCaptureSignature());
  };

  const openWalletHandler = (walletObj, walletType) => {
    if (walletObj && walletObj?.wallets) {
      dispatch(appActions.toggleAccountModal());
      // TODO - Update this to read only flag when wallet has that ready
      if (drilldownHistory.length > 1 || isFeeder) {
        state.isDrilldown = true;
      }

      let walletToken;
      let walletSecToken;
      walletObj.wallets.forEach(wallet => {
        if (wallet.walletType === walletType) {
          walletToken = wallet.token;
          walletSecToken = wallet.secToken;
        }
      });

      if (walletToken && walletSecToken) {
        openWallet(walletToken, walletSecToken, payload?.season, state.isDrilldown);
      } else {
        throw ErrorHelpers.createSimpleError(
          'We were unable to find your wallet, if the problem persists please contact technical support.',
        );
      }
    }
  };

  const walletLinkHandler = () => {
    if (!demoAccountFlag && state.walletObj?.wallets?.length) {
      return (
        <AccessControl
          requiredAction="read"
          accessLevel="access_wallet"
          additionalAccessCheck={canViewWallet()}
        >
          {state.isInitialLoginAccountType && (
            <div
              id="btnWalletSetup"
              className="link-text"
              onClick={() => openWalletHandler(state.walletObj, 'account')}
            >
              UserID Wallet
            </div>
          )}
          {!state.walletObj?.walletAccountOverride && currentView?.role === EFIN && (
            <div
              id="btnWalletSetup"
              className="link-text"
              onClick={() => openWalletHandler(state.walletObj, 'office')}
            >
              Office Wallet
            </div>
          )}
        </AccessControl>
      );
    }

    return null;
  };

  const displayGenerateMobileAppID = () => {
    return (
      <div
        id="btnGenerateMobileAppID"
        className={'small-link-text'}
        onClick={() => {
          setState({ generateID: true });
          dispatch(loginSetupActions.generateMobileAppID());
        }}
        disabled={state.generateID}
      >
        Generate TaxPass ID
      </div>
    );
  };

  const handleDisplayCompanyName = () => {
    if (payload !== undefined && payload.preparer_info !== null) {
      return <div className="office-name">{state.activeCompany}</div>;
    }

    return null;
  };

  const handleSwitchOfOfficeDisplay = () => {
    if ((payload?.offices || []).length >= 2 && !activeAccessLevels?.create_login) {
      return (
        <div
          id="btnSwitchOffice"
          className="link-text"
          onClick={() => {
            onSwitchOfficeYear(false);
          }}
        >
          Switch Office
        </div>
      );
    }

    return null;
  };

  const handleDisplayUserIDAndEFIN = () => {
    if ((canSignPrepOrERO || [SB, SSB, FRANCHISE].includes(currentView?.role)) && basicInfo) {
      return (
        <div className="account-id">
          {basicInfo?.user_id && <span>UserID: {basicInfo?.user_id}</span>}
          {basicInfo?.user_id && basicInfo?.efin && <span>&nbsp; | &nbsp;</span>}
          {basicInfo?.efin && <span>Efin: {basicInfo?.efin}</span>}
        </div>
      );
    }

    return null;
  };

  const canSignPrepOrERO = [EFIN, PREPARER].includes(currentView?.role);

  return (
    <div className="outer-rectangle">
      <div className="account-outer-rectangle-scroll">
        <div className="person-block">
          <div className="initial-block">
            <img src={customAvatar === '' ? defaultAvatar : customAvatar} className="avatar" />
          </div>
          <div className="user-info-block">
            <div className="account-name">{payload !== undefined ? payload.full_name : ''}</div>
            <div className="role-name">{getRoleName()}</div>
            <div id="btnUploadImage" className={'small-link-text'} onClick={openAvatarModal}>
              Upload Image
            </div>
          </div>
        </div>
        {canSignPrepOrERO && (
          <div className="mobile-block">
            <div className="mobile-info-block">
              <img
                src={
                  mobileAppQRCode === '' ? defaultQR : 'data:image/png;base64, ' + mobileAppQRCode
                }
                className="qr-code"
              />

              <div className="mobile-app-id">TaxPass Site ID</div>
              <div className="small-text">
                {mobileAppID === '' ? 'No TaxPass Site ID' : mobileAppID}
              </div>
              {mobileAppID === ''
                ? displayGenerateMobileAppID()
                : displayTaxPassAppUrl(mobileAppID)}
            </div>
          </div>
        )}
        <hr />
        <div className="link-block">
          {handleDisplayCompanyName()}
          <div className="tax-year">
            {payload?.season !== undefined ? payload.season - 1 : ''} Tax Year
          </div>
          <div
            id="btnSwitchTaxYear"
            className="link-text"
            onClick={() => {
              onSwitchOfficeYear(true);
            }}
          >
            Switch Tax Year
          </div>
          {handleSwitchOfOfficeDisplay()}
          {handleDisplayUserIDAndEFIN()}
        </div>
        <hr />
        {state.drilledDownAccount === '' ? (
          <>
            <div className="link-block">
              <div
                id="btnChangePassword"
                className="link-text"
                onClick={() => onClickManageAuth(0)}
              >
                Change Password
              </div>
              <div
                id="btnGenerateNewQRCode"
                className="link-text"
                onClick={() => onClickManageAuth(1)}
              >
                Generate NEW 2FA QR Code
              </div>
            </div>
            <hr />
          </>
        ) : (
          <></>
        )}
        <div className="link-block link-block-last">
          <AccessControl requiredAction="write" accessLevel="access_training_returns">
            {canSignPrepOrERO ? (
              <div id="btnToggleTrainingMode" className="link-text" onClick={onClickTraining}>
                Toggle Training Mode
              </div>
            ) : null}
          </AccessControl>

          {/* THIS DIV IS NOT DISPLAYING */}
          <div
            className="link-text"
            onClick={() => dispatch(appActions.toggleAccountModal())}
            style={{ display: 'none' }}
          >
            Profile Settings
          </div>
          <AccessControl requiredAction="write" accessLevel="view_databases">
            {canSignPrepOrERO && (
              <Link to="/database" style={{ textDecoration: 'none' }}>
                <div id="btnDatabase" className="link-text">
                  Database
                </div>
              </Link>
            )}
          </AccessControl>
          {!state.isDrilldown &&
            state.currentRoleAccessLevels.includes('capture_ero_signature') &&
            !isFeeder && (
              <div
                id="btnCaptureEROSignature"
                className="link-text"
                onClick={() =>
                  state.showEROSignatureModal
                    ? onClickSignature(SIGNEE_TYPE.ERO)
                    : onClickSignatureModal()
                }
              >
                Capture ERO Signature
              </div>
            )}
          {/* only managers linked to a preparer account and preparers should be 
          able to capture prep signature */}
          {!state.isDrilldown && canSignPrepOrERO && state.prepID > 0 && (
            <div
              id="btnCapturePreparerSignature"
              className="link-text"
              onClick={() => onClickSignature(SIGNEE_TYPE.PREPARER)}
            >
              Capture Preparer Signature
            </div>
          )}
          {walletLinkHandler()}
          <div
            id="btnLoginPreferences"
            className="link-text"
            onClick={() => onClickLoginSettings(true)}
          >
            Login Preferences
          </div>
          {!demoAccountFlag && (
            <div id="btnVersions" className="link-text" onClick={onClickVersions}>
              Versions
            </div>
          )}
          <div id="btnSignOut" className="link-text" onClick={onClickLogOut}>
            Sign Out
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
