/**
 * Setup the multi-office data object for passing to PagedTable component.
 */
export const setupLicenseDataForPagedTable = moLicensingSetup => {
  if (Object.keys(moLicensingSetup).length) {
    const moData = [];

    moLicensingSetup.map((mo, index) => {
      const moInfo = {};

      const availableToGen =
        mo.licenses_available_to_generate < 0 ? 0 : mo.licenses_available_to_generate;

      console.log('mo: ', mo);

      mo.id = index;
      moInfo.data = mo;
      moInfo.columns = [
        mo.firmName,
        mo.licenses_able_to_generate,
        availableToGen,
        mo.licenses_generated,
        mo.licenses_consumed,
      ];
      moData.push(moInfo);
      return mo;
    });

    return moData;
  }
};

export const checkLicencingSetupData = (licencingSetup, selectedKey) => {
  if (licencingSetup && licencingSetup.licenseInfo && licencingSetup.licenseInfo[selectedKey]) {
    return licencingSetup.licenseInfo[selectedKey];
  } else {
    return { licenseID: '', season: '', officeID: 0 };
  }
};

export const licenseUsed = licencingSetup => {
  let licenseMsg;
  if (
    licencingSetup &&
    Number.isInteger(licencingSetup?.applied) &&
    Number.isInteger(licencingSetup?.unapplied)
  ) {
    licenseMsg = `${licencingSetup.applied} out of
    ${licencingSetup.applied + licencingSetup.unapplied}
    Licenses Used`;
  } else {
    licenseMsg = 'Licenses Used';
  }
  return licenseMsg;
};

export const getRemoveText = (licencingSetup, selectedKey) => {
  let text = '';

  if (licencingSetup?.licenseInfo[selectedKey]) {
    text = `Remove License ${licencingSetup.licenseInfo[selectedKey].licenseID} from
  ${licencingSetup.licenseInfo[selectedKey].officeName}?
  `;
  }
  return text;
};

// helper constants for labeling license type
export const INDIVIDUAL = 'INDIVIDUAL';
export const BUSINESS = 'BUSINESS';
