// External imports
import React from 'react';
import ReactPaginate from 'react-paginate';
import { Button, Divider, Modal, Paper, withStyles } from '@material-ui/core';
// Internal imports
import AccessControl from '~/app/Components/Auth/AccessControl.jsx';
import ErrorHelpers from '~/app/errorHelpers.js';
import NewPreparerModal from '~/app/Components/NewAccount/SingleOffice/NewPreparerModal.jsx';
import SimpleDialog from '#/Common/SimpleDialog.jsx';
import PreviewSignature from '#/Common/PreviewSignature.jsx';
import SendPreparerRMSModal from '~/app/Components/NewAccount/SingleOffice/SendPreparerRMSModal.jsx';
import SetupPage from '~/app/Components/Settings/Setup/SetupPage.jsx';
import XlinkAPI from '~/app/api/xlinkAPI';
import { statusOK } from '~/app/webHelpers';
import {
  HIERARCHY_TYPE,
  ACCOUNT_TYPES,
  COMMON_PAGINATION_PROPS,
  SIGNEE_TYPE,
} from '~/app/constants.js';
// Redux imports
import { connect } from 'react-redux';
import { selectors as loginSelector } from '~/app/redux/loginSetup/selectors';
import { actions as appActions } from '~/app/redux/modules/app';
import { actions as preparerSetupActions } from '~/app/redux/preparerSetup/duck';
// Styling imports
import { styles } from '#/NewAccount/SingleOffice/css/preparerSetup.js';
import '#/NewAccount/SingleOffice/css/preparerSetup.css';
import '~/app/Pages/Setup/css/setup.css';

const mapDispatchToProps = {
  ...appActions,
  ...preparerSetupActions,
};
const mapStateToProps = state => {
  return {
    drilldownHistory: state.drilldown.drilldownHistory,
    currentView: state.drilldown.drilldownHistory[state.drilldown.drilldownHistory.length - 1],
    resultsPerPage: state.preparerSetup.resultsPerPage,
    preparerPage: state.preparerSetup.preparerPage,
    totalPreparers: state.preparerSetup.totalPreparers,
    preparers: state.preparerSetup.preparers,
    officeName: state.app.loggedInBasicInfo.company_name,
    canRequestPreparerSig: Object.prototype.hasOwnProperty.call(
      loginSelector.getActiveAccessLevels(state),
      'add/edit_preparer',
    ),
  };
};

class PreparerSetup extends SetupPage {
  constructor(props) {
    const name = 'Preparer'; // You need this for save and next to transition
    super(props, name);
    this.state = {
      currentPreparerID: null,
      currentPreparerName: '',
      currentPreparerSignature: '',
      pagedTableStyle: {
        minHeight: `${this.props.resultsPerPage * 2.9 /* magic num */}em`,
      },
      preparerIDToDelete: -1, // need to store this as state becase we have many preparers in the list at once

      // modals
      isAddPreparerModalOpen: false,
      isConfirmCancelModalOpen: false,
      isDeleteConfirmationOpen: false,
      isPrepRMSModalOpen: false,
      isSignatureExistModalOpen: false,
      isActiveRMSModalOpen: false,
    };
  }

  getInitialConfigState() {
    this.setState({
      currentPreparerID: null,
      currentPreparerName: '',
      pagedTableStyle: {
        minHeight: `${this.props.resultsPerPage * 2.9 /* magic num */}em`,
      },
      preparerIDToDelete: -1, // need to store this as state becase we have many preparers in the list at once
      isSignatureExistModalOpen: false,

      // modals
      isAddPreparerModalOpen: false,
      isConfirmCancelModalOpen: false,
      isDeleteConfirmationOpen: false,
      isPrepRMSModalOpen: false,
      isActiveRMSModalOpen: false,
    });
  }

  componentDidMount() {
    this.fetchPreparers();
  }

  fetchPreparers = data => {
    let offset = 0;
    if (data) {
      offset = Math.ceil(data.selected * this.props.resultsPerPage);
    }

    this.props.fetchMyPreparers(this.props.currentView.loginID, this.props.resultsPerPage, offset);
  };

  clearPreparerState = () => {
    this.setState({
      currentPreparerID: null,
    });
  };

  deletePreparer = async preparerID => {
    try {
      const res = await XlinkAPI.deletePreparerByID(preparerID, this.props.currentView.loginID);
      if (statusOK(res, false)) this.fetchPreparers();
    } catch (error) {
      ErrorHelpers.handleError('Failed to delete preparer', error);
    }
  };

  setPreparerIDToDelete = preparerID => {
    this.setState({
      preparerIDToDelete: preparerID,
    });
  };

  toggleModal = modalName => {
    this.setState({
      [modalName]: !this.state[modalName],
    });
  };

  startPrepRMSProcess = async (preparerID, prepName) => {
    this.setState({ currentPreparerID: preparerID, currentPreparerName: prepName });

    try {
      const res = await XlinkAPI.getPreparerSignature(preparerID);

      if (statusOK(res)) {
        const { preparerSignature, requestStatus } = res.data;
        // check to see if there is a pending RMS or an existing signature and supply the correct modal, otherwise continue RMS
        const modalName =
          requestStatus === 1
            ? 'isActiveRMSModalOpen'
            : preparerSignature.length === 0
            ? 'isPrepRMSModalOpen'
            : 'isSignatureExistModalOpen';
        this.setState({
          [modalName]: true,
          currentPreparerSignature: preparerSignature,
        });
      }
    } catch (error) {
      ErrorHelpers.handleError('Error Fetching Preparer Signature', error);
    }
  };

  buildPreparersTable = () => {
    const classes = this.props.classes;
    const notEFINOrPrep =
      this.props.currentView.role !== HIERARCHY_TYPE.PREPARER &&
      this.props.currentView.role !== HIERARCHY_TYPE.EFIN;

    if (this.props.preparers !== null) {
      return this.props.preparers.map((prep, key) => (
        <tr key={key}>
          <td className="row-padding-bottom">{prep.preparer_shortcut_id}</td>
          <td className="row-padding-bottom">{prep.preparer_name}</td>
          <td className="row-padding-bottom">
            {prep.has_preparer_signature ? 'COMPLETED' : 'NOT COMPLETED'}
          </td>
          {notEFINOrPrep && <td className="row-padding-bottom">{prep.preparer_efin}</td>}
          <td className="row-padding-bottom">
            <AccessControl
              requiredAction="write"
              accessLevel="add/edit_preparer"
              disableOnFalse={true}
            >
              <Button
                id={`btnExecuteSign${key}`}
                onClick={() => this.startPrepRMSProcess(prep.preparer_id, prep.preparer_name)}
                color="primary"
                classes={{ root: classes.captureSignatureButton }}
              >
                Remote Signature
              </Button>
              <Button
                id={`btnExecuteDelete${key}`}
                onClick={() => {
                  this.setPreparerIDToDelete(prep.preparer_id);
                  this.toggleModal('isDeleteConfirmationOpen');
                }}
                color="primary"
                classes={{ root: classes.buttonSize }}
              >
                Delete
              </Button>
              &nbsp;
              <Button
                id={`btnExecuteEdit${key}`}
                color="primary"
                classes={{ root: classes.buttonSize }}
                onClick={() => {
                  this.setState({ currentPreparerID: prep.preparer_id });
                  this.toggleModal('isAddPreparerModalOpen');
                }}
              >
                Edit
              </Button>
            </AccessControl>
          </td>
        </tr>
      ));
    }
  };

  render() {
    const classes = this.props.classes;

    const totalPreparerPages = Math.ceil(this.props.totalPreparers / this.props.resultsPerPage);
    let preparersOffset = this.props.preparerPage * this.props.resultsPerPage + 1;

    if (!this.props.preparers || this.props.totalPreparers === 0) {
      preparersOffset = 0;
    }
    let preparersLastRecord = preparersOffset + this.props.resultsPerPage - 1;
    if (this.props.totalPreparers < preparersLastRecord) {
      preparersLastRecord = this.props.totalPreparers;
    }

    const isEFINOrPrep =
      this.props.currentView.role === HIERARCHY_TYPE.PREPARER ||
      this.props.currentView.role === HIERARCHY_TYPE.EFIN;

    return (
      <div className="container-fixed preparer-setup-container">
        <Paper>
          <form className="preparer-setup-form">
            <div className="preparer-setup-form-content">
              <div className="preparer-setup-form-heading">Preparer(s)</div>
              <div className="preparer-setup-form-btn-container">
                {isEFINOrPrep && (
                  <AccessControl
                    requiredAction="write"
                    accessLevel="add/edit_preparer"
                    disableOnFalse={true}
                  >
                    <Button
                      id="btnAddNewPreparerSetup"
                      onClick={() => {
                        this.setState(
                          { currentPreparerID: 0 },
                          this.toggleModal('isAddPreparerModalOpen'),
                        );
                      }}
                      color="primary"
                      size="medium"
                      style={{ padding: '0.5em' }}
                    >
                      Create New Preparer
                    </Button>
                  </AccessControl>
                )}
              </div>
            </div>
            <div style={this.state.pagedTableStyle}>
              <table className="preparer-setup-table">
                <thead>
                  <tr>
                    <th align="left" className="preparer-setup-table-heading">
                      PREPARER ID/SHORTCUT
                      <Divider className="preparer-setup-divider-top-margin" />
                    </th>
                    <th align="left" className="preparer-setup-table-heading">
                      PREPARER NAME
                      <Divider className="preparer-setup-divider-top-margin" />
                    </th>
                    <th align="left" className="preparer-setup-table-heading">
                      PREPARER SIGNATURE STATUS
                      <Divider className="preparer-setup-divider-top-margin" />
                    </th>

                    {!isEFINOrPrep && (
                      <th className="preparer-setup-table-heading">
                        EFIN
                        <Divider className="preparer-setup-divider-top-margin" />
                      </th>
                    )}
                    <th
                      align="center"
                      className="preparer-setup-table-heading heading-30-perc-width"
                    >
                      ACTION
                      <Divider className="preparer-setup-divider-top-margin" />
                    </th>
                  </tr>
                </thead>
                <tbody>{this.buildPreparersTable()}</tbody>
              </table>
              <div className="preparer-setup-paginate-container">
                {this.props.totalPreparers > 0 && (
                  <div className="row paginate-page-count-container">
                    <span className="col paginate-page-count">
                      Showing <span style={{ color: 'black' }}>{preparersOffset}</span> to{' '}
                      <span style={{ color: 'black' }}>{preparersLastRecord}</span> of{' '}
                      {this.props.totalPreparers}
                    </span>
                  </div>
                )}
                <ReactPaginate
                  forcePage={this.props.preparerPage}
                  onPageChange={this.fetchPreparers}
                  pageCount={totalPreparerPages}
                  {...COMMON_PAGINATION_PROPS}
                />
              </div>
              <br />
            </div>
            <br />
            <div>
              <Divider className="preparer-setup-divider-left-right-margin" />
              <br />
              <div className="setup-nav-btns">
                {this.hasPrev() ? (
                  <Button
                    id="btnPrevPreparerSetup"
                    color="primary"
                    onClick={() => this.toggleModal('isConfirmCancelModalOpen')}
                  >
                    Previous (cancel)
                  </Button>
                ) : null}
                {this.hasNext() ? (
                  <Button id="btnNextPreparerSetup" color="primary" onClick={this.getNextPage}>
                    Save &amp; Next
                  </Button>
                ) : null}
              </div>

              <br />
            </div>
          </form>
        </Paper>

        {/* Modals */}
        <Modal
          id="mdlPrepRMSModalPreparerSetup"
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.isPrepRMSModalOpen}
          onClose={() => this.toggleModal('isPrepRMSModalOpen')}
          style={{ overflowY: 'auto' }}
          disableAutoFocus={true}
          disableBackdropClick={true}
        >
          <Paper classes={{ root: classes.prepRMSModal }}>
            <SendPreparerRMSModal
              onClose={() => this.toggleModal('isPrepRMSModalOpen')}
              currentPreparerID={this.state.currentPreparerID}
              currentPreparerName={this.state.currentPreparerName}
              officeName={this.props.officeName}
              canRequestPreparerSig={this.props.canRequestPreparerSig}
            />
          </Paper>
        </Modal>
        <Modal
          id="mdlAddPreparerModalPreparerSetup"
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.isAddPreparerModalOpen}
          onClose={() => this.toggleModal('isAddPreparerModalOpen')}
          style={{ overflowY: 'auto' }}
          disableAutoFocus={true}
          disableBackdropClick={true}
        >
          <Paper elevation={5} classes={{ root: classes.newPreparerModal }}>
            <NewPreparerModal
              handleClosePreparerModal={() => this.toggleModal('isAddPreparerModalOpen')}
              selected_preparer_id={this.state.currentPreparerID}
              accountType={ACCOUNT_TYPES.PREPARER}
              clearPreparerState={this.clearPreparerState}
              onSuccess={this.fetchPreparers}
              officeSetupProgressFlags={this.props.officeSetupProgressFlags}
              onSetupComplete={this.props.onSetupComplete}
              disableAutoFocus={true}
              PaperProps={{ tabIndex: -1 }}
            />
          </Paper>
        </Modal>
        <SimpleDialog
          open={this.state.isDeleteConfirmationOpen}
          onClose={() => {
            this.setPreparerIDToDelete(-1);
            this.toggleModal('isDeleteConfirmationOpen');
          }}
          onConfirm={() => {
            this.deletePreparer(this.state.preparerIDToDelete);
            this.toggleModal('isDeleteConfirmationOpen');
          }}
          dialogTitle={'Delete Preparer?'}
          contentText={'Are you sure you want to delete this preparer?'}
        />
        <Modal
          id="mdlAddSignatureMenuOnScreen"
          aria-labelledby="simple-modal-title-signature-menu-onscreen"
          aria-describedby="simple-modal-description-signature-menu-onscreen"
          open={
            this.state.isSignatureExistModalOpen && this.state.currentPreparerSignature.length > 0
          }
          disableAutoFocus
          disableBackdropClick
        >
          <PreviewSignature
            prepSignature={this.state.currentPreparerSignature}
            handleNewClose={() => this.toggleModal('isSignatureExistModalOpen')}
            signatureRecapture={() => {
              this.toggleModal('isSignatureExistModalOpen');
              this.toggleModal('isPrepRMSModalOpen');
            }}
            signeeType={SIGNEE_TYPE.PREPARER}
          />
        </Modal>
        <SimpleDialog
          open={this.state.isConfirmCancelModalOpen}
          onClose={() => this.toggleModal('isConfirmCancelModalOpen')}
          onConfirm={() => {
            this.toggleModal('isConfirmCancelModalOpen');
            this.getInitialConfigState();
            this.getPrevPage();
          }}
          dialogTitle={'Lose Unsaved Changes?'}
          contentText={'Are you sure you want to undo any pending changes?'}
        />
        <SimpleDialog
          styled
          open={this.state.isActiveRMSModalOpen}
          onClose={() => this.toggleModal('isActiveRMSModalOpen')}
          onConfirm={() => {
            this.toggleModal('isActiveRMSModalOpen');
            this.toggleModal(
              this.state.currentPreparerSignature.length > 0
                ? 'isSignatureExistModalOpen'
                : 'isPrepRMSModalOpen',
            );
          }}
          confirmText="Continue"
          dialogTitle={'WARNING: Pending Remote Signature Request'}
          contentText={
            'There is currently an active remote signature request email that has already been sent to the Preparer.'
          }
          contentTextTwo={'Sending a new request to the Preparer will deactivate the existing one.'}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PreparerSetup));
