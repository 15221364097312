export const styles = {
  "@media screen and (max-width: 700px)": {
    paperStyle: {
      width: "400px",
      height: "350px",
      borderRadius: "4px",
      marginLeft: "2px",
      position: "relative",
      marginTop: "25px",
      marginBottom: "25px"
    },
    paperStyleTwo: {
      width: "400px",
      height: "180px",
      borderRadius: "4px",
      position: "relative",
      marginTop: "25vh",
      margin: "auto"
    },
    styleOne: {
      textAlign: "left",
      paddingTop: "2vh",
      paddingBottom: "1vh",
      fontSize: "13px"
    },
    styledButton: {
      width: "20vw",
      textTransform: "capitalize",
      fontFamily: "Roboto",
      fontSize: "14px",
      paddingBottom: "0.5vh"
    }
  },

  "@media screen and (min-width: 701px)": {
    paperStyle: {
      width: "500px",
      height: "500px",
      borderRadius: "4px",
      position: "relative",
      margin: "auto",
      marginTop: "20vh"
    },
    paperStyleTwo: {
      width: "500px",
      height: "250px",
      borderRadius: "4px",
      margin: "auto",
      position: "relative",
      marginTop: "20vh"
    },
    styledButton: {
      width: "15vw",
      textTransform: "capitalize",
      fontFamily: "Roboto",
      fontSize: "14px",
      paddingBottom: "0.5vh"
    }
  }
};
