import dashboardIcon from '~/images/icons/Dashboard.svg';
import preparersIcon from '~/images/icons/preparersIcon.png';
import accountsIcon from '~/images/icons/accountsIcon.svg';
import taxReturnIcon from '~/images/icons/taxReturnIcon.svg';
import officesIcon from '~/images/icons/officesIcon.svg';
import calendarIcon from '~/images/icons/Calendar.png';
import messageIcon from '~/images/icons/Message.png';
import checksIcon from '~/images/icons/icons8-paycheque.png';
import rfdCalcIcon from '~/images/icons/refund_calculator.png';
import reportingIcon from '~/images/icons/reporting.png';
import serverIcon from '~/images/icons/icons8-server-1.png';
import paymentIcon from '~/images/icons/icons8-request-money.png';
import moveReturnIcon from '~/images/icons/moveReturnIcon.png';
import userLookupIcon from '~/images/icons/user-lookup.png';
import customerReminderIcon from '~/images/icons/cs_reminder.png';
import techloginsIcon from '~/images/icons/tech-logins-icon.png';
import PackageIcon from '~/images/icons/transmit_icon.png';

export const excludedComponents = [
  '/account-settings',
  '/create-new-account',
  '/database',
  '/refund-calculator',
  '/wizard-mode',
];

export const url = ['/dashboard', '/checks/ready-to-print', '/checks/checks-register'];

export const screenWidth = 1024;

export const icons = [
  dashboardIcon,
  preparersIcon,
  accountsIcon,
  officesIcon,
  taxReturnIcon,
  serverIcon,
  PackageIcon,
  calendarIcon,
  messageIcon,
  userLookupIcon,
  customerReminderIcon,
  checksIcon,
  rfdCalcIcon,
  reportingIcon,
  paymentIcon,
  moveReturnIcon,
  techloginsIcon,
];
