// External Imports
import React, { useEffect } from 'react';
import get from 'lodash-es/get';
import {
  Button,
  FormControl,
  Input,
  InputLabel,
  Paper,
  Snackbar,
  withStyles,
} from '@material-ui/core';
// Internal Imports
import { useSetState } from '~/app/Utility/customHooks';
import LogoSelector from '~/app/Pages/Setup/AccountSetup/components/LogoSelector/LogoSelector.jsx';
import { statusOK } from '~/app/webHelpers.js';
import XlinkAPI from '~/app/api/xlinkAPI';
import ErrorHelpers from '~/app/errorHelpers.js';
import { displayTaxPassAppUrl } from '~/app/Utility/taxpassHelper.js';
import {
  ACCESS_LEVEL,
  COMMUNICATIONS_SETUP_PAGES,
  EXTENDED_SETUP_PAGES,
  LOGO_TYPE,
} from '~/app/constants.js';
import defaultQR from '~/images/whiteSquare.png';
// Redux Imports
import { useDispatch, useSelector } from 'react-redux';
import { selectors as loginSelector } from '~/app/redux/loginSetup/selectors';
import { actions } from '~/app/redux/loginSetup/duck';
// Styling Imports
import { styles } from './css/mobileSetupStyles.js';
import './css/mobileSetup.css';

const MobileSetup = props => {
  const { classes } = props;
  const [state, setState] = useSetState({
    disableGenerateID: false,
    mobileID: '',
    mobileQR: '',
    logo: '',
    email: '',
    isSnackbarOpen: false,
  });

  const { hierarchyType, activeAccessLevels, canCobrand, hasPurchasedCobranding } = useSelector(
    state => ({
      hierarchyType:
        state.drilldown.drilldownHistory[state.drilldown.drilldownHistory.length - 1].role,
      activeAccessLevels: loginSelector.getActiveAccessLevels(state),
      canCobrand: state.cobrand.canCobrand,
      hasPurchasedCobranding: state.cobrand.hasPurchasedCobranding,
    }),
  );
  const dispatch = useDispatch();

  useEffect(() => {
    getIntegrationInfo();
  }, []);

  const getIntegrationInfo = async () => {
    try {
      const response = await XlinkAPI.getMobileAppIntegration();
      if (statusOK(response)) {
        setState({
          mobileID: response.data.mobileID,
          mobileQR: response.data.QRCode,
          email: response.data.email,
          logo: response.data.logo,
        });
      }
    } catch (err) {
      ErrorHelpers.handleError(
        'Unable to get TaxPass Site integration',
        ErrorHelpers.createSimpleError(
          'We were unable to get your TaxPass Site integration settings. If this problem persists please contact customer support.',
        ),
      );
    }
  };

  const generateMobileAppID = async () => {
    try {
      const response = await XlinkAPI.generateMobileAppID(state.email);
      if (statusOK(response)) {
        setState({
          mobileID: response.data.mobileID,
          mobileQR: response.data.QRCode,
          isSnackbarOpen: true,
        });
        // save generated info to state for the rest of the app
        dispatch(actions.setMobileAppID(state.mobileID, state.mobileQR));
      }
    } catch (err) {
      ErrorHelpers.handleError(
        'Unable to generate TaxPass Site ID',
        ErrorHelpers.createSimpleError(
          'We were unable to generate your TaxPass Site ID. If this problem persists please contact customer support.',
        ),
      );
    }
  };

  const saveEmail = async () => {
    try {
      const response = await XlinkAPI.updateMobileAppEmail(state.email);
      if (statusOK(response, false)) {
        setState({ isSnackbarOpen: true });
        // save updated info to state for the rest of the app - logo is not needed, cobranding logo is used instead
        dispatch(actions.setMobileAppIntegration(state.mobileID, state.mobileQR, state.email));
      }
    } catch (err) {
      const errMsg = get(
        err.response.data,
        'details',
        'We were unable to save your TaxPass Site email. If this problem persists please contact customer support',
      );
      ErrorHelpers.handleError('Unable to save email', ErrorHelpers.createSimpleError(errMsg));
    }
  };

  const saveLogo = async logo => {
    try {
      const response = await XlinkAPI.updateMobileAppLogo(logo);
      if (statusOK(response, false)) {
        setState({ logo, isSnackbarOpen: true });
      }
    } catch (err) {
      const errMsg = get(
        err.response.data,
        'details',
        'We were unable to save your TaxPass Site email. If this problem persists please contact customer support',
      );
      ErrorHelpers.handleError('Unable to save logo', ErrorHelpers.createSimpleError(errMsg));
    }
  };

  const goToPrevious = () => {
    props.loadSetupComponent(EXTENDED_SETUP_PAGES.PAYMENTS.NAME);
  };

  const goToNext = () => {
    props.loadSetupComponent(
      Object.hasOwn(activeAccessLevels, ACCESS_LEVEL.CREATE_RESTRICTED_FIELDS)
        ? EXTENDED_SETUP_PAGES.RESTRICTED_FIELDS.NAME
        : COMMUNICATIONS_SETUP_PAGES.TEXT.NAME,
    );
  };

  const handleSubmit = e => {
    e.preventDefault();
    saveEmail();
  };

  const displayGenerateMobileAppID = () => {
    return (
      <div
        id="btnGenerateMobileAppID"
        className="small-link-text"
        onClick={() => {
          setState({ disableGenerateID: true });
          generateMobileAppID();
        }}
        disabled={state.disableGenerateID}
      >
        Generate ID
      </div>
    );
  };

  return (
    <div className="setup-page-container">
      <Paper classes={{ root: classes.mobileSetupPageContainer }}>
        <section className="setup-section-container">
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={state.isSnackbarOpen}
            autoHideDuration={2500}
            onClose={() => setState({ isSnackbarOpen: false })}
            id="snkbarconfsaved"
            message={<span id="success-message">TaxPass Site Settings Saved</span>}
          />
          <h2 className="setup-section-heading">TaxPass Site Settings</h2>
          <div className="setup-section-content-container">
            <div className="mobile-block">
              <img
                src={state.mobileQR === '' ? defaultQR : 'data:image/png;base64, ' + state.mobileQR}
                className="qr-code"
              />
              <div className="mobile-info-block verticallyCenter">
                <div className="mobile-app-id">TaxPass ID</div>
                <div className="small-text">
                  {state.mobileID === '' ? 'No TaxPass ID' : state.mobileID}
                </div>
                {state.mobileID === ''
                  ? displayGenerateMobileAppID()
                  : displayTaxPassAppUrl(state.mobileID)}
              </div>
              <div className="mobile-setup-email-container">
                <form className="mobile-setup-email-form" onSubmit={e => handleSubmit(e)}>
                  <FormControl fullWidth>
                    <InputLabel shrink>TaxPass Site Email</InputLabel>
                    <Input
                      id="txtMobileAppEmail"
                      disableUnderline
                      value={state.email}
                      onChange={e => setState({ email: e.target.value })}
                      disabled={props.officeProfile.is_feeder_office}
                    />
                  </FormControl>
                  <Button
                    classes={{ root: classes.mobileSetupEmailFormButton }}
                    id="btnMobileAppEmailSave"
                    color="primary"
                    type="submit"
                    disabled={props.officeProfile.is_feeder_office}
                  >
                    Save
                  </Button>
                </form>
              </div>
              {canCobrand && hasPurchasedCobranding && (
                <div className="mobile-setup-logo-container">
                  <LogoSelector
                    logoType={LOGO_TYPE.MOBILE}
                    hierarchyType={hierarchyType}
                    logo={state.logo}
                    requestUploadLogo={saveLogo}
                    hasCobranding={canCobrand && hasPurchasedCobranding}
                    disabled={props.officeProfile.is_feeder_office}
                  />
                </div>
              )}
            </div>
          </div>
        </section>
        <div className="setup-nav-btns">
          <Button
            id="btnPreviousAudit"
            color="primary"
            onClick={() => {
              goToPrevious();
            }}
          >
            Previous
          </Button>
          <Button
            classes={{ root: classes.auditConfigFormButton }}
            color="primary"
            id="btnNextText"
            onClick={() => goToNext()}
          >
            Next
          </Button>
        </div>
      </Paper>
    </div>
  );
};

export default withStyles(styles)(MobileSetup);
