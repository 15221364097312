// External imports
import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Modal,
  Paper,
  TextField,
  Grid,
  CircularProgress,
  withStyles,
} from '@material-ui/core';
import { pdfjs } from 'react-pdf';
import { isMobile, isMobileOnly, withOrientationChange } from 'react-device-detect';
import { get } from 'lodash';
// Internal imports
import { useSetState } from '~/app/Utility/customHooks.js';
import Spinner from '#/Common/Spinner.jsx';
import AuthAPI from '~/app/api/authAPI.js';
import WebHelpers, { statusOK } from '~/app/webHelpers';
import ErrorHelpers from '~/app/errorHelpers.js';
import RemoteSignPad from './RemoteSignaturePad.jsx';
import RemoteSignPreview from './RemoteSignaturePreview.jsx';
import rotateDevice from '~/images/icons/rotateDevice.png';
import SimpleDialog from '#/Common/SimpleDialog.jsx';
import {
  SPINNER_DIALOGS,
  SIGNEE_CATEGORY_TYPE,
  SIGNEE_TYPE,
  SIGNATURE_CATEGORY,
  ELECTRONIC_DISCLOSURE_CANCEL_MESSAGE,
  ELECTRONIC_DISCLOSURE_TITLE,
  ELECTRONIC_DISCLOSURE,
  PAPER_COPIES,
  SCOPE_OF_CONSENT,
  ERROR_IN_DOCUMENT_CANCEL_MESSAGE,
} from '~/app/constants.js';
import {
  buildTitleLabel,
  formHasErrors,
} from '~/app/Components/RemoteSign/helpers/remoteSignAuthHelpers.js';
// Redux imports
import { useDispatch } from 'react-redux';
import { actions as remoteSignActions } from '~/app/redux/remoteSign/duck';
// Styling imports
import '~/app/Components/RemoteSign/css/remoteSign.css';
import '~/app/Components/RemoteSign/css/remoteSignatureAuth.css';
import { styles } from '~/app/Components/RemoteSign/css/remoteSignatureAuth.js';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const RemoteSignatureAuth = props => {
  const { classes } = props;
  const dispatch = useDispatch();
  const [state, setState] = useSetState({
    form: {
      name: '',
      lastFourPrimary: '',
      dob: '',
      zipCode: '',
    },
    inputErrors: {},
    bankInformation: {},
    isInitialVerify: true,
    isSubmitDisabled: true,
    windowHeight: '',
    windowWidth: '',
    isShowInvalidDateModalOpen: false,
    isOpenInvalidDataModalOpen: false,
    isSignatureModalOpen: false,
    isSignaturePreviewOpen: false,
    pdfData: '',
    validTest: -1,
    signatureValidationID: -1,
    signatureCategory: '',
    signatureType: '',
    signatureEmail: '',
    signatureRequests: '',
    isAuthenticationPageOpen: true,
    isPDFPageOpen: false,
    isCancelPageOpen: false,
    signatureToken: '',
    alreadySigned: -1,
    // Remote signature, person who will be signing
    isRemoteSignAuthTaxpayerSpouse: false,
    isRemoteSignAuthPreparer: false,
    isRemoteSignAuthBusiness: false,
    isRemoteSignAuthERO: false,
    hasIssueLoadingPDF: false,
    isAllDone: false,
    signatureRequestsModified: '',
    isProcessingSignature: false,
    isNeedingToRotate: false,
    isBankAgreementNeeded: false,
    isElectronicDisclosureModalOpen: false,
    cancelMessage: '',
    isElectronicDisclosureOpen: false,
    electronicDisclosureConsent: 0, // 0 and 1
    season: 0,
    remoteSignature: '',
    remoteSignatureString: '',
    isPDFOpen: true,
    isValidSigRequest: false,
    isRMSAuthCodeActive: true,
  });
  const [debouncedState, setDebouncedState] = useState({
    form: {
      name: '',
      lastFourPrimary: '',
      dob: '',
      zipCode: '',
    },
  });
  const signeeType = props.match.params.code.substring(0, 1);
  const verifyCode = props.match.params.code.substring(1);

  useEffect(() => {
    getVersionInfo();

    switch (signeeType) {
      case SIGNATURE_CATEGORY.TAXPAYER:
      case SIGNATURE_CATEGORY.SPOUSE:
        toggleStateBool('isRemoteSignAuthTaxpayerSpouse');
        break;
      case SIGNATURE_CATEGORY.PREPARER:
      case SIGNATURE_CATEGORY.AMENDED_PREPARER:
        toggleStateBool('isRemoteSignAuthPreparer');
        break;
      case SIGNATURE_CATEGORY.OFFICER:
        toggleStateBool('isRemoteSignAuthBusiness');
        break;
      default:
        toggleStateBool('isRemoteSignAuthTaxpayerSpouse');
        break;
    }

    handleResize();
    window.addEventListener('resize', handleResize);

    // check to see if the auth code is active, if not, supply error message
    isRMSAuthCodeActive();
  }, []);

  // Handles setting timeout on form change
  const debounce = useCallback(
    _.debounce(_formVal => {
      setDebouncedState(_formVal);
      // send the server request here
    }, 3000),
    [],
  );

  // When debounce state changes after user stops typing for a set period of time, then verify return.
  useEffect(() => {
    if (state.isInitialVerify) {
      toggleStateBool('isInitialVerify');
    } else {
      setState({
        isSubmitDisabled: formHasErrors(
          state.form,
          handleSetErrors,
          state.isRemoteSignAuthTaxpayerSpouse,
        ),
        isVerifying: false,
      });
    }
  }, [debouncedState]);

  // Verifiies the auth code
  const isRMSAuthCodeActive = async () => {
    try {
      const res = await AuthAPI.IsRMSAuthCodeActive(verifyCode, signeeType);
      if (statusOK(res, false)) {
        setState({ isRMSAuthCodeActive: res?.data || false });
      }
    } catch (err) {
      ErrorHelpers.handleError('Unable to verify the RMS Auth Code: ', err);
    }
  };

  // Adding function that will handle resizing
  const handleResize = () => {
    setState({
      windowHeight: window.innerHeight - 200,
      windowWidth: window.innerWidth * 0.59,
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    // Calling API to check if data entered on screen matches the data we have saved on the remote signatures table
    getDataMatches(state.form.dob);
  };

  const getDataMatches = async dbDate => {
    // If the information is correct, load the signature pad component, if not show modal stating that the information provided does not match our records
    const name = state.form.name;
    const lastFourPrimary = state.form.lastFourPrimary;
    const bankInformation = {};

    if (state.isRemoteSignAuthTaxpayerSpouse) {
      try {
        const res = await AuthAPI.CheckRemoteSignAuthData(
          name,
          dbDate,
          lastFourPrimary,
          state.form.zipCode,
          verifyCode,
          parseInt(signeeType),
        );

        if (statusOK(res)) {
          const data = res.data;
          if (data.pdfData === '') {
            // We have a blank pdf, so show error message
            toggleStateBool('isAuthenticationPageOpen', 'isOpenInvalidDataModalOpen');
          } else {
            try {
              const res = await AuthAPI.GetSignatureBankInfo(data.sigDocID, data.season);

              statusOK(res) && Object.assign(bankInformation, res.data);
              // Set Signature needed mapping
              dispatch(remoteSignActions.signaturesNeededSet(data?.signatureRequests || ''));

              setState({
                pdfData: data.pdfData,
                validTest: data.validLoginSignDataEntered,
                signatureValidationID: data.sigValidationID,
                sigDocID: data.sigDocID,
                signatureCategory: data.signatureCategory,
                signatureType: data.signatureCategory,
                signatureEmail: data.signatureEmail,
                signatureRequests: data.signatureRequests,
                isAuthenticationPageOpen: false,
                isPDFPageOpen: true,
                signatureToken: data.signatureToken,
                alreadySigned: data.alreadySigned,
                // Remove values
                form: {
                  name: '',
                  lastFourPrimary: '',
                  dob: '',
                  zipCode: '',
                },
                bankInformation: bankInformation,
                season: data.season,
                isValidSigRequest: data.validSigRequest,
              });
            } catch (error) {
              ErrorHelpers.handleError('Unable to retrieve signature information', error);
            }
          }
        }
      } catch (error) {
        ErrorHelpers.handleError(
          'Invalid Remote Signature Authentication Data Entered for Taxpayer/Spouse',
          error,
        );
      }
    }
    if (
      state.isRemoteSignAuthPreparer ||
      state.isRemoteSignAuthERO ||
      state.isRemoteSignAuthBusiness
    ) {
      try {
        const res = await AuthAPI.CheckRemoteSignAuthDataPrepEROBusiness(
          name,
          lastFourPrimary,
          verifyCode,
          parseInt(signeeType),
        );
        if (statusOK(res)) {
          const data = res.data;
          if (data.pdfData === '') {
            // We have a blank pdf, so show error message
            toggleStateBool('isAuthenticationPageOpen', 'isOpenInvalidDataModalOpen');
          } else {
            try {
              // This fetches bank disclaimers from a table that is only updated up to season 2021 - business returns are season 2024 and greater
              if (signeeType !== '7') {
                const res = await AuthAPI.GetSignatureBankInfo(data.sigDocID, data.season);
                statusOK(res) && Object.assign(bankInformation, res.data);
              }

              setState({
                pdfData: data.pdfData,
                validTest: data.validLoginSignDataEntered,
                signatureValidationID: data.sigValidationID,
                sigDocID: data.sigDocID,
                signatureCategory: data.signatureCategory,
                signatureEmail: data.signatureEmail,
                signatureRequests: data.signatureRequests,
                isAuthenticationPageOpen: false,
                isPDFPageOpen: true,
                signatureToken: data.signatureToken,
                alreadySigned: data.alreadySigned,
                signatureType: data.signatureCategory,
                // Remove values
                form: {
                  name: '',
                  lastFourPrimary: '',
                  dob: '',
                  zipCode: '',
                },
                bankInformation: bankInformation,
                season: data.season,
                isValidSigRequest: data.validSigRequest,
              });
            } catch (error) {
              ErrorHelpers.handleError('Unable to retrieve signature information', error);
            }
          }
        }
      } catch (error) {
        ErrorHelpers.handleError(
          'Invalid Remote Signature Authentication Data Entered for Preparer/ERO/Business',
          error,
        );
      }
    }
  };

  const handleCancel = type => {
    let cancelMessage = '';

    if (type === 'Electronic Disclosure') {
      cancelMessage = ELECTRONIC_DISCLOSURE_CANCEL_MESSAGE;
    } else if (type === 'Document Error') {
      cancelMessage = ERROR_IN_DOCUMENT_CANCEL_MESSAGE;
    }
    // Show message when user clicks on cancel button
    setState({
      cancelMessage: cancelMessage,
    });
    toggleStateBool('isCancelPageOpen', 'isPDFPageOpen');
  };

  const getVersionInfo = async () => {
    try {
      const res = await WebHelpers.getXlinkCloudVersion();
      if (statusOK(res, false)) {
        setState({
          xlinkapiver: res,
        });
      }
    } catch (err) {
      ErrorHelpers.handleError('Error fetching xlinkcloud version', err);
    }
  };

  const postSignature = async () => {
    // Posting signature to db
    const { electronicDisclosureConsent, sigDocID } = state;

    toggleStateBool('isProcessingSignature');

    try {
      const res = await AuthAPI.PostSignedSignatureData(
        sigDocID,
        state.signatureValidationID,
        state.signatureCategory,
        state.signatureType,
        state.remoteSignatureString,
        state.signatureToken,
        electronicDisclosureConsent,
        state.season,
      );

      if (statusOK(res, false) && electronicDisclosureConsent === 1) {
        getRemoteSignatureCount();
      }
    } catch (error) {
      ErrorHelpers.handleError('Failed to Post Remote Signature Data', error);
    }
    setState({ isProcessingSignature: false });
  };

  // getRemoteSignatureCount after posting the signature
  const getRemoteSignatureCount = () => {
    let signatureRequestsCopy = state.signatureRequests;

    if (state.signatureRequestsModified.length > 0) {
      signatureRequestsCopy = state.signatureRequestsModified;
    }

    // TAXPAYER CATEGORY
    if (state.signatureCategory === SIGNEE_CATEGORY_TYPE.TAXPAYER) {
      if (signatureRequestsCopy.includes(SIGNEE_TYPE.TAXPAYER)) {
        signatureRequestsCopy = signatureRequestsCopy.replace(SIGNEE_TYPE.TAXPAYER, '');
      }

      if (signatureRequestsCopy.includes(SIGNEE_TYPE.TAXPAYER_INITIAL)) {
        signatureRequestsCopy = signatureRequestsCopy.replace(SIGNEE_TYPE.TAXPAYER_INITIAL, '');
        setState({
          isSignatureModalOpen: true,
          signatureType: SIGNEE_CATEGORY_TYPE.TAXPAYER_INITIAL,
        });
      } else if (signatureRequestsCopy.includes(SIGNEE_TYPE.TAXPAYER_SECOND_SIGNATURE)) {
        signatureRequestsCopy = signatureRequestsCopy.replace(
          SIGNEE_TYPE.TAXPAYER_SECOND_SIGNATURE,
          '',
        );
        setState({
          isSignatureModalOpen: true,
          signatureType: SIGNEE_CATEGORY_TYPE.TAXPAYER_SECOND_SIGNATURE,
        });
      } else {
        toggleStateBool('isAllDone', 'isPDFPageOpen');
      }
    }

    // SPOUSE CATEGORY
    if (state.signatureCategory === SIGNEE_CATEGORY_TYPE.SPOUSE) {
      if (signatureRequestsCopy.includes(SIGNEE_TYPE.SPOUSE)) {
        signatureRequestsCopy = signatureRequestsCopy.replace(SIGNEE_TYPE.SPOUSE, '');
      }

      if (signatureRequestsCopy.includes(SIGNEE_TYPE.SPOUSE_INITIAL)) {
        signatureRequestsCopy = signatureRequestsCopy.replace(SIGNEE_TYPE.SPOUSE_INITIAL, '');
        setState({
          isSignatureModalOpen: true,
          signatureType: SIGNEE_CATEGORY_TYPE.SPOUSE_INITIAL,
        });
      } else if (signatureRequestsCopy.includes(SIGNEE_TYPE.SPOUSE_SECOND_SIGNATURE)) {
        signatureRequestsCopy = signatureRequestsCopy.replace(
          SIGNEE_TYPE.SPOUSE_SECOND_SIGNATURE,
          '',
        );
        setState({
          isSignatureModalOpen: true,
          signatureType: SIGNEE_CATEGORY_TYPE.SPOUSE_SECOND_SIGNATURE,
        });
      } else {
        toggleStateBool('isAllDone', 'isPDFPageOpen');
      }
    }

    // ERO/PREP/BUSINESS CATEGORY
    if (
      [
        SIGNEE_CATEGORY_TYPE.PREPARER,
        SIGNEE_CATEGORY_TYPE.ERO,
        SIGNEE_CATEGORY_TYPE.OFFICER,
      ].includes(state.signatureCategory)
    ) {
      toggleStateBool('isAllDone', 'isPDFPageOpen');
    }

    // SET REQ SIGNATURES
    setState({
      signatureRequestsModified: signatureRequestsCopy,
    });
  };

  const handleSignReturn = async () => {
    state.bankInformation.documentName?.includes('BANK') &&
      toggleStateBool('isBankAgreementNeeded');

    try {
      await AuthAPI.PostCodeRemoteSign(
        state.sigDocID,
        state.signatureType,
        state.signatureEmail,
        state.signatureValidationID,
      );
    } catch (error) {
      ErrorHelpers.handleError('Failed to Post Remote Signature Code', error);
    }

    if (isMobileOnly && props.isPortrait) {
      toggleStateBool('isNeedingToRotate');
    }

    toggleStateBool('isSignatureModalOpen');
  };

  const toggleStateBool = (nameOne, nameTwo = '') => {
    if (nameTwo === '') {
      setState({
        [nameOne]: !state[nameOne],
      });
    } else {
      setState({
        [nameOne]: !state[nameOne],
        [nameTwo]: !state[nameTwo],
      });
    }
  };

  /** Handles input change */
  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;

    // inputProps does not work with date pickers
    if (name === 'dob' && value.length > 10) return;

    setState({
      form: {
        ...state.form,
        [name]: value.toUpperCase(),
      },
      isSubmitDisabled: true, // require verify if form was completed then modified.
      isVerifying: true,
    });
    debounce({
      form: {
        ...state.form,
        [name]: value.toUpperCase(),
      },
    });

    // If an input does have an error, we want to reset the error and give the user a chance to fix it
    const tempErrors = state.inputErrors;
    if (tempErrors[name]) {
      delete tempErrors[name];
      handleSetErrors(tempErrors);
    }
  };

  const handleSetErrors = errors => {
    setState({ inputErrors: errors }); // set any errors to the local state
  };

  // uncomment when we get the electronic disclosure
  // const getElectronicDisclosure = () => {
  //   return (
  //     <span>
  //       Please read the{' '}
  //       <span
  //         className="remote-signature-auth-electronic-signature-disclosure"
  //         onClick={() => setState({ isElectronicDisclosureOpen: true })}
  //       >
  //         Electronic Signature Disclosure
  //       </span>
  //     </span>
  //   );
  // };

  const finalPdf = 'data:application/pdf;base64,' + state.pdfData;
  const namePlaceholder = signeeType === '7' ? 'Smith Co...' : 'Smith...';
  const nameInputLabel =
    (state.isRemoteSignAuthTaxpayerSpouse
      ? 'Last '
      : state.isRemoteSignAuthBusiness
      ? 'Business '
      : '') + 'Name';
  const isMobileStyle = state.windowWidth <= 500 && state.windowHeight < 824;
  const lastFourNumLabel =
    'Last 4 Digits of ' +
    (state.isRemoteSignAuthTaxpayerSpouse
      ? 'SSN'
      : state.isRemoteSignAuthPreparer
      ? 'SSN/PTIN' // This squishes the label, we need to increase the grid for this field
      : state.isRemoteSignAuthERO || state.isRemoteSignAuthBusiness
      ? 'ERO'
      : null);

  if (state.isNeedingToRotate && props.isPortrait) {
    return (
      <div className="remote-sign-rotate-device-block">
        <h5 className="remote-sign-rotate-device-content">
          Please Rotate Your Device to Proceed Signing the Document
        </h5>
        <img className="remote-sign-rotate-img" src={rotateDevice} />
      </div>
    );
  } else {
    return (
      <>
        {state.isAuthenticationPageOpen && state.isRMSAuthCodeActive ? (
          <div className="remoteAuthenticationPage">
            <Paper
              classes={{ root: isMobileStyle && classes.paper }}
              className="remote-sign-container"
            >
              <div className="remote-sign-log-in">{buildTitleLabel(signeeType)}</div>
              <Grid container spacing={32}>
                <Grid item xs={12}>
                  <hr className="remote-sign-horizontal-line" />
                </Grid>

                {/* name is included for each signee type */}
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      id="txtNameSignLogin"
                      name="name"
                      value={state.form.name || ''}
                      label={nameInputLabel}
                      placeholder={namePlaceholder}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{ maxLength: 100 }}
                      variant="outlined"
                      autoComplete="off"
                      helperText={state.inputErrors?.name}
                      error={state.inputErrors?.name !== undefined}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={state.isRemoteSignAuthPreparer ? 12 : 6}>
                  <FormControl>
                    <TextField
                      id="nflastFourNumSignLogin"
                      name="lastFourPrimary"
                      value={state.form.lastFourPrimary || ''}
                      label={lastFourNumLabel}
                      placeholder="####"
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{ maxLength: 4 }}
                      variant="outlined"
                      autoComplete="off"
                      helperText={state.inputErrors?.lastFourPrimary}
                      error={state.inputErrors?.lastFourPrimary !== undefined}
                    />
                  </FormControl>
                </Grid>

                {state.isRemoteSignAuthTaxpayerSpouse && (
                  <>
                    <Grid item xs={6}>
                      <FormControl>
                        <TextField
                          id="nfZipCodeSignLogin"
                          name="zipCode"
                          value={state.form.zipCode || ''}
                          label="Zip Code"
                          placeholder="#####"
                          onChange={handleChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{ maxLength: 14 }}
                          variant="outlined"
                          autoComplete="off"
                          helperText={state.inputErrors?.zipCode}
                          error={state.inputErrors?.zipCode !== undefined}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl>
                        <TextField
                          id="nfDOBSignLogin"
                          name="dob"
                          value={state.form.dob || ''}
                          label="DOB"
                          onChange={handleChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                          autoComplete="off"
                          type="date"
                          helperText={state.inputErrors?.dob}
                          error={state.inputErrors?.dob !== undefined}
                        />
                      </FormControl>
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <Button
                      id="btnVerifySignLogin"
                      onClick={handleSubmit}
                      variant="contained"
                      type="submit"
                      color="primary"
                      disabled={state.isSubmitDisabled}
                    >
                      {state.isVerifying ? (
                        <>
                          <CircularProgress
                            size="1rem"
                            color="inherit"
                            className={classes.smallSpinner}
                          />
                          Verifying...
                        </>
                      ) : (
                        'Verify'
                      )}
                    </Button>
                  </FormControl>
                </Grid>
              </Grid>
            </Paper>
          </div>
        ) : null}

        {state.isPDFPageOpen && state.isValidSigRequest && state.alreadySigned === 0 && (
          <Paper className="remote-sign-download-doc-container">
            <SimpleDialog
              open={state.isElectronicDisclosureModalOpen}
              onConfirm={() => {
                setState({
                  electronicDisclosureConsent: 1,
                });
                toggleStateBool('isPDFOpen', 'isElectronicDisclosureModalOpen');
                handleSignReturn();
              }}
              onClose={() => {
                setState({
                  electronicDisclosureConsent: 0,
                });
                toggleStateBool('isElectronicDisclosureModalOpen');
                handleCancel('Electronic Disclosure');
              }}
              confirmText="Yes"
              cancelText="No"
              styled={true}
              dialogTitle="Electronic Disclosure"
              // contentText={getElectronicDisclosure()} // Wont display until we get an electronic disclosure from legal
              contentText="Do you consent to signing this document electronically?"
            />

            <Dialog
              open={state.isElectronicDisclosureOpen}
              aria-labelledby="electronic-disclosure-title"
              aria-describedby="electronic-disclosure-desc"
              disableBackdropClick={true}
            >
              <DialogTitle id="electronic-disclosure-title">
                ELECTRONIC SIGNATURE DISCLOSURE
              </DialogTitle>
              <DialogContent id="electronic-disclosure-desc">
                <p className="remote-signature-auth-bold">{ELECTRONIC_DISCLOSURE_TITLE}</p>
                <p className="remote-signature-auth-italic">{ELECTRONIC_DISCLOSURE}</p>
                <p className="remote-signature-auth-bold">Paper Copies</p>
                <p className="textAlignJustify">{PAPER_COPIES}</p>
                <p className="remote-signature-auth-bold">Scope of Consent</p>
                <p className="textAlignJustify">{SCOPE_OF_CONSENT}</p>
              </DialogContent>
              <DialogActions>
                <Button
                  id="btnHideDisclosure"
                  className="remote-signature-auth-disclosure-btn"
                  onClick={() => toggleStateBool('isElectronicDisclosureOpen')}
                >
                  Done
                </Button>
              </DialogActions>
            </Dialog>

            <div>
              <span className="remote-signature-dowload-container">
                <a href={finalPdf} download="document.pdf">
                  <Button variant="contained" color="primary">
                    Download Document to Device
                  </Button>
                </a>
              </span>
              <h6>Document Preview</h6>
            </div>
            <div className="remote-sign-pdf-return-view-container">
              {isMobile && state.isPDFOpen ? (
                <div className="remote-sign-document-block remote-sign-document-block-center">
                  <p>
                    {`Previewing document is ONLY supported on a desktop or laptop browser. To view
                      the document on a mobile device, please use Google Chrome and click the 'Download Document To Device'
                      button above.`}
                  </p>
                </div>
              ) : (
                <object
                  id="pdf"
                  className="remote-sign-pdf-return-view"
                  data={finalPdf}
                  type="application/pdf"
                />
              )}
            </div>
            <div className="remote-sign-download-doc-form-btn-container">
              <p>Please verify that the document is accurate to the best of your knowledge.</p>
              <Button
                onClick={() => handleCancel('Document Error')}
                style={{ display: 'inline-block', marginRight: '10px' }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => toggleStateBool('isElectronicDisclosureModalOpen')}
                variant="contained"
                color="primary"
                style={{ display: 'inline-block' }}
              >
                Sign
              </Button>
            </div>
          </Paper>
        )}

        {/* Modals and Dialogs */}
        {!state.isValidSigRequest &&
          !state.isOpenInvalidDataModalOpen &&
          state.alreadySigned === 0 && (
            <Paper classes={{ root: classes.msgContent }}>
              <p>
                Our records indicate that this is no longer a valid remote signature request. Please
                contact your tax preparer to have them send you a new remote signature request.
                <br />
                Please close this window.
              </p>
            </Paper>
          )}
        {state.alreadySigned === 1 && (
          <Paper classes={{ root: classes.msgContent }}>
            <p>
              Our records indicate that you have already signed your return.
              <br />
              Please close this window.
            </p>
          </Paper>
        )}
        {state.isCancelPageOpen && (
          <Paper classes={{ root: classes.msgContent }}>
            <p>
              {state.cancelMessage}
              <br />
              Please close this window.
            </p>
          </Paper>
        )}
        {state.isAllDone && (
          <Paper classes={{ root: classes.msgContent }}>
            <p>
              Thank you for submitting your signature. The remote signature process has been
              completed.
              <br />
              Please close this window.
            </p>
          </Paper>
        )}
        {state.hasIssueLoadingPDF && (
          <Paper classes={{ root: classes.msgContent }}>
            <p>
              There was an issue loading your document. Download to device or try again by
              refreshing the page.
            </p>
          </Paper>
        )}
        {state.isSignatureModalOpen && (
          <Modal
            id="mdlRemoteSignatureModal"
            aria-labelledby="simple-modal-remote-signature"
            aria-describedby="simple-modal-remote-signature-description"
            open={state.isSignatureModalOpen}
            onClose={() => toggleStateBool('isSignatureModalOpen')}
          >
            <DialogContent classes={{ root: classes.dialogContent }}>
              <RemoteSignPad
                handlePreviewSignature={() => {
                  toggleStateBool('isSignatureModalOpen', 'isSignaturePreviewOpen');
                }}
                currentSig={state.signatureType}
                showBankAgreement={state.isBankAgreementNeeded}
                bankDisclaimer={get(state, 'bankInformation.bankDisclaimer', '')}
                isNeedingToRotate={() => toggleStateBool('isNeedingToRotate')}
                setRemoteSignature={remoteSignature =>
                  setState({ remoteSignature: remoteSignature })
                }
                setRemoteSignatureString={remoteSignatureString => {
                  setState({ remoteSignatureString: remoteSignatureString });
                }}
              />
            </DialogContent>
          </Modal>
        )}
        {state.isSignaturePreviewOpen && (
          <Modal
            id="mdlRemoteSignaturePreviewModal"
            aria-labelledby="simple-modal-remote-siganture-preview"
            aria-describedby="simple-modal-remote-signature-preview"
            open={state.isSignaturePreviewOpen}
            onClose={() => toggleStateBool('isSignaturePreviewOpen')}
          >
            <DialogContent classes={{ root: classes.dialogContent }}>
              <RemoteSignPreview
                handleCloseSignatureModal={() => toggleStateBool('isSignaturePreviewOpen')}
                handleRecapture={() => {
                  toggleStateBool('isSignatureModalOpen', 'isSignaturePreviewOpen');
                }}
                postSignature={postSignature}
                isNeedingToRotate={() => toggleStateBool('isNeedingToRotate')}
                remoteSignature={state.remoteSignature}
              />
            </DialogContent>
          </Modal>
        )}
        <SimpleDialog
          open={state.isShowInvalidDateModalOpen}
          onConfirm={() => toggleStateBool('isShowInvalidDateModalOpen')}
          dialogTitle="INVALID DATE OF BIRTH"
          contentText="Please enter a valid date of birth."
          styled
        />
        <SimpleDialog
          open={state.isOpenInvalidDataModalOpen}
          onConfirm={() =>
            toggleStateBool('isOpenInvalidDataModalOpen', 'isAuthenticationPageOpen')
          }
          dialogTitle="PLEASE VERIFY YOUR INFORMATION"
          contentText="The information provided does not match our records. If you believe the information
          provided is correct, please contact your tax preparer."
          styled
        />
        <SimpleDialog
          open={!state.isRMSAuthCodeActive}
          onConfirm={() => toggleStateBool('isRMSAuthCodeActive')}
          dialogTitle="WARNING: Authorization Code is inactive"
          contentText="Please contact your Tax Preparer to have them re-send the request."
          styled
        />

        {state.isProcessingSignature && (
          <div className="remote-sign-spinner-container">
            <Spinner
              size={125}
              loadingText={SPINNER_DIALOGS.REMOTE_SIGNATURE}
              lockActions={true}
              textColor="white"
              bgColor="grey"
            />
          </div>
        )}
      </>
    );
  }
};

export default withStyles(styles)(withOrientationChange(RemoteSignatureAuth));
