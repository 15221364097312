// External imports
import React, { useRef, useEffect } from 'react';
import {
  AppBar,
  Button,
  Divider,
  Grid,
  Paper,
  Toolbar,
  Typography,
  withStyles,
} from '@material-ui/core';
import SignaturePad from 'react-signature-pad-wrapper';
import { isMobile, isMobileOnly, withOrientationChange } from 'react-device-detect';
import parse from 'html-react-parser';
// Internal imports
import {
  toBMPDataURL,
  canvasSize,
  canvasOptions,
  resizeCanvas,
  isCanvasScaled,
  cleanUpCanvas,
  scaleCanvasPixelRatio,
} from '~/app/bmpConversionHelpers.js';
import { buildToolbarTitle } from '~/app/Components/RemoteSign/helpers/remoteSignAuthHelpers.js';
// Styling imports
import '~/app/Components/RemoteSign/css/remoteSign.css';
import '~/app/Components/RemoteSign/css/remoteSignaturePad.css';
import { styles } from '~/app/Components/RemoteSign/css/remoteSignaturePad.js';

const RemoteSignaturePad = props => {
  const { classes } = props;
  const isMounted = useRef(false);
  const signaturePad = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    if (isMobile) {
      if (isMobileOnly) {
        window.addEventListener('deviceorientation', props.isNeedingToRotate());
      }
    }

    if (signaturePad.current && signaturePad.current.canvas != null) {
      scaleCanvasPixelRatio(signaturePad.current.canvas);
    }

    return () => {
      isMounted.current = false;
      if (isMobile) {
        window.removeEventListener('deviceorientation', props.isNeedingToRotate());
      }

      if (isCanvasScaled) {
        cleanUpCanvas();
      }
    };
  }, []);

  // Handle Clear Signature
  const clearSignature = e => {
    e.preventDefault();
    signaturePad.current.clear();
  };

  // Handle Preview Signature Button
  const previewSignature = e => {
    e.preventDefault();
    // Check if user signed or if the signature pad is empty
    const hasSignature = signaturePad.current.isEmpty();
    // Only go to preview page if the user entered a signature on the signature pad
    if (!hasSignature) {
      const remoteSignatureData = isCanvasScaled
        ? resizeCanvas(signaturePad.current.canvas).toDataURL()
        : signaturePad.current.toDataURL(); // Getting signature data (base 64)
      const signaturePadCanvas = isCanvasScaled
        ? resizeCanvas(signaturePad.current.canvas)
        : signaturePad.current.canvas;
      const padType = 'screen';
      let sigString = toBMPDataURL(signaturePadCanvas, padType);

      sigString = sigString.replace('data:image/bmp;base64,', ''); // strip url src info

      props.setRemoteSignature(remoteSignatureData);
      props.setRemoteSignatureString(sigString);

      signaturePad.current.clear();
      // Open the preview page
      props.handlePreviewSignature();
    } else {
      // Show alert when no logo available to upload
      alert(
        "No signature available to preview. Please sign and then click on the 'Preview' button",
      );
    }
  };

  const toolbarTitle = buildToolbarTitle(props.currentSig);

  return (
    <div className={`${!isMobile && 'container-fluid'} remote-sign-on-screen-container`}>
      <Paper
        className={
          props.showBankAgreement
            ? 'remote-signature-pad-preview-content'
            : 'remote-sign-on-screen-capture-content'
        }
      >
        <AppBar position="static">
          <Toolbar classes={{ root: classes.toolbarColor }}>
            <span className="remote-signature-pad-toolbar-title">{toolbarTitle}</span>
          </Toolbar>
        </AppBar>
        <form className="remote-sign-capture-signature-form">
          {props.showBankAgreement && (
            <div>
              <Grid
                item
                xs={9}
                classes={{ item: isMobileOnly ? classes.gridMobile : classes.grid }}
              >
                <div className="remote-signature-pad-text-container">
                  <Typography
                    classes={{ root: classes.agreementTitle }}
                    variant="title"
                    gutterBottom
                  >
                    Agreement:
                  </Typography>
                  <Typography classes={{ root: classes.agreement }} variant="body1">
                    {parse(props.bankDisclaimer)}
                  </Typography>
                </div>
              </Grid>
              {!isMobileOnly && (
                <Divider
                  classes={{
                    root: classes.divider,
                  }}
                  variant="fullWidth"
                  light={true}
                />
              )}
            </div>
          )}
          {!props.showBankAgreement && (
            <div>
              <p className="remote-signature-pad-paragraph">Please sign below</p>
            </div>
          )}
          <div
            className={`remote-sign-capture-signature-pad-container ${
              props.showBankAgreement
                ? 'remote-sign-capture-signature-pad-container-disclaimer'
                : ''
            }`}
          >
            <SignaturePad
              width={canvasSize.width}
              height={canvasSize.height}
              ref={ref => (signaturePad.current = ref)}
              options={{ ...canvasOptions }}
            />
          </div>
          <div
            className={`remote-signature-pad-button-block ${
              props.showBankAgreement ? 'remote-signature-pad-button-block-disclaimer' : ''
            }`}
          >
            <Button
              id="btnClearSignatureModal"
              onClick={clearSignature}
              classes={{ root: classes.clearButton }}
            >
              Clear Signature
            </Button>
            <Button
              id="btnPreviewSignatueModal"
              classes={{ root: classes.previewButton }}
              onClick={previewSignature}
            >
              Preview signature
            </Button>
          </div>
          <br />
        </form>
      </Paper>
    </div>
  );
};

export default withOrientationChange(withStyles(styles)(RemoteSignaturePad));
