// External imports
import React from 'react';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Input,
  Paper,
  Snackbar,
} from '@material-ui/core';
// Internal imports
import ErrorHelpers from '~/app/errorHelpers.js';
import { useSetState } from '~/app/Utility/customHooks.js';
import SimpleDialog from '#/Common/SimpleDialog.jsx';
import Spinner from '#/Common/Spinner.jsx';
import WebHelpers from '~/app/webHelpers.js';
import XlinkAPI from '~/app/api/xlinkAPI';
import { SPINNER_DIALOGS, EXTENDED_SETUP_PAGES } from '~/app/constants.js';
// Redux imports
import { useSelector, useDispatch } from 'react-redux';
import { actions as defaultsActions } from '~/app/redux/setupPages/defaultsPage/duck';

const defaultSetup = props => {
  const dispatch = useDispatch();
  const {
    fetching,
    finishedFetching,
    error,
    jumpCursor,
    jumpCursorID,
    enableReturnOwnerLocking,
    enableReturnOwnerLockingID,
    autoAddFillingOpt,
    autoAddFillingOptID,
    enableDoubleEntry,
    enableDoubleEntryID,
    lockFinishedPaperReturns,
    lockFinishedPaperReturnsID,
    blindEntryNewReturn,
    blindEntryNewReturnID,
    defaultStateRAC,
    defaultStateRACID,
    autoSaveAfterMins,
    autoSaveAfterMinsID,
    autoSaveEnabled,
    autoSaveEnabledID,
    silentlySaveReturnOnClose,
    silentlySaveReturnOnCloseID,
    requireAutoAdd1040Sch123,
    requireAutoAdd1040Sch123ID,
    disableRTLite,
    maskSSNEIN,
    maskSSNEINID,
    excludeForms,
    excludeFormsID,
    excludeDocs,
    excludeDocsID,
    includePreparerInfo,
    includePreparerInfoID,
    includeBankInfo,
    includeBankInfoID,
    // Return Level
    requireThirdPartyDesignee,
    requireThirdPartyDesigneeID,
    disableEIC,
    disableEICID,
    requireReferral,
    requireReferralID,
    requireCellPhoneCarrierInfo,
    requireCellPhoneCarrierInfoID,
    requireEFPMTBankInfoPresent,
    requireEFPMTBankInfoPresentID,
    defaultPreparerInfo8879,
    defaultPreparerInfo8879ID,
    // Verification Level
    dspWarningTypeErrors,
    dspWarningTypeErrorsID,
    dspOverridesErrors,
    dspOverridesErrorsID,
    dspNoVerifyOnUnattachedForms,
    dspNoVerifyOnUnattachedFormsID,
    // PDF print warning message
    printPDFWarningMessage,
    printPDFWarningMessageID,
  } = useSelector(state => ({
    fetching: state.defaultsPageState.fetching,
    finishedFetching: state.defaultsPageState.finishedFetching,
    error: state.defaultsPageState.error,
    jumpCursor: state.defaultsPageState.jumpCursor,
    jumpCursorID: state.defaultsPageState.jumpCursorID,
    enableReturnOwnerLocking: state.defaultsPageState.enableReturnOwnerLocking,
    enableReturnOwnerLockingID: state.defaultsPageState.enableReturnOwnerLockingID,
    autoAddFillingOpt: state.defaultsPageState.autoAddFillingOpt,
    autoAddFillingOptID: state.defaultsPageState.autoAddFillingOptID,
    enableDoubleEntry: state.defaultsPageState.enableDoubleEntry,
    enableDoubleEntryID: state.defaultsPageState.enableDoubleEntryID,
    lockFinishedPaperReturns: state.defaultsPageState.lockFinishedPaperReturns,
    lockFinishedPaperReturnsID: state.defaultsPageState.lockFinishedPaperReturnsID,
    blindEntryNewReturn: state.defaultsPageState.blindEntryNewReturn,
    blindEntryNewReturnID: state.defaultsPageState.blindEntryNewReturnID,
    defaultStateRAC: state.defaultsPageState.defaultStateRAC,
    defaultStateRACID: state.defaultsPageState.defaultStateRACID,
    autoSaveAfterMins: state.defaultsPageState.autoSaveAfterMins,
    autoSaveAfterMinsID: state.defaultsPageState.autoSaveAfterMinsID,
    autoSaveEnabled: state.defaultsPageState.autoSaveEnabled,
    autoSaveEnabledID: state.defaultsPageState.autoSaveEnabledID,
    silentlySaveReturnOnClose: state.defaultsPageState.silentlySaveReturnOnClose,
    silentlySaveReturnOnCloseID: state.defaultsPageState.silentlySaveReturnOnCloseID,
    requireAutoAdd1040Sch123: state.defaultsPageState.requireAutoAdd1040Sch123,
    requireAutoAdd1040Sch123ID: state.defaultsPageState.requireAutoAdd1040Sch123ID,
    disableRTLite: true, // TODO: Not selectable in xlink18 => Check if we should allow for checkbox to change true/false?
    maskSSNEIN: state.defaultsPageState.maskSSNEIN,
    maskSSNEINID: state.defaultsPageState.maskSSNEINID,
    excludeForms: state.defaultsPageState.excludeForms,
    excludeFormsID: state.defaultsPageState.excludeFormsID,
    excludeDocs: state.defaultsPageState.excludeDocs,
    excludeDocsID: state.defaultsPageState.excludeDocsID,
    includePreparerInfo: state.defaultsPageState.includePreparerInfo,
    includePreparerInfoID: state.defaultsPageState.includePreparerInfoID,
    includeBankInfo: state.defaultsPageState.includeBankInfo,
    includeBankInfoID: state.defaultsPageState.includeBankInfoID,
    // Return Level
    requireThirdPartyDesignee: state.defaultsPageState.requireThirdPartyDesignee,
    requireThirdPartyDesigneeID: state.defaultsPageState.requireThirdPartyDesigneeID,
    disableEIC: state.defaultsPageState.disableEIC,
    disableEICID: state.defaultsPageState.disableEICID,
    requireReferral: state.defaultsPageState.requireReferral,
    requireReferralID: state.defaultsPageState.requireReferralID,
    requireCellPhoneCarrierInfo: state.defaultsPageState.requireCellPhoneCarrierInfo,
    requireCellPhoneCarrierInfoID: state.defaultsPageState.requireCellPhoneCarrierInfoID,
    requireEFPMTBankInfoPresent: state.defaultsPageState.requireEFPMTBankInfoPresent,
    requireEFPMTBankInfoPresentID: state.defaultsPageState.requireEFPMTBankInfoPresentID,
    defaultPreparerInfo8879: state.defaultsPageState.defaultPreparerInfo8879,
    defaultPreparerInfo8879ID: state.defaultsPageState.defaultPreparerInfo8879ID,
    // Verification Level
    dspWarningTypeErrors: state.defaultsPageState.dspWarningTypeErrors,
    dspWarningTypeErrorsID: state.defaultsPageState.dspWarningTypeErrorsID,
    dspOverridesErrors: state.defaultsPageState.dspOverridesErrors,
    dspOverridesErrorsID: state.defaultsPageState.dspOverridesErrorsID,
    dspNoVerifyOnUnattachedForms: state.defaultsPageState.dspNoVerifyOnUnattachedForms,
    dspNoVerifyOnUnattachedFormsID: state.defaultsPageState.dspNoVerifyOnUnattachedFormsID,
    // PDF print warning message
    printPDFWarningMessage: state.defaultsPageState.printPDFWarningMessage,
    printPDFWarningMessageID: state.defaultsPageState.printPDFWarningMessageID,
  }));
  const [state, setState] = useSetState({
    confirmCancel: false,
    gotoPrev: false,
    season: WebHelpers.getJWTPayload().season,
  });

  const submitDefaultData = async gotoNext => {
    // Application Level
    // First Column
    const subJumpCursor = {
      configID: jumpCursorID,
      configValue: jumpCursor,
    };
    const subEnableReturnOwnerLocking = {
      configID: enableReturnOwnerLockingID,
      configValue: enableReturnOwnerLocking,
    };
    const subAutoAddFillingOpt = {
      configID: autoAddFillingOptID,
      configValue: autoAddFillingOpt,
    };
    const subEnableDoubleEntry = {
      configID: enableDoubleEntryID,
      configValue: enableDoubleEntry,
    };
    const subLockedFinishedPaperReturns = {
      configID: lockFinishedPaperReturnsID,
      configValue: lockFinishedPaperReturns,
    };
    const subBlindEntryNewReturn = {
      configID: blindEntryNewReturnID,
      configValue: blindEntryNewReturn,
    };
    const subDefaultStateRAC = {
      configID: defaultStateRACID,
      configValue: defaultStateRAC,
    };
    const subAutoSaveAfterMins = {
      configID: autoSaveAfterMinsID,
      configValue: autoSaveAfterMins,
    };
    const subAutoSaveEnabled = {
      configID: autoSaveEnabledID,
      configValue: autoSaveEnabled,
    };
    // Second Column
    const subSilentlySaveReturnOnClose = {
      configID: silentlySaveReturnOnCloseID,
      configValue: silentlySaveReturnOnClose,
    };
    const subAutoAdd1040Sch123 = {
      configID: requireAutoAdd1040Sch123ID,
      configValue: requireAutoAdd1040Sch123,
    };
    const subMaskSSNEIN = {
      configID: maskSSNEINID,
      configValue: maskSSNEIN,
    };
    // Year to Year
    const subExcludeForms = {
      configID: excludeFormsID,
      configValue: excludeForms,
    };
    const subExcludeDocs = {
      configID: excludeDocsID,
      configValue: excludeDocs,
    };
    const subIncludePreparerInfo = {
      configID: includePreparerInfoID,
      configValue: includePreparerInfo,
    };
    const subIncludeBankInfo = {
      configID: includeBankInfoID,
      configValue: includeBankInfo,
    };
    // Return Level
    const subRequireThirdPartyDesignee = {
      configID: requireThirdPartyDesigneeID,
      configValue: requireThirdPartyDesignee,
    };
    const subDisableEIC = {
      configID: disableEICID,
      configValue: disableEIC,
    };
    const subRequireReferral = {
      configID: requireReferralID,
      configValue: requireReferral,
    };
    const subRequireCellPhoneCarrierInfo = {
      configID: requireCellPhoneCarrierInfoID,
      configValue: requireCellPhoneCarrierInfo,
    };
    const subRequireEFPMTBankInfoPresent = {
      configID: requireEFPMTBankInfoPresentID,
      configValue: requireEFPMTBankInfoPresent,
    };
    const subDefaultPreparerInfo8879 = {
      configID: defaultPreparerInfo8879ID,
      configValue: defaultPreparerInfo8879,
    };
    const subDspWarningTypeErrors = {
      configID: dspWarningTypeErrorsID,
      configValue: dspWarningTypeErrors,
    };
    const subDspOverridesErrors = {
      configID: dspOverridesErrorsID,
      configValue: dspOverridesErrors,
    };
    const subDspNoVerifyOnUnattachedForms = {
      configID: dspNoVerifyOnUnattachedFormsID,
      configValue: dspNoVerifyOnUnattachedForms,
    };
    const printPDFWarningMessageObj = {
      configID: printPDFWarningMessageID,
      configValue: printPDFWarningMessage,
    };

    // Creating object that will be submitted, which will be composed of all field values
    const submitData = [
      subJumpCursor,
      subEnableReturnOwnerLocking,
      subAutoAddFillingOpt,
      subEnableDoubleEntry,
      subLockedFinishedPaperReturns,
      subBlindEntryNewReturn,
      subDefaultStateRAC,
      subSilentlySaveReturnOnClose,
      ...(state.season > 2018 ? [subAutoAdd1040Sch123] : []), // Wrapped in array to spread and conditionally add based on season.
      subMaskSSNEIN,
      subExcludeForms,
      subExcludeDocs,
      subIncludePreparerInfo,
      subRequireThirdPartyDesignee,
      subDisableEIC,
      subRequireReferral,
      subRequireCellPhoneCarrierInfo,
      subRequireEFPMTBankInfoPresent,
      ...(state.season > 2020 ? [subDefaultPreparerInfo8879] : []), // Wrapped in array to spread and conditionally add based on season.
      subAutoSaveAfterMins,
      subAutoSaveEnabled,
      subIncludeBankInfo,
      subDspWarningTypeErrors,
      subDspOverridesErrors,
      subDspNoVerifyOnUnattachedForms,
      printPDFWarningMessageObj,
    ];

    try {
      await XlinkAPI.updateDefaultsSetup(submitData, props.loginID);
      if (gotoNext) {
        props.loadSetupComponent(EXTENDED_SETUP_PAGES.AUDIT.NAME);
      } else {
        toggleSuccessSnackbar(true);
      }
    } catch (err) {
      ErrorHelpers.handleError('Error Updating Defaults Settings', err);
    }
  };

  const toggleSuccessSnackbar = toggle => {
    setState({
      snackbarOpen: toggle,
    });
  };

  const toggleConfirmCancel = toggle => {
    setState({
      confirmCancel: toggle,
    });
  };

  const spanStyle = {
    marginLeft: '2vw',
    color: '#354052',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: '500',
    marginTop: '1vh',
    marginBottom: '2vh',
  };
  const checkboxLabelStyle = {
    fontFamily: 'Roboto',
    fontSize: '14px',
    color: '#354052',
  };

  if (error) {
    return <div>Error: {error?.message}</div>;
  }

  if (fetching && !finishedFetching) {
    return (
      <div>
        <Spinner
          size={125}
          loadingText={SPINNER_DIALOGS.PROCESS_REQUEST}
          textColor="white"
          bgColor="grey"
        />
      </div>
    );
  }

  return (
    <div className="container-fixed" style={{ marginTop: '2.5rem' }}>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={state.snackbarOpen}
        autoHideDuration={2500}
        onClose={() => toggleSuccessSnackbar(false)}
        id="snkbarconfsaved"
        message={<span id="success-message">Configuration Saved</span>}
      />
      <Paper>
        <form
          style={{
            marginLeft: '3vw',
            marginRight: '3vw',
            marginTop: '1vh',
          }}
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          <span>
            <div
              style={{
                textAlign: 'left',
                paddingTop: '3vh',
                paddingBottom: '1vh',
              }}
            >
              <span
                style={{
                  fontFamily: 'Roboto',
                  fontSize: '21px',
                  letterSpacing: '0.14px',
                  color: '#1A173B',
                }}
              >
                Defaults
              </span>
            </div>
            <div style={spanStyle}>
              <span>Application Level</span>
            </div>

            <div style={{ float: 'left', width: '30vw', marginRight: '2vw' }}>
              <FormControl>
                <div style={{ marginLeft: '3vw', marginBottom: '0.1vh' }}>
                  <FormControlLabel
                    label="Jump Cursor Past City/State"
                    style={checkboxLabelStyle}
                    control={
                      <Checkbox
                        color="primary"
                        style={{
                          width: 17,
                          height: 16,
                          marginRight: '0.3vw',
                        }}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fontSize: 24, color: '#8FC3FF' }}
                            color="secondary"
                          />
                        }
                        checkedIcon={<CheckBoxIcon style={{ fontSize: 24 }} color="primary" />}
                        id="chkJumpCursorDefaults"
                        name="jumpCursor"
                        checked={jumpCursor === '1'}
                        value={jumpCursor}
                        onChange={e =>
                          dispatch(defaultsActions.handleCheckbox(e.target.checked, e.target.name))
                        }
                        inputProps={{ height: '20px', width: '20px' }}
                      />
                    }
                  />
                </div>
                <div style={{ marginLeft: '3vw', marginBottom: '0.1vh' }}>
                  <FormControlLabel
                    label="Auto Add Filing Options"
                    control={
                      <Checkbox
                        color="primary"
                        style={{
                          width: 17,
                          height: 16,
                          marginRight: '0.3vw',
                        }}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fontSize: 24, color: '#8FC3FF' }}
                            color="secondary"
                          />
                        }
                        checkedIcon={<CheckBoxIcon style={{ fontSize: 24 }} color="primary" />}
                        id="chkAutoAddFillingOptDefaults"
                        name="autoAddFillingOpt"
                        checked={autoAddFillingOpt === '1'}
                        value={autoAddFillingOpt}
                        onChange={e =>
                          dispatch(defaultsActions.handleCheckbox(e.target.checked, e.target.name))
                        }
                        inputProps={{ height: '20px', width: '20px' }}
                      />
                    }
                  />
                </div>
                <div style={{ marginLeft: '3vw', marginBottom: '0.1vh' }}>
                  <FormControlLabel
                    label="Enable Double Entry Validation"
                    control={
                      <Checkbox
                        color="primary"
                        style={{
                          width: 17,
                          height: 16,
                          marginRight: '0.3vw',
                        }}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fontSize: 24, color: '#8FC3FF' }}
                            color="secondary"
                          />
                        }
                        checkedIcon={<CheckBoxIcon style={{ fontSize: 24 }} color="primary" />}
                        id="chkEnableDoubleEntryDefaults"
                        name="enableDoubleEntry"
                        checked={enableDoubleEntry === '1'}
                        value={enableDoubleEntry}
                        onChange={e =>
                          dispatch(defaultsActions.handleCheckbox(e.target.checked, e.target.name))
                        }
                        inputProps={{ height: '20px', width: '20px' }}
                      />
                    }
                  />
                </div>
                <div style={{ marginLeft: '3vw', marginBottom: '0.1vh' }}>
                  <FormControlLabel
                    label="Lock Finished Paper Returns"
                    control={
                      <Checkbox
                        color="primary"
                        style={{
                          width: 17,
                          height: 16,
                          marginRight: '0.3vw',
                        }}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fontSize: 24, color: '#8FC3FF' }}
                            color="secondary"
                          />
                        }
                        checkedIcon={<CheckBoxIcon style={{ fontSize: 24 }} color="primary" />}
                        id="chkLockFinishedPaperReturnsDefaults"
                        name="lockFinishedPaperReturns"
                        checked={lockFinishedPaperReturns === '1'}
                        value={lockFinishedPaperReturns}
                        onChange={e =>
                          dispatch(defaultsActions.handleCheckbox(e.target.checked, e.target.name))
                        }
                        inputProps={{ height: '20px', width: '20px' }}
                      />
                    }
                  />
                </div>
                <div style={{ marginLeft: '3vw', marginBottom: '0.1vh' }}>
                  <FormControlLabel
                    label="Blind Entry for Create New Return"
                    control={
                      <Checkbox
                        color="primary"
                        style={{
                          width: 17,
                          height: 16,
                          marginRight: '0.3vw',
                        }}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fontSize: 24, color: '#8FC3FF' }}
                            color="secondary"
                          />
                        }
                        checkedIcon={<CheckBoxIcon style={{ fontSize: 24 }} color="primary" />}
                        id="chkBlindEntryNewReturnDefaults"
                        name="blindEntryNewReturn"
                        checked={blindEntryNewReturn === '1'}
                        value={blindEntryNewReturn}
                        onChange={e =>
                          dispatch(defaultsActions.handleCheckbox(e.target.checked, e.target.name))
                        }
                        inputProps={{ height: '20px', width: '20px' }}
                      />
                    }
                  />
                </div>
              </FormControl>
              <br />
              <div style={{ marginLeft: '3vw' }}>
                <FormControlLabel
                  label="Enable Auto Save After"
                  style={{ marginRight: 0 }}
                  control={
                    <Checkbox
                      color="primary"
                      style={{
                        width: 17,
                        height: 16,
                        marginRight: '0.3vw',
                      }}
                      icon={
                        <CheckBoxOutlineBlankIcon
                          style={{ fontSize: 24, color: '#8FC3FF' }}
                          color="secondary"
                        />
                      }
                      checkedIcon={<CheckBoxIcon style={{ fontSize: 24 }} color="primary" />}
                      id="chkEnableAutoSaveDefaults"
                      name="autoSaveEnabled"
                      checked={autoSaveEnabled === '1'}
                      value={autoSaveEnabled}
                      onChange={e =>
                        dispatch(defaultsActions.handleCheckbox(e.target.checked, e.target.name))
                      }
                      inputProps={{ height: '20px', width: '20px' }}
                    />
                  }
                />
                <div
                  style={{
                    marginLeft: '0.5vw',
                    fontFamily: 'Roboto',
                    fontSize: '14px',
                    textAlign: 'left',
                    width: '20%',
                    display: 'inline-block',
                    verticalAlign: 'bottom',
                  }}
                >
                  <FormControl style={{ margintop: 0 }}>
                    <Input
                      id="txtAutoSaveAfterMinsDefaults"
                      name="autoSaveAfterMins"
                      value={
                        parseInt(autoSaveAfterMins) < 2 || autoSaveAfterMins === ''
                          ? '2'
                          : autoSaveAfterMins
                      }
                      onChange={e =>
                        dispatch(defaultsActions.handleInputChange(e.target.value, e.target.name))
                      }
                      disabled={!autoSaveEnabled}
                      style={{
                        width: '5vw',
                        marginRight: '0.5vw',
                      }}
                      disableUnderline
                    />
                  </FormControl>
                </div>
                <div
                  style={{
                    fontFamily: 'Roboto',
                    fontSize: '14px',
                    display: 'inline',
                    verticalAlign: '.4vh',
                  }}
                >
                  Minutes
                </div>
              </div>
            </div>
            <div>
              <FormControl>
                <div style={{ marginLeft: '0.5vw', marginBottom: '0.1vh' }}>
                  <FormControlLabel
                    label="Default State RAC to Y/N"
                    control={
                      <Checkbox
                        color="primary"
                        style={{
                          width: 17,
                          height: 16,
                          marginRight: '0.3vw',
                        }}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fontSize: 24, color: '#8FC3FF' }}
                            color="secondary"
                          />
                        }
                        checkedIcon={<CheckBoxIcon style={{ fontSize: 24 }} color="primary" />}
                        id="chkDefaultStateRACDefaults"
                        name="defaultStateRAC"
                        checked={defaultStateRAC === '1'}
                        value={defaultStateRAC}
                        onChange={e =>
                          dispatch(defaultsActions.handleCheckbox(e.target.checked, e.target.name))
                        }
                        inputProps={{ height: '20px', width: '20px' }}
                      />
                    }
                  />
                </div>
                <div style={{ marginLeft: '0.5vw', marginBottom: '0.1vh' }}>
                  <FormControlLabel
                    label="Silently Save Return on Close"
                    control={
                      <Checkbox
                        color="primary"
                        style={{
                          width: 17,
                          height: 16,
                          marginRight: '0.3vw',
                        }}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fontSize: 24, color: '#8FC3FF' }}
                            color="secondary"
                          />
                        }
                        checkedIcon={<CheckBoxIcon style={{ fontSize: 24 }} color="primary" />}
                        id="chkSilentlySaveReturnOnCloseDefaults"
                        name="silentlySaveReturnOnClose"
                        checked={silentlySaveReturnOnClose === '1'}
                        value={silentlySaveReturnOnClose}
                        onChange={e =>
                          dispatch(defaultsActions.handleCheckbox(e.target.checked, e.target.name))
                        }
                        inputProps={{ height: '20px', width: '20px' }}
                      />
                    }
                  />
                </div>
                {state.season > 2018 && (
                  <div style={{ marginLeft: '0.5vw', marginBottom: '0.1vh' }}>
                    <FormControlLabel
                      label={`Auto Add 1040 Schedules ${state.season > 2019 ? '1-3' : '1-6'}`}
                      control={
                        <Checkbox
                          color="primary"
                          style={{
                            width: 17,
                            height: 16,
                            marginRight: '0.3vw',
                          }}
                          icon={
                            <CheckBoxOutlineBlankIcon
                              style={{ fontSize: 24, color: '#8FC3FF' }}
                              color="secondary"
                            />
                          }
                          checkedIcon={<CheckBoxIcon style={{ fontSize: 24 }} color="primary" />}
                          id="chkAutoAdd1040Sch123"
                          name="requireAutoAdd1040Sch123"
                          checked={requireAutoAdd1040Sch123 === '1'}
                          value={requireAutoAdd1040Sch123}
                          onChange={e =>
                            dispatch(
                              defaultsActions.handleCheckbox(e.target.checked, e.target.name),
                            )
                          }
                          inputProps={{ height: '20px', width: '20px' }}
                        />
                      }
                    />
                  </div>
                )}
                <div style={{ marginLeft: '0.5vw', marginBottom: '0.1vh' }}>
                  <FormControlLabel
                    label="Disable RT Lite"
                    control={
                      <Checkbox
                        color="primary"
                        style={{
                          width: 17,
                          height: 16,
                          marginRight: '0.3vw',
                        }}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fontSize: 24, color: '#8FC3FF' }}
                            color="secondary"
                          />
                        }
                        checkedIcon={<CheckBoxIcon style={{ fontSize: 24 }} color="primary" />}
                        id="chkDisableRTLiteDefaults"
                        name="disableRTLite"
                        checked={disableRTLite === '1'}
                        value={disableRTLite}
                        onChange={e =>
                          dispatch(defaultsActions.handleCheckbox(e.target.checked, e.target.name))
                        }
                        inputProps={{ height: '20px', width: '20px' }}
                      />
                    }
                  />
                </div>
                <div style={{ marginLeft: '0.5vw', marginBottom: '0.1vh' }}>
                  <FormControlLabel
                    label="Mask SSN/EIN"
                    control={
                      <Checkbox
                        color="primary"
                        style={{
                          width: 17,
                          height: 16,
                          marginRight: '0.3vw',
                        }}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fontSize: 24, color: '#8FC3FF' }}
                            color="secondary"
                          />
                        }
                        checkedIcon={<CheckBoxIcon style={{ fontSize: 24 }} color="primary" />}
                        id="chkMaskSSNEINDefaults"
                        name="maskSSNEIN"
                        checked={maskSSNEIN === '1'}
                        value={maskSSNEIN}
                        onChange={e =>
                          dispatch(defaultsActions.handleCheckbox(e.target.checked, e.target.name))
                        }
                        inputProps={{ height: '20px', width: '20px' }}
                      />
                    }
                  />
                </div>
                <br />
              </FormControl>
            </div>

            <br />
            <div style={{ ...spanStyle, ...{ marginTop: '2vh' } }}>
              <br />
              <span>Year to Year</span>
            </div>

            <div style={{ float: 'left', width: '30vw', marginRight: '2vw' }}>
              <FormControl>
                <div style={{ marginLeft: '3vw', marginBottom: '0.1vh' }}>
                  <FormControlLabel
                    label="Do Not Transfer W2/1099"
                    control={
                      <Checkbox
                        color="primary"
                        style={{
                          width: 17,
                          height: 16,
                          marginRight: '0.3vw',
                        }}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fontSize: 24, color: '#8FC3FF' }}
                            color="secondary"
                          />
                        }
                        checkedIcon={<CheckBoxIcon style={{ fontSize: 24 }} color="primary" />}
                        id="chkExcludeFormsDefaults"
                        name="excludeForms"
                        checked={excludeForms === '1'}
                        value={excludeForms}
                        onChange={e =>
                          dispatch(defaultsActions.handleCheckbox(e.target.checked, e.target.name))
                        }
                        inputProps={{ height: '20px', width: '20px' }}
                      />
                    }
                  />
                </div>
                <div style={{ marginLeft: '3vw', marginBottom: '0.1vh' }}>
                  <FormControlLabel
                    label="Do Not Transfer Doc Archive"
                    control={
                      <Checkbox
                        color="primary"
                        style={{
                          width: 17,
                          height: 16,
                          marginRight: '0.3vw',
                        }}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fontSize: 24, color: '#8FC3FF' }}
                            color="secondary"
                          />
                        }
                        checkedIcon={<CheckBoxIcon style={{ fontSize: 24 }} color="primary" />}
                        id="chkExcludeDocsDefaults"
                        name="excludeDocs"
                        checked={excludeDocs === '1'}
                        value={excludeDocs}
                        onChange={e =>
                          dispatch(defaultsActions.handleCheckbox(e.target.checked, e.target.name))
                        }
                        inputProps={{ height: '20px', width: '20px' }}
                      />
                    }
                  />
                </div>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <div style={{ marginLeft: '0.5vw', marginBottom: '0.1vh' }}>
                  <FormControlLabel
                    label="Transfer Preparer Information"
                    control={
                      <Checkbox
                        color="primary"
                        style={{
                          width: 17,
                          height: 16,
                          marginRight: '0.3vw',
                        }}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fontSize: 24, color: '#8FC3FF' }}
                            color="secondary"
                          />
                        }
                        checkedIcon={<CheckBoxIcon style={{ fontSize: 24 }} color="primary" />}
                        id="chkIncludePreparerInfoDefaults"
                        name="includePreparerInfo"
                        checked={includePreparerInfo === '1'}
                        value={includePreparerInfo}
                        onChange={e =>
                          dispatch(defaultsActions.handleCheckbox(e.target.checked, e.target.name))
                        }
                        inputProps={{ height: '20px', width: '20px' }}
                      />
                    }
                  />
                </div>
                <div style={{ marginLeft: '0.5vw', marginBottom: '0.1vh' }}>
                  <FormControlLabel
                    label="Transfer Bank Account Information"
                    control={
                      <Checkbox
                        color="primary"
                        style={{
                          width: 17,
                          height: 16,
                          marginRight: '0.3vw',
                        }}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fontSize: 24, color: '#8FC3FF' }}
                            color="secondary"
                          />
                        }
                        checkedIcon={<CheckBoxIcon style={{ fontSize: 24 }} color="primary" />}
                        id="chkIncludeBankInfoDefaults"
                        name="includeBankInfo"
                        checked={includeBankInfo === '1'}
                        value={includeBankInfo}
                        onChange={e =>
                          dispatch(defaultsActions.handleCheckbox(e.target.checked, e.target.name))
                        }
                        inputProps={{ height: '20px', width: '20px' }}
                      />
                    }
                  />
                </div>
              </FormControl>
            </div>
            <br />
            <div style={spanStyle}>
              <span>Return Level</span>
            </div>
            <div style={{ float: 'left', width: '32vw', marginRight: '0vw' }}>
              <FormControl>
                <div style={{ marginLeft: '3vw', marginBottom: '0.1vh' }}>
                  <FormControlLabel
                    label="Require Third Party Designee"
                    control={
                      <Checkbox
                        color="primary"
                        style={{
                          width: 17,
                          height: 16,
                          marginRight: '0.3vw',
                        }}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fontSize: 24, color: '#8FC3FF' }}
                            color="secondary"
                          />
                        }
                        checkedIcon={<CheckBoxIcon style={{ fontSize: 24 }} color="primary" />}
                        id="chkRequireThirdPartyDesigneeDefaults"
                        name="requireThirdPartyDesignee"
                        checked={requireThirdPartyDesignee === '1'}
                        value={requireThirdPartyDesignee}
                        onChange={e =>
                          dispatch(defaultsActions.handleCheckbox(e.target.checked, e.target.name))
                        }
                        inputProps={{ height: '20px', width: '20px' }}
                      />
                    }
                  />
                </div>
                <div style={{ marginLeft: '3vw', marginBottom: '0.1vh' }}>
                  <FormControlLabel
                    label="Disable EIC Checklist for all Self Prepared Returns"
                    control={
                      <Checkbox
                        color="primary"
                        style={{
                          width: 17,
                          height: 16,
                          marginRight: '0.3vw',
                        }}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fontSize: 24, color: '#8FC3FF' }}
                            color="secondary"
                          />
                        }
                        checkedIcon={<CheckBoxIcon style={{ fontSize: 24 }} color="primary" />}
                        id="chkDisableEICDefaults"
                        name="disableEIC"
                        checked={disableEIC === '1'}
                        value={disableEIC}
                        onChange={e =>
                          dispatch(defaultsActions.handleCheckbox(e.target.checked, e.target.name))
                        }
                        inputProps={{ height: '20px', width: '20px' }}
                      />
                    }
                  />
                </div>
                <div style={{ marginLeft: '3vw', marginBottom: '0.1vh' }}>
                  <FormControlLabel
                    label="Require Form EF-PMT when bank information present on CDS"
                    control={
                      <Checkbox
                        color="primary"
                        style={{
                          width: 17,
                          height: 16,
                          marginRight: '0.3vw',
                        }}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fontSize: 24, color: '#8FC3FF' }}
                            color="secondary"
                          />
                        }
                        checkedIcon={<CheckBoxIcon style={{ fontSize: 24 }} color="primary" />}
                        id="chkRequireEFPMTBankInfoPresentDefaults"
                        name="requireEFPMTBankInfoPresent"
                        checked={requireEFPMTBankInfoPresent === '1'}
                        value={requireEFPMTBankInfoPresent}
                        onChange={e =>
                          dispatch(defaultsActions.handleCheckbox(e.target.checked, e.target.name))
                        }
                        inputProps={{ height: '20px', width: '20px' }}
                      />
                    }
                  />
                </div>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <div style={{ marginLeft: '0.5vw', marginBottom: '0.1vh' }}>
                  <FormControlLabel
                    label="Require Referral"
                    control={
                      <Checkbox
                        color="primary"
                        style={{
                          width: 17,
                          height: 16,
                          marginRight: '0.3vw',
                        }}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fontSize: 24, color: '#8FC3FF' }}
                            color="secondary"
                          />
                        }
                        checkedIcon={<CheckBoxIcon style={{ fontSize: 24 }} color="primary" />}
                        id="chkRequireReferralDefaults"
                        name="requireReferral"
                        checked={requireReferral === '1'}
                        value={requireReferral}
                        onChange={e =>
                          dispatch(defaultsActions.handleCheckbox(e.target.checked, e.target.name))
                        }
                        inputProps={{ height: '20px', width: '20px' }}
                      />
                    }
                  />
                </div>
                <div style={{ marginLeft: '0.5vw', marginBottom: '0.1vh' }}>
                  <FormControlLabel
                    label="Require Cell Phone Carrier Information"
                    control={
                      <Checkbox
                        color="primary"
                        style={{
                          width: 17,
                          height: 16,
                          marginRight: '0.3vw',
                        }}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fontSize: 24, color: '#8FC3FF' }}
                            color="secondary"
                          />
                        }
                        checkedIcon={<CheckBoxIcon style={{ fontSize: 24 }} color="primary" />}
                        id="chkRequireCellPhoneCarrierInfoDefaults"
                        name="requireCellPhoneCarrierInfo"
                        checked={requireCellPhoneCarrierInfo === '1'}
                        value={requireCellPhoneCarrierInfo}
                        onChange={e =>
                          dispatch(defaultsActions.handleCheckbox(e.target.checked, e.target.name))
                        }
                        inputProps={{ height: '20px', width: '20px' }}
                      />
                    }
                  />
                </div>
                {state.season > 2020 && (
                  <div style={{ marginLeft: '0.5vw', marginBottom: '0.1vh' }}>
                    <FormControlLabel
                      label="Default Preparer Information on 8879"
                      control={
                        <Checkbox
                          color="primary"
                          style={{
                            width: 17,
                            height: 16,
                            marginRight: '0.3vw',
                          }}
                          icon={
                            <CheckBoxOutlineBlankIcon
                              style={{ fontSize: 24, color: '#8FC3FF' }}
                              color="secondary"
                            />
                          }
                          checkedIcon={<CheckBoxIcon style={{ fontSize: 24 }} color="primary" />}
                          id="chkDefaultPreparerInfo8879Defaults"
                          name="defaultPreparerInfo8879"
                          checked={defaultPreparerInfo8879 === '1'}
                          value={defaultPreparerInfo8879}
                          onChange={e =>
                            dispatch(
                              defaultsActions.handleCheckbox(e.target.checked, e.target.name),
                            )
                          }
                          inputProps={{ height: '20px', width: '20px' }}
                        />
                      }
                    />
                  </div>
                )}
                <div style={{ marginLeft: '0.5vw', marginBottom: '0.1vh' }}>
                  <br />
                </div>
              </FormControl>
            </div>
            <br />
            <div style={spanStyle}>
              <span>Verification Level</span>
            </div>
            <div style={{ float: 'left', width: '32vw', marginRight: '0vw' }}>
              <FormControl>
                <div style={{ marginLeft: '3vw', marginBottom: '0.1vh' }}>
                  <FormControlLabel
                    label="Display Warning-type Errors"
                    control={
                      <Checkbox
                        color="primary"
                        style={{
                          width: 17,
                          height: 16,
                          marginRight: '0.3vw',
                        }}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fontSize: 24, color: '#8FC3FF' }}
                            color="secondary"
                          />
                        }
                        checkedIcon={<CheckBoxIcon style={{ fontSize: 24 }} color="primary" />}
                        id="chkDspWarningTypeErrors"
                        name="dspWarningTypeErrors"
                        checked={dspWarningTypeErrors === '1'}
                        value={dspWarningTypeErrors}
                        onChange={e =>
                          dispatch(defaultsActions.handleCheckbox(e.target.checked, e.target.name))
                        }
                        inputProps={{ height: '20px', width: '20px' }}
                      />
                    }
                  />
                </div>
              </FormControl>
            </div>
            {state.season > 2023 && (
              <div style={{ marginLeft: '0.5vw', marginBottom: '0.1vh' }}>
                <FormControlLabel
                  label="No verify on unattached forms"
                  control={
                    <Checkbox
                      color="primary"
                      style={{
                        width: 17,
                        height: 16,
                        marginRight: '0.3vw',
                      }}
                      icon={
                        <CheckBoxOutlineBlankIcon
                          style={{ fontSize: 24, color: '#8FC3FF' }}
                          color="secondary"
                        />
                      }
                      checkedIcon={<CheckBoxIcon style={{ fontSize: 24 }} color="primary" />}
                      id="chkDspNoVerifyOnUnattachedForms"
                      name="dspNoVerifyOnUnattachedForms"
                      checked={dspNoVerifyOnUnattachedForms === '1'}
                      value={dspNoVerifyOnUnattachedForms}
                      onChange={e =>
                        dispatch(defaultsActions.handleCheckbox(e.target.checked, e.target.name))
                      }
                      inputProps={{ height: '20px', width: '20px' }}
                    />
                  }
                />
              </div>
            )}
            <div>
              <FormControl>
                <div style={{ marginLeft: '3vw', marginBottom: '0.1vh' }}>
                  <FormControlLabel
                    label="Display Overrides as Errors"
                    control={
                      <Checkbox
                        color="primary"
                        style={{
                          width: 17,
                          height: 16,
                          marginRight: '0.3vw',
                        }}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fontSize: 24, color: '#8FC3FF' }}
                            color="secondary"
                          />
                        }
                        checkedIcon={<CheckBoxIcon style={{ fontSize: 24 }} color="primary" />}
                        id="chkDspOverridesErrors"
                        name="dspOverridesErrors"
                        checked={dspOverridesErrors === '1'}
                        value={dspOverridesErrors}
                        onChange={e =>
                          dispatch(defaultsActions.handleCheckbox(e.target.checked, e.target.name))
                        }
                        inputProps={{ height: '20px', width: '20px' }}
                      />
                    }
                  />
                </div>
              </FormControl>
              <div style={{ marginLeft: '0.5vw', marginBottom: '0.1vh' }}>
                <br />
              </div>
            </div>
          </span>
          <br />
          <Divider />
          <div
            style={{
              textAlign: 'right',
              width: '100%',
              padding: '0',
              marginLeft: '0vw',
              marginRight: '3vw',
              marginTop: '3vh',
            }}
          >
            <Button
              color="primary"
              id="btnPreviousDefaults"
              style={{ width: '10vw', marginRight: '1vw' }}
              onClick={() => {
                setState({ gotoPrev: true });
                toggleConfirmCancel(true);
              }}
            >
              Previous (Cancel)
            </Button>
            <span>
              <Button
                color="primary"
                id="btnPreviousDefaults"
                style={{ width: '10vw', marginRight: '1vw' }}
                onClick={() => {
                  setState({ gotoPrev: false });
                  toggleConfirmCancel(true);
                }}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                id="btnSaveDefaults"
                style={{ width: '10vw', marginRight: '1vw' }}
                onClick={() => submitDefaultData(false)}
              >
                Save
              </Button>
            </span>
            <Button
              color="primary"
              id="btnNextDefaults"
              style={{ width: '10vw' }}
              onClick={() => submitDefaultData(true)}
            >
              Save &amp; Next
            </Button>
          </div>
          <br />
        </form>
        <SimpleDialog
          open={state.confirmCancel}
          onClose={() => toggleConfirmCancel(false)}
          onConfirm={() => {
            toggleConfirmCancel(false);
            if (state.gotoPrev) {
              props.loadSetupComponent(EXTENDED_SETUP_PAGES.LOGINS.NAME);
            }
            dispatch(defaultsActions.fetchDefaultsConfig(props.loginID, state.season));
          }}
          dialogTitle="Lose Unsaved Changes?"
          contentText="Are you sure you want to undo any pending changes?"
        />
      </Paper>
    </div>
  );
};

export default defaultSetup;
