export const CLIENT_REVIEW_TEXT = {
  Title: 'Bank Document Review',
  Dialogue: 'Has the client already reviewed the bank documents?',
};

// Bank IDs
// 1-Republic Bank
// 2-TPG
// 3-Refundo
// 4-Refund Advantage
// 5-TPG World Finance
export const BANK_CONSENT_TEXT = {
  1: {
    Title: 'Republic Bank Electronic Signature Disclosure and Consent',
    contentText:
      'To ensure the integrity of documents, it is your responsibility to make certain the appropriate taxpayer signs when applicable.',
    contentTextTwo:
      'PLEASE READ THE FOLLOWING TO THE CUSTOMER(S). You are now signing and agreeing to the BANK PRODUCT APPLICATION AND AGREEMENT. By signing, you are agreeing to the fees set forth in Section 4 of the Application and Agreement and acknowledging that you have received, read and understand the Need to Know Sheet (if applicable), Easy Advance Information Page (if applicable), Truth-In Lending-Act Disclosure (if applicable), Privacy Policy and all applicable state disclosures. If you do not wish to apply for a Bank Product through Republic Bank, do not sign. If you do wish to continue with this transaction and enter into this application and agreement for a Bank Product through Republic Bank, please sign now.',
    contentTextThree: null,
  },
  2: {
    Title: 'TPG Electronic Signature Disclosure and Consent',
    contentText:
      'You are now signing the BANK PRODUCT APPLICATION and AGREEMENT stating that you have seen and agree to the Application and Agreement. If you do not wish to apply for a Bank Product through TPG/BANK, do not sign.  If you do wish to continue with this transaction and enter into this agreement, please sign now.',
    contentTextTwo: null,
  },
  3: {
    Title: 'Refundo Electronic Signature Disclosure and Consent',
    contentText:
      'It is required by law to provide you with certain disclosures and information about the products, services or accounts you may receive or access in connection with your relationship with us (“Required Information”). With your consent, we can deliver Required Information to you by:  a) displaying or delivering the Required Information electronically; and b) requesting that you print or download the Required Information and retain it for you records.Prior to providing certain account related information, your consent is requested to send important information electronically. You agree to provide and keep current your email address and satisfy the system requirements below. You certify the email address you provide is your email address, and you want us to use it to provide communications electronically. If any electronic communication is returned as undeliverable, you agree that this information may be delivered in paper form.You understand and agree that you may be provided with all disclosures, notices and other communications regarding your Refund Advance account in electronic form. These communications include, but are not limited to, error resolutions, and changes in terms. You understand that by giving us your consent, you may no longer receive paper copies of communications and you accept any consequence of not reviewing communications in a timely manner. At your request, we will provide you with paper copies of communications that are provided to you electronically.',
    contentTextTwo:
      'You may elect to withdraw your consent for electronic communications at any time free of charge electronically by emailing contactus@myfcbusa.com',
    contentTextThree:
      "You may also contact us to receive a paper copy of any electronic communication or if you need to update any personal information or a change in your email address. We retain copies of communications for the time periods required by law and will provide you with copies of these free of charge, upon your request, within those time periods. In order to receive electronic communications, you must have a valid email address, a device with access to the Internet, a web browser that supports HTML and 128-bit encryption such as Chrome, Firefox, Internet Explorer or Safari, the capabilityto download and print, and a program to view PDF files (such as Adobe Reader, which you may download free from http://get.adobe.com/reader/).  We may amend (add to, delete or change) the terms of this Consent to electronic communication by providing you with advance notice. I have read the information about the use of electronic records, disclosures, notices, and email and consent to the use of electronic records for the delivery of “Required Information” in connection with our relationship. I have been able to view this information using my computer and software. I have an account with an internet service provider, and I am able to send email and receive email with hyperlinks to websites and attached files. I also consent to the use of electronic records and electronic signatures in place of written documents and handwritten signatures. SIGNATURE AND ACCEPTANCE OF ALL TERMS AND CONDITIONS By clicking the 'OK' button below, you are electronically signing this consent to electronic communications.  You also acknowledge your ability to download or print a fully completed copy for your records.",
  },
  4: {
    Title: 'Refund Advantage Electronic Signature Disclosure and Consent',
    contentText:
      'You are now signing the REFUND DISBURSEMENT SERVICE APPLICATION stating that you have seen the IMPORTANT SERVICE DISCLOSURES and agree with the Agreement.  If you do not wish to use the Refund Disbursement Service, do not sign.  If you wish to continue with this transaction and enter into this Agreement, please sign now.',
    contentTextTwo: null,
  },
  5: {
    Title: 'TPG World Finance Electronic Signature Disclosure and Consent',
    contentText:
      'You are now signing the BANK PRODUCT APPLICATION and AGREEMENT stating that you have seen and agree to the Application and Agreement. If you do not wish to apply for a Bank Product through TPG World Finance/BANK, do not sign.  If you do wish to continue with this transaction and enter into this agreement, please sign now.',
    contentTextTwo: null,
    contentTextThree: null,
  },
};
