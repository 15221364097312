/* eslint-disable dot-notation */
import React, { Fragment } from 'react';
import { styles } from '~/app/Pages/Setup/ManageLicencing/css/manageLicencing.js';
import { styles as pagedTableStyles } from '~/app/Components/Common/css/pagedTableStyles.jsx';
import '~/app/Pages/Setup/ManageLicencing/css/manageLicencing.css';
import '~/app/Pages/Setup/css/setup.css';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Button,
  withStyles,
} from '@material-ui/core';
import { licenseUsed } from '../manageLicenseHelper.js';

/**
 * LicenseTable Component
 * Displays a given set of licenses as a table
 * @component
 *
 */
const LicenseTable = props => {
  const { classes } = props;
  return (
    <Fragment>
      <Grid item xs={12} style={styles.paperTitle}>
        <Grid container style={styles.gridSubTable}>
          <Grid item xs={8}>
            <h2 className="setup-section-heading">
              {props.tableName}
              <span className="license-used">( {licenseUsed(props.licencingSetup)} )</span>
            </h2>
          </Grid>
        </Grid>
      </Grid>
      <Table>
        <TableHead classes={{ root: classes.pagedTableHeadStickyHead }}>
          <TableRow classes={{ root: classes.pagedTableHeadRow }}>
            <TableCell classes={{ root: classes.pagedTableStickyHeadCell }}>License ID</TableCell>
            <TableCell classes={{ root: classes.pagedTableStickyHeadCell }}>Assigned To</TableCell>
            <TableCell classes={{ root: classes.pagedTableStickyHeadCell }}>License Type</TableCell>
            <TableCell classes={{ root: classes.pagedTableStickyHeadCell }}>Season</TableCell>
            <TableCell classes={{ root: classes.pagedTableStickyHeadCell }}>Status</TableCell>
            <TableCell classes={{ root: classes.pagedTableStickyHeadCell }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {props?.licencingSetup?.licenseInfo?.map((value, index) => {
            return (
              <TableRow key={'Fragment' + index} classes={{ root: classes.pagedTableBodyRow }}>
                <TableCell classes={{ root: classes.pagedTableBodyCell }}>
                  {value.licenseID}
                </TableCell>
                <TableCell classes={{ root: classes.pagedTableBodyCell }}>
                  {value.officeName}
                </TableCell>
                <TableCell classes={{ root: classes.pagedTableBodyCell }}>
                  {`${value.productCode}${value.lType === 3 ? ' (PPR)' : ''}`}
                </TableCell>
                <TableCell classes={{ root: classes.pagedTableBodyCell }}>{value.season}</TableCell>
                <TableCell classes={{ root: classes.pagedTableBodyCell }}>
                  {props.getLicenseStatusText(value.officeID)}
                </TableCell>
                <TableCell classes={{ root: classes.pagedTableBodyCell }}>
                  <Button
                    id={'btnLicencingSetupEdit' + index}
                    color="primary"
                    onClick={() => {
                      props.openLicenseModal(
                        props.licencingSetup.licenseInfo,
                        index,
                        props.licenseType,
                      );
                    }}
                    disabled={value.isLicenseRemovable}
                  >
                    {value.officeID === 0 ? 'Assign' : 'Remove'}
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Fragment>
  );
};

export default withStyles({ ...styles, ...pagedTableStyles })(LicenseTable);
