import React, { useEffect, useState } from 'react';
import WebHelpers from '~/app/webHelpers.js';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import XlinkAPI from '~/app/api/xlinkAPI';
import { Divider, Grid, withStyles } from '@material-ui/core';

import '~/app/Components/Auth/css/versionModal.css';
import { styles } from '~/app/Components/Auth/css/versionModal.js';

const VersionsModal = props => {
  const [season, setSeason] = useState('');
  const [versionNames, setVersionNames] = useState([]);
  const [corpVersionNames, setCorpVersionNames] = useState([]);

  const { classes } = props;

  useEffect(() => {
    XlinkAPI.fetchCrosslinkVersions().then(res => {
      setVersionNames(Object.entries(res.data.arg['1040Versions']));
      setCorpVersionNames(Object.entries(res.data.arg.CORPVersions));
    });
    getSeason();
  }, []);

  const getSeason = () => {
    const payload = WebHelpers.getJWTPayload();
    if (typeof payload !== 'undefined') {
      setSeason(WebHelpers.getJWTPayload().season - 1);
    }
  };

  const versionNumbers = is1040 => {
    const versionList = is1040 ? versionNames : corpVersionNames;
    return versionList.map(val => {
      const version = val[0] + '\n' + val[1];
      return (
        <Grid item xs={4} zeroMinWidth key={val[0]}>
          {version}
        </Grid>
      );
    });
  };

  return (
    <div className="version-modal-container">
      <Toolbar className="version-modal-toolbar">
        <Typography classes={{ root: classes.typography }}>
          Versions
          <Button classes={{ root: classes.btn }} onClick={() => props.onClose()}>
            X
          </Button>
        </Typography>
      </Toolbar>
      <div className="version-modal-body">
        <span className="version-modal-body-title">Tax Year {season}</span>

        <span className="version-modal-body-program-version">
          Program Version: {sessionStorage.getItem('appVersion') || ''}
        </span>
        <Grid
          classes={{
            item: classes.styledFlexGridDivider,
          }}
          item
          xs={12}
        >
          <Divider
            classes={{
              root: classes.documentDivider,
            }}
            variant="fullWidth"
            light={true}
          />
        </Grid>
        <span className="version-modal-body-program-version">1040</span>
        <Grid className="version-modal-grid" container spacing={0}>
          {versionNumbers(true)}
        </Grid>
        <span className="version-modal-body-program-version">Business</span>
        <Grid className="version-modal-grid" container spacing={0}>
          {versionNumbers(false)}
        </Grid>
      </div>
    </div>
  );
};

export default withStyles(styles)(VersionsModal);
