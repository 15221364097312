// External imports
import React, { useEffect } from 'react';
import {
  withStyles,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Typography,
  TableHead,
  NativeSelect,
  Input,
} from '@material-ui/core';
import update from 'immutability-helper';
// Internal imports
import { useSetState } from '~/app/Utility/customHooks';
import InputTableCell from '~/app/Components/NewAccount/Setup/InputTableCell.jsx';
import {
  AVAILABLE_BUSINESS_ENTITIES,
  EIN_ENTITY_TYPES,
  QA_STATE_LIST_PACKAGES,
  QA_CORP_STATE_LIST_PACKAGES,
} from '~/app/constants.js';
import ToggleButton from '#/Common/Toggle.jsx';
import XlinkAPI from '~/app/api/xlinkAPI';
import ErrorHelpers from '~/app/errorHelpers.js';
import Spinner from '#/Common/Spinner.jsx';
import addFormIcon from '~/images/icons/add_form.png';
import WebHelpers from '~/app/webHelpers.js';
// Styling imports
import { styles } from '~/app/Components/NewAccount/SingleOffice/css/formBilling.js';

const BillingItemListModal = props => {
  const payload = WebHelpers.getJWTPayload();
  const { classes } = props;
  const [state, setState] = useSetState({
    selectedState: 'US',
    selectedEntity: props.isBusiness ? '1065' : '1040',
    isLoading: true,
    tbRowsFormBilling: [],
    addedBilledItems: [],
    filterString: '',
    basicView: true,
    individualPackages: [],
    businessPackages: [],
  });

  useEffect(() => {
    setState({
      addedBilledItems: props.tbRowsFormBilling,
    });
    loadBillingItemList(state.selectedState);
  }, []);

  useEffect(() => {
    // Regardless of State Package manager, allow production testing accounts access to all states
    if (
      payload.season >= 2024 &&
      ENVIRONMENT === 'production' &&
      ['NAHMIC', 'AMAPRI00', 'CLOTST'].includes(payload.account_code)
    ) {
      setState({
        individualPackages: QA_STATE_LIST_PACKAGES,
        businessPackages: QA_CORP_STATE_LIST_PACKAGES,
      });
      return;
    }

    getAvailablePackages();
  }, [props.isBusiness]);

  const getAvailablePackages = async () => {
    try {
      const res = await XlinkAPI.getStatePackages(props.isBusiness);

      if (props.isBusiness) {
        setState({
          businessPackages: res.data?.sort(),
        });
      } else {
        setState({
          individualPackages: res.data?.sort(),
        });
      }
    } catch (error) {
      ErrorHelpers.handleError('Fetch Error', error);
    }
  };

  const loadBillingItemList = (state, selectedEntity = '1065') => {
    let entityID;
    if (!props.isBusiness) {
      entityID = 10; // 1040 ID
    } else {
      entityID = Object.keys(EIN_ENTITY_TYPES).find(
        key => EIN_ENTITY_TYPES[key] === selectedEntity,
      );
    }

    XlinkAPI.getBillingDetails(entityID, props.form_type, state)
      .then(res => {
        setState({ tbRowsFormBilling: res.data }, setState({ isLoading: false }));
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getStateList = () => {
    const pkgList = props.isBusiness ? state.businessPackages : state.individualPackages;
    return (
      <>
        {props.isBusiness ? (
          <NativeSelect
            id="selectedEntity"
            value={state.selectedEntity}
            onChange={e => handleSelectedEntityChange(e.target.value)}
            style={{ minWidth: '13%', maxWidth: 'auto', marginLeft: '1.5em' }}
            hover="false"
          >
            <option value="" />
            {AVAILABLE_BUSINESS_ENTITIES.map((entity, i) => {
              if (
                payload.season >= 2024 &&
                ENVIRONMENT === 'production' &&
                !['NAHMIC', 'AMAPRI00', 'CLOTST'].includes(payload.account_code) &&
                i > 2
              ) {
                return null;
              }
              return (
                <option key={entity} value={entity}>
                  {entity}
                </option>
              );
            }).filter(option => option !== undefined)}
          </NativeSelect>
        ) : null}
        <NativeSelect
          id="selectedState"
          value={state.selectedState}
          onChange={e => handleSelectedStateChange(e.target.value)}
          style={{ minWidth: '13%', maxWidth: 'auto', marginLeft: '1.5em' }}
          hover="false"
        >
          <option value="" />
          {['US'].concat(pkgList).map(state => {
            return (
              <option key={state} value={state}>
                {state}
              </option>
            );
          })}
        </NativeSelect>
      </>
    );
  };

  const buildFormBillingTable = () => {
    // see FormBilling component, buildFormBillingTable, should there be other exceptions
    let filteredList = state.tbRowsFormBilling?.filter(form =>
      form.form_name.toLowerCase().includes(state.filterString.toLowerCase()),
    );

    if (!props.customTags?.includes('104A')) {
      // Desktop hides this for non 104A users (CTTS)
      filteredList = filteredList.filter(
        item => item.form_name !== '1040 Data' && item.form_description !== '1040 Data Entry Form',
      );
    }
    return filteredList.map(data => {
      return (
        <TableRow classes={{ root: classes.tableRowRoot }} key={data.billing_lookup_id}>
          <TableCell
            id="tblCellfrmDesc"
            style={{ fontWeight: 'Bold' }}
            className="noselect"
            classes={{ root: classes.tableCellRoot }}
          >
            {data.form_name}
          </TableCell>
          <TableCell
            id="tblCellfrmName"
            style={{ color: '#67727C' }}
            className="noselect"
            classes={{ root: classes.tableCellRoot }}
            component="th"
            scope="row"
          >
            {data.form_description}
          </TableCell>
          {!state.basicView && (
            <TableCell classes={{ root: classes.tableCellRoot }} className="noselect">
              <InputTableCell
                id={'bqty-' + data.billing_lookup_id}
                value={getBaseQty(data.billing_lookup_id)}
                updateValue={updateBaseQtyForId}
                billid={data.billing_lookup_id}
                form_name={data.form_name}
                form_description={data.form_description}
              />
            </TableCell>
          )}
          {!state.basicView && (
            <TableCell classes={{ root: classes.tableCellRoot }} className="noselect">
              <InputTableCell
                id={'bprice-' + data.billing_lookup_id}
                billid={data.billing_lookup_id}
                form_name={data.form_name}
                form_description={data.form_description}
                value={getBasePrice(data.billing_lookup_id)}
                updateValue={updateBasePriceForId}
                type="currency"
              />
            </TableCell>
          )}
          <TableCell classes={{ root: classes.tableCellRoot }} className="noselect">
            <InputTableCell
              id={'bpriceper-' + data.billing_lookup_id}
              billid={data.billing_lookup_id}
              form_name={data.form_name}
              form_description={data.form_description}
              value={getPerItem(data.billing_lookup_id)}
              updateValue={updatePerItemForId}
              type="currency"
            />
          </TableCell>
        </TableRow>
      );
    });
  };

  const handleSelectedStateChange = value => {
    setState(
      { selectedState: value, isLoading: true },
      loadBillingItemList(value, state.selectedEntity),
    );
  };

  const handleSelectedEntityChange = value => {
    setState(
      { selectedEntity: value, isLoading: true },
      loadBillingItemList(state.selectedState, value),
    );
  };

  const updateBaseQtyForId = (id, e, name, desc) => {
    e.preventDefault();
    // Item not found, add a new item to list.
    let value = e.target.value;
    value = value.replace(/[$,]/g, '');

    const itemIndex = state.addedBilledItems.findIndex(
      billItem => billItem.billing_lookup_id === id,
    );
    // Item not found, add a new item to list.
    if (itemIndex === -1) {
      if (value.length === 0) {
        return;
      }
      const newItem = {
        billing_lookup_id: id,
        form_name: name,
        form_description: desc,
        baseQty: value,
        basePrice: '0.00',
        perItem: '0.00',
        form_type: props.form_type,
        entityName: state.selectedEntity,
      };
      setState({
        addedBilledItems: [...state.addedBilledItems, newItem],
      });
    } else {
      if (value.length === 0) {
        value = '0';
      }
      setState({
        addedBilledItems: update(state.addedBilledItems, {
          [itemIndex]: { baseQty: { $set: value } },
        }),
      });
    }
  };

  const updatePerItemForId = (id, e, name, desc) => {
    e.preventDefault();
    let value = e.target.value;
    value = value.replace(/[$,]/g, '');

    // Item not found, add a new item to list.
    const itemIndex = state.addedBilledItems.findIndex(
      billItem => billItem.billing_lookup_id === id,
    );
    // Item not found, add a new item to list.
    if (itemIndex === -1) {
      if (value.length === 0) {
        return;
      }
      const newItem = {
        billing_lookup_id: id,
        form_name: name,
        form_description: desc,
        baseQty: '0',
        basePrice: '0.00',
        perItem: value,
        form_type: props.form_type,
        entityName: state.selectedEntity,
      };
      setState({
        addedBilledItems: [...state.addedBilledItems, newItem],
      });
    } else {
      if (value.length === 0) {
        value = '0.00';
      }
      setState({
        addedBilledItems: update(state.addedBilledItems, {
          [itemIndex]: { perItem: { $set: value } },
        }),
      });
    }
  };

  const updateBasePriceForId = (id, e, name, desc) => {
    e.preventDefault();
    let value = e.target.value;
    value = value.replace(/[$,]/g, '');
    const itemIndex = state.addedBilledItems.findIndex(
      billItem => billItem.billing_lookup_id === id,
    );
    // Item not found, add a new item to list.
    if (itemIndex === -1) {
      if (value.length === 0) {
        return;
      }
      const newItem = {
        billing_lookup_id: id,
        form_name: name,
        form_description: desc,
        baseQty: state.basicView ? '1' : '0',
        basePrice: state.basicView ? '0.00' : '0',
        perItem: value,
        form_type: props.form_type,
        entityName: state.selectedEntity,
      };
      setState({
        addedBilledItems: [...state.addedBilledItems, newItem],
      });
    } else {
      if (value.length === 0) {
        value = '0.00';
      }
      setState({
        addedBilledItems: update(state.addedBilledItems, {
          [itemIndex]: { basePrice: { $set: value } },
        }),
      });
    }
  };

  const getBaseQty = id => {
    const item = state.addedBilledItems.find(item => {
      if (item.billing_lookup_id === id) {
        return item;
      }
      return undefined;
    });
    if (item === undefined) {
      return '';
    } else {
      return item.baseQty;
    }
  };

  const getBasePrice = id => {
    const item = state.addedBilledItems.find(item => {
      if (item.billing_lookup_id === id) {
        return item;
      }
      return undefined;
    });
    if (item === undefined) {
      return '';
    } else {
      return item.basePrice;
    }
  };

  const getPerItem = id => {
    const item = state.addedBilledItems.find(item => {
      if (item.billing_lookup_id === id) {
        return item;
      }
      return undefined;
    });
    if (item === undefined) {
      return '';
    } else {
      return item.perItem;
    }
  };

  const handleFilterChange = e => {
    setState({
      filterString: e?.target?.value || '',
    });
  };

  return (
    <>
      {state.isLoading ? (
        <Spinner loadingText="Loading Billing Items List..." />
      ) : (
        <div>
          <Typography variant="body2" id="simple-modal-title" style={{ paddingLeft: '4.5%' }}>
            <img
              id="clientNameInfo"
              className="noselect"
              src={addFormIcon}
              style={{ transform: 'scale(1,1)' }}
            />{' '}
            {props.billingTitle}
          </Typography>
          <div
            style={{
              display: 'inline-flex',
              paddingTop: '1.2em',
              paddingLeft: '4.5%',
              paddingRight: '4.5%',
              width: '100%',
            }}
          >
            <Typography variant="title" style={{ alignSelf: 'center', marginRight: '1em' }}>
              {props.billingSelectMessage}
            </Typography>
            <Input
              disableUnderline
              id="txtFilterSearchFormName"
              placeholder="Search by name"
              onChange={event => handleFilterChange(event)}
              value={state.filterString}
            />
            {getStateList()}
            <div style={{ marginTop: '5px', marginLeft: 'auto' }}>
              <ToggleButton
                selected={state.basicView}
                toggleSelected={() => setState({ basicView: !state.basicView })}
                option1="ADVANCED VIEW"
                option2="BASIC VIEW"
              />
            </div>
          </div>
          <div
            style={{
              maxHeight: '500px',
              minHeight: '500px',
              overflowY: 'auto',
              marginLeft: '1.5%',
              marginRight: '1.5%',
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell classes={{ root: classes.tableHeadRoot }}>Name</TableCell>
                  <TableCell classes={{ root: classes.tableHeadRoot }}>Description</TableCell>
                  {!state.basicView && (
                    <TableCell classes={{ root: classes.tableHeadRoot }}>BASE QTY</TableCell>
                  )}
                  {!state.basicView && (
                    <TableCell classes={{ root: classes.tableHeadRoot }}>BASE PRICE</TableCell>
                  )}
                  <TableCell classes={{ root: classes.tableHeadRoot }}>PER ITEM</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{buildFormBillingTable()}</TableBody>
            </Table>
          </div>
          <div
            className="buttonFooterSection"
            style={{
              marginTop: '15px',
              marginBottom: '15px',
              marginRight: '15px',
              textAlign: 'right',
              position: 'relative',
            }}
          >
            <Button
              id="btnToggleAddListCancel"
              size="small"
              classes={{ root: classes.cancelButtonRoot }}
              onClick={props.toggleAddBillingItemList}
            >
              Cancel
            </Button>
            <Button
              id="btnUpdateBillingState"
              size="small"
              onClick={() => {
                props.toggleAddBillingItemList();
                props.updateBillingState(state.addedBilledItems);
              }}
            >
              Add
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default withStyles(styles)(BillingItemListModal);
